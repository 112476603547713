import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ds-info-row',
  templateUrl: './ds-info-row.component.html',
  styleUrls: ['./ds-info-row.component.scss']
})
export class DsInfoRowComponent {
  @Input() customStyle: unknown;
  @Input() info: string;
  @Input() title: string;
  @Input() value: string;

  showInfo = false;

  clickInfo(): void {
    this.showInfo = !this.showInfo;
  }
}
