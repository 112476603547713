import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {
  @Input() public autoFocus = true;

  constructor(private el: ElementRef) {}
  ngAfterViewInit(): void {
    if (!this.autoFocus) {
      return;
    }
    setTimeout(() => {
      (this.el.nativeElement as HTMLElement).focus();
    });
  }
}
