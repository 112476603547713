import { Component, Input } from '@angular/core';

type BadgeTypes = 'primary' | 'secondary' | 'neutral' | 'info' | 'success' | 'warning' | 'critical';

@Component({
  selector: 'app-ds-badge',
  templateUrl: './ds-badge.component.html',
  styleUrls: ['./ds-badge.component.scss']
})
export class DsBadgeComponent {
  @Input() type: BadgeTypes;
}
