/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import * as AccountActions from '@account/account.actions';
import * as HomeActions from '@home/home.actions';
import * as fromAccount from '@account/account.reducers';
import * as fromHome from '@home/reducers';

import { ImpactStoryDetail, PerformanceFilterRange, PromoCampaign } from '@shared/models/Home';
import { ToastService } from '@shared/services/toast.service';
import { YovaAPI } from '@shared/yova-api';

@Injectable()
export class HomeEffects {
  getGraphData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getGraphData),
      mergeMap(({ account }) =>
        this.yovaAPI.dashboard.getReports(account.id).pipe(
          map((res) =>
            HomeActions.getGraphDataSuccess({
              account,
              graphData: res.customer.reporting
            })
          ),
          catchError(() => {
            return of(HomeActions.getGraphDataFail({ account, error: true }));
          })
        )
      )
    )
  );

  getPerformanceData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getPerformanceGraph),
      filter(({ range }) => range !== PerformanceFilterRange.ALL),
      concatLatestFrom(() => this.store.select(fromAccount.selectCurrentAccountID)),
      switchMap(([{ range }, accountID]) => {
        return this.yovaAPI.dashboard.getPerformanceGraph(accountID, range).pipe(
          map((graphData) => {
            return HomeActions.getPerformanceGraphSuccess({ graphData, range });
          }),
          catchError((err) => of(HomeActions.getPerformanceGraphFailed()))
        );
      })
    )
  );

  getEndValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getEndValues),
      mergeMap(({ account }) =>
        this.yovaAPI.dashboard.getEndValues(account.id).pipe(
          map((res) => HomeActions.getEndValuesSuccess({ account, data: res.customer.end_values })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getAllReportingsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getAllReportingsStatus),
      mergeMap(() =>
        this.yovaAPI.dashboard.getAllReportingsStatus().pipe(
          map((res) => HomeActions.getAllReportingsStatusSuccess({ data: res.data })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getImpactStoryDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getImpactStoryDetail),
      mergeMap(({ id }) =>
        this.yovaAPI.dashboard.getImpactStoryDetail(id).pipe(
          map((res) => HomeActions.getImpactStoryDetailSuccess({ data: res.data })),
          catchError(() => {
            void this.toastService.error(this.translateService.instant('SHARED.errors.something'));
            void this.router.navigateByUrl('/tabs/home');
            return EMPTY;
          })
        )
      )
    )
  );

  getImpactStories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getImpactStories),
      mergeMap(() =>
        this.yovaAPI.dashboard.getImpactStories().pipe(
          map((res) => HomeActions.getImpactStoriesSuccess({ data: res.data })),
          catchError(() => {
            void this.toastService.error(this.translateService.instant('SHARED.errors.something'));
            return EMPTY;
          })
        )
      )
    )
  );

  getImpactMetrics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getImpactMetrics),
      mergeMap(() =>
        this.yovaAPI.dashboard.getImpactMetrics().pipe(
          map((res) => HomeActions.getImpactMetricsSuccess({ data: res.data })),
          catchError(() => of(HomeActions.getImpactMetricsFail()))
        )
      )
    )
  );

  getCustomerNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getCustomerNotifications),
      mergeMap(() =>
        this.yovaAPI.notifications.fetchAll().pipe(map((res) => HomeActions.getCustomerNotificationsSuccess({ data: res.data })))
      )
    )
  );

  getCompaniesStockPerformance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getCompaniesStockPerformance),
      mergeMap(({ account }) =>
        this.yovaAPI.dashboard.getCompaniesStockPerformance(account.id).pipe(
          map((res) => {
            return HomeActions.getCompaniesStockPerformanceSuccess({ account, data: res.data });
          }),
          catchError(() => of(HomeActions.getCompaniesStockPerformanceFail({ account })))
        )
      )
    )
  );

  getSuperhumanSurvey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getSuperhumanSurvey),
      mergeMap(({ nextQuestionID }) =>
        this.yovaAPI.dashboard.getSuperHumanSurvey().pipe(
          map((response) => HomeActions.getSuperhumanSurveySuccess({ survey: response.survey_questions, nextQuestionID })),
          catchError(() => of(HomeActions.getSuperhumanSurveyFailed()))
        )
      )
    )
  );

  getCrowdinvestingData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getCrowdInvestingData),
      mergeMap(() =>
        this.yovaAPI.dashboard.getCrowdinvestingData().pipe(
          map((response) => {
            return HomeActions.getCrowdInvestingDataSuccess({ data: response.data[0].attributes });
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  saveSuperhumanSurveyAnswers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.saveSuperhumanSurveyAnswers),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.pipe(select(fromAccount.selectCustomer))))),
      mergeMap(([{ submitted, nextQuestionID, answers }, customer]) =>
        this.yovaAPI.dashboard.saveSuperHumanSurvey({ submitted, answers }).pipe(
          map(() => {
            if (!submitted) {
              return HomeActions.getSuperhumanSurvey({ nextQuestionID });
            }
            return HomeActions.markSuperhumanNotificationAsSeen({ customerID: customer.id });
          }),
          catchError(() => {
            void this.toastService.error(this.translateService.instant('SHARED.errors.something'));
            return EMPTY;
          })
        )
      )
    )
  );

  markSuperhumanNotificationAsSeen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.markSuperhumanNotificationAsSeen),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.pipe(select(fromHome.selectCustomerNotifications))))),
      mergeMap(([, notifications]) => {
        const superhumanNotification = notifications.find((item) => item.attributes.data.type === 'superhuman');
        if (superhumanNotification) {
          return this.yovaAPI.notifications.markAsSeen(superhumanNotification.id).pipe(
            map(() => HomeActions.getCustomerNotifications()),
            catchError(() => EMPTY)
          );
        }
        return EMPTY;
      })
    )
  );

  sendEngagement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.sendEngagement),
      withLatestFrom(this.store.pipe(select(fromHome.selectImpactStoryDetail)), this.store.pipe(select(fromHome.selectImpactStories))),
      mergeMap(([{ action, id, rebalance_portfolio }, article, stories]) =>
        this.yovaAPI.dashboard.sendEngagement(action, id, rebalance_portfolio).pipe(
          mergeMap((response) => {
            const supporters = article.article?.attributes?.supporters_number + 1;
            const updatedStories = cloneDeep(stories);
            updatedStories.forEach((story) => {
              if (Number(story.id) === id) {
                // eslint-disable-next-line no-param-reassign
                story.attributes.liked = action;
                // eslint-disable-next-line no-param-reassign
                story.attributes.supporters_number = supporters;
              }
            });
            return [
              HomeActions.sendEngagementSuccess({ data: response }),
              HomeActions.getImpactStoryDetailSuccess({
                data: {
                  attributes: {
                    ...article.article.attributes,
                    liked: action,
                    supporters_number: supporters
                  }
                } as ImpactStoryDetail
              }),
              HomeActions.getImpactStoriesSuccess({ data: updatedStories })
            ];
          }),
          catchError(() => {
            void this.toastService.error(this.translateService.instant('SHARED.errors.something'));
            return EMPTY;
          })
        )
      )
    )
  );

  deleteEngagement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.deleteEngagement),
      withLatestFrom(this.store.pipe(select(fromHome.selectImpactStoryDetail)), this.store.pipe(select(fromHome.selectImpactStories))),
      mergeMap(([{ id }, article, stories]) =>
        this.yovaAPI.dashboard.deleteEngagement(id).pipe(
          mergeMap((response) => {
            const supporters = article.article?.attributes?.supporters_number - 1;
            const updatedStories = cloneDeep(stories);
            updatedStories.forEach((story) => {
              if (Number(story.id) === id) {
                // eslint-disable-next-line no-param-reassign
                story.attributes.liked = false;
                // eslint-disable-next-line no-param-reassign
                story.attributes.supporters_number = supporters;
              }
            });
            return [
              HomeActions.deleteEngagementStatus({ success: true }),
              HomeActions.getImpactStoryDetailSuccess({
                data: {
                  attributes: {
                    ...article.article.attributes,
                    liked: false,
                    supporters_number: supporters
                  }
                } as ImpactStoryDetail
              }),
              HomeActions.getImpactStoriesSuccess({ data: updatedStories })
            ];
          }),
          catchError(() => {
            HomeActions.deleteEngagementStatus({ success: false });
            void this.toastService.error(this.translateService.instant('SHARED.errors.something'));
            return EMPTY;
          })
        )
      )
    )
  );

  getAccountOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getAccountOptions, AccountActions.setCurrentAccount),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.pipe(select(fromAccount.selectCurrentAccount))))),
      filter(([, data]) => {
        return data.step_status === 5;
      }),
      mergeMap(([, data]) =>
        this.yovaAPI.dashboard.getAccountOptions(data.id).pipe(
          map((response) => HomeActions.getAccountOptionsSuccess({ data: response.data })),
          catchError(() => {
            HomeActions.getAccountOptionsFail();
            return EMPTY;
          })
        )
      )
    )
  );

  setAccountOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.setAccountOptions),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.pipe(select(fromAccount.selectCurrentAccountID))))),
      mergeMap(([{ option }, accountID]) =>
        this.yovaAPI.dashboard.setAccountOptions(option, accountID).pipe(
          map((response) => HomeActions.setAccountOptionsSuccess({ data: response.data })),
          catchError(() => {
            HomeActions.setAccountOptionsFail();
            void this.toastService.error(this.translateService.instant('SHARED.errors.something'));
            return EMPTY;
          })
        )
      )
    )
  );

  getSavingsPlanSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getSavingsPlanSummary, AccountActions.setCurrentAccount),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.pipe(select(fromAccount.selectCurrentAccount))))),
      filter(([, data]) => {
        return data.step_status === 5;
      }),
      mergeMap(([, data]) =>
        this.yovaAPI.dashboard.getSavingsPlanSummary(data.id).pipe(
          map((response) => HomeActions.getSavingsPlanSummarySuccess({ data: response.data.attributes })),
          catchError(() => {
            HomeActions.getSavingsPlanSummaryFail({ data: null });
            return EMPTY;
          })
        )
      )
    )
  );

  getPerformanceSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getPerformanceSummary),
      mergeMap(() =>
        this.yovaAPI.account.getPerformanceSummary().pipe(
          map((response) => HomeActions.getPerformanceSummarySuccess({ data: response.data.attributes })),
          catchError(() => of(HomeActions.getPerformanceSummaryFail()))
        )
      )
    )
  );

  getBalanceSheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getBalanceSheet),
      mergeMap(() =>
        this.yovaAPI.account.getBalanceSheet().pipe(
          map((response) => HomeActions.getBalanceSheetSuccess({ data: response.data.attributes })),
          catchError(() => of(HomeActions.getBalanceSheetFail()))
        )
      )
    )
  );

  getPromoCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getPromoCampaigns),
      mergeMap(() =>
        this.yovaAPI.dashboard.getPromoCampaigns().pipe(
          map((response) => HomeActions.getPromoCampaignsSuccess({ data: addPromosToCampaigns(response) })),
          catchError(() => of(HomeActions.getPromoCampaignsFail()))
        )
      )
    )
  );

  setCampaignParticipation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.setCampaignParticipation),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.pipe(select(fromHome.selectPromoCampaigns))))),
      mergeMap(([{ participation, id }, campaigns]) =>
        this.yovaAPI.dashboard.setPromoParticipation(participation, id).pipe(
          map(() =>
            HomeActions.setCampaignParticipationSuccess({
              data: campaigns.map((campaign) => {
                if (Number(campaign.id) === id) {
                  const updatedCampaign = cloneDeep(campaign);
                  updatedCampaign.attributes.opted_in = participation;
                  return updatedCampaign;
                }
                return campaign;
              })
            })
          ),
          catchError(() => {
            void this.toastService.error(this.translateService.instant('SHARED.errors.something'));
            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private toastService: ToastService,
    private store: Store,
    private router: Router,
    private translateService: TranslateService,
    private yovaAPI: YovaAPI
  ) {}
}

function addPromosToCampaigns(response: PromoCampaign): PromoCampaign {
  const campaigns = response.data.map((campaign) => {
    const promoIDs = campaign.relationships.deposit_promotions.data.map((promo) => promo.id);
    return {
      ...campaign,
      promotions: response.included
        .filter((promotion) => {
          return promoIDs.includes(promotion.id);
        })
        .sort((a, b) => a.attributes.value - b.attributes.value)
    };
  });
  return { ...response, data: campaigns };
}
