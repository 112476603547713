// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds-info-row {
  display: flex;
  flex-direction: column;
  border-top: var(--border-1) solid var(--color-core-neutral-3);
  padding: var(--spacing-3) 0;
}
.ds-info-row-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--spacing-2);
}
.ds-info-row-content > p {
  white-space: nowrap;
}
.ds-info-row-content div {
  display: inherit;
  align-items: inherit;
  gap: var(--spacing-2);
  text-align: right;
}
.ds-info-row-content div ion-icon {
  min-width: var(--icon-size-1);
  margin-top: 2px;
  font-size: var(--fs-4);
}
.ds-info-row .extra-info {
  margin-top: var(--spacing-2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
