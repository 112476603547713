import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as HomeActions from '@home/home.actions';
import * as fromHome from '@home/reducers';

import { SuperhumanQuestion } from '@shared/models/Home';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-home-superhuman-survey',
  styleUrls: ['./home-superhuman-survey.component.scss'],
  templateUrl: './home-superhuman-survey.component.html'
})
export class HomeSuperhumanSurveyComponent implements OnInit, OnDestroy {
  freeTextFrom: FormGroup;
  showThankYouMessage: boolean;
  currentQuestionDetails: {
    currentIndex: number;
    nextQuestionID: number;
    previousQuestionID: number;
    question: SuperhumanQuestion;
    surveyLength: number;
  };

  protected readonly onDestroy$ = new Subject<void>();

  constructor(
    private modalController: ModalController,
    private trackingService: TrackingService,
    private store: Store,
    private fb: FormBuilder
  ) {}

  dismiss(): void {
    void this.modalController.dismiss({
      dismissed: true
    });
  }

  get ratingArray(): number[] {
    return Array.from(Array(11).keys());
  }

  get stepperArray(): number[] {
    return Array.from(Array(this.currentQuestionDetails.surveyLength).keys());
  }

  get currentQuestion(): SuperhumanQuestion {
    return this.currentQuestionDetails.question;
  }

  goBack(): void {
    if (!this.currentQuestionDetails.previousQuestionID) {
      this.dismiss();
    } else {
      this.store.dispatch(HomeActions.setCurrentSuperhumanSurveyID({ id: this.currentQuestionDetails.previousQuestionID }));
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.complete();
    this.onDestroy$.next();
  }

  ngOnInit(): void {
    this.store.dispatch(HomeActions.getSuperhumanSurvey({ nextQuestionID: null }));

    this.freeTextFrom = this.fb.group({
      id: ['0'],
      answer: ['', [Validators.required, Validators.minLength(5)]]
    });

    this.freeTextFrom
      .get('answer')
      .valueChanges.pipe(debounceTime(500), takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.currentQuestionDetails?.question?.type !== 'open') return;
        this.currentQuestionDetails = {
          ...this.currentQuestionDetails,
          question: {
            ...this.currentQuestion,
            survey_customer_answer: this.freeTextFrom.getRawValue()
          }
        };
      });

    this.store.pipe(select(fromHome.selectSuperhumanSurveyCurrentQuestion), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.currentQuestionDetails = value;

      if (this.currentQuestionDetails?.question?.type === 'open' && this.currentQuestionDetails.question.survey_customer_answer) {
        this.freeTextFrom.get('answer').setValue(this.currentQuestionDetails.question.survey_customer_answer.answer);
      }
    });
  }

  selectAnswerRating(score: number): void {
    this.currentQuestionDetails = {
      ...this.currentQuestionDetails,
      question: {
        ...this.currentQuestion,
        survey_customer_answer: {
          id: 0,
          answer: score
        }
      }
    };
  }

  selectAnswerSinglechoice(answerID: number): void {
    const answer = this.currentQuestion.survey_customer_answer?.answer === answerID ? null : { id: 0, answer: answerID };
    this.currentQuestionDetails = {
      ...this.currentQuestionDetails,
      question: {
        ...this.currentQuestion,
        survey_customer_answer: answer
      }
    };
  }

  selectAnswerMultichoice(answerID: number): void {
    let answer = this.currentQuestion.survey_customer_answer as { id: number; answer: number[] };
    if (!answer) {
      answer = {
        id: 0,
        answer: []
      };
    }
    const index = answer.answer?.findIndex((item) => item === answerID);
    if (index !== -1 && answer.answer.length === 1) {
      answer = null;
    } else if (index !== -1 && answer.answer.length > 1) {
      answer.answer.splice(index, 1);
    } else {
      answer = {
        ...answer,
        answer: [...answer.answer, answerID]
      };
    }
    this.currentQuestionDetails = {
      ...this.currentQuestionDetails,
      question: {
        ...this.currentQuestion,
        survey_customer_answer: answer as { id: number; answer: [] }
      }
    };
  }

  submitAnswers(): void {
    const answers = {
      question_id: this.currentQuestion.id,
      answer: this.currentQuestion.survey_customer_answer.answer
    };
    this.store.dispatch(
      HomeActions.saveSuperhumanSurveyAnswers({
        nextQuestionID: this.currentQuestionDetails.nextQuestionID,
        answers: [answers],
        submitted: !this.currentQuestionDetails.nextQuestionID
      })
    );
    this.showThankYouMessage = !this.currentQuestionDetails.nextQuestionID;
  }

  submitAnswersAndGoBack(): void {
    const answers = {
      question_id: this.currentQuestion.id,
      answer: this.currentQuestion.survey_customer_answer.answer
    };
    this.store.dispatch(
      HomeActions.saveSuperhumanSurveyAnswers({
        nextQuestionID: this.currentQuestionDetails.previousQuestionID,
        answers: [answers],
        submitted: false
      })
    );
  }

  track(event: string) {
    this.trackingService.trackActivity(event);
  }
}
