import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
class AuthGuardService {
  constructor(private authService: AuthService) {}

  canActivate(): boolean {
    return !!this.authService.isAuthenticated();
  }
}

export const AuthGuard: CanActivateFn = () => {
  return inject(AuthGuardService).canActivate();
};
