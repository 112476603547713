/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-home-area-chart',
  styleUrls: ['./home-area-chart.component.scss'],
  templateUrl: './home-area-chart.component.html'
})
export class HomeAreaChartComponent implements OnChanges {
  @Input() data = [];
  @Input() status: 'positive' | 'negative';

  private width = 110;
  private height = 45;

  constructor(private container: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue.length) {
      this.drawChart(this.data);
    }
  }

  drawChart(data: any[]): void {
    const dataUpdated = data.map((item) => {
      return {
        date: new Date(item.date),
        value: item.return
      };
    });

    const svg = d3
      .select(this.container.nativeElement)
      .select('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .style('overflow', 'hidden');

    let min = d3.min(dataUpdated, (d) => d.value);
    const max = d3.max(dataUpdated, (d) => d.value);
    if (min > 0) {
      min *= 0.8;
    } else {
      min *= 1.2;
    }

    // Curve
    const curve = d3.curveLinear;

    // X
    const x = d3
      .scaleUtc()
      .domain(d3.extent(dataUpdated, (d) => d.date))
      .range([0, this.width]);

    // Y
    const y = d3.scaleLinear().domain([min, max]).nice().range([this.height, 0]);

    // Create line
    const line = d3
      .line()
      .defined((d: any) => d.value)
      .x((d: any) => x(d.date))
      .y((d: any) => y(d.value));

    // Create line
    const area = d3
      .area()
      .curve(curve)
      .x((d: any) => x(d.date))
      .y0(y(min))
      .y1((d: any) => y(d.value));

    // Y axis
    const yAxis = (g) =>
      g
        .call(d3.axisLeft(y))
        .call((g) => g.select('.domain').remove())
        .call((g) => g.select('.tick:last-of-type text').clone().attr('x', 3).attr('text-anchor', 'start').attr('font-weight', 'bold'));

    // X axis
    const xAxis = (g) => g.attr('transform', `translate(0,${this.height})`).call(d3.axisBottom(x));

    svg
      .append('path')
      .datum(dataUpdated as any)
      .attr('class', 'area')
      .attr('d', area);

    svg
      .append('path')
      .datum(dataUpdated as any)
      .transition()
      .duration(700)
      .attr('fill', 'none')
      .attr('stroke', 'var(--color-core-brand-6-5)')
      .attr('stroke-width', 2)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('d', line);

    svg.append('g').style('opacity', 0).call(yAxis);
    svg.append('g').style('opacity', 0).call(xAxis);
  }
}
