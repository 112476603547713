import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-home-impact-metrics-info',
  styleUrls: ['./home-impact-metrics-info.component.scss'],
  templateUrl: './home-impact-metrics-info.component.html'
})
export class HomeImpactMetricsInfoComponent {
  @Input() metricName: string;

  constructor(
    private modalController: ModalController,
    private trackingService: TrackingService
  ) {}

  dismiss() {
    void this.modalController.dismiss({
      dismissed: true
    });
  }

  track(event: string) {
    this.trackingService.trackActivity(event);
  }
}
