import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as RootActions from '@app/app.actions';

import { BiometricLogin, State } from '@shared/models/State';

export const initialState: RootState = {
  biometricLogin: {
    available: false,
    name: 'biometric',
    type: null
  },
  isGrowAccountActive: false
};

const rootReducer = createReducer(
  initialState,
  on(RootActions.setBiometricLogin, (state, action) => ({
    ...state,
    biometricLogin: action.payload
  })),
  on(RootActions.toggleGrowAccount, (state, action) => ({
    ...state,
    isGrowAccountActive: action.active
  }))
);

export function reducer(state: RootState | undefined, action: Action) {
  return rootReducer(state, action);
}

export interface RootState {
  biometricLogin: BiometricLogin;
  isGrowAccountActive: boolean;
}

/*
 * SELECTORS
 */
export const selectFeature = (state: State) => state.root;
// Root reducer selectors
export const selectBiometricLogin = createSelector(selectFeature, (state: RootState) => state.biometricLogin);
export const selectIsGrowAccountActive = createSelector(selectFeature, (state: RootState) => state.isGrowAccountActive);
