import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-market-performance-box',
  templateUrl: './market-performance-box.component.html',
  styleUrls: ['./market-performance-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketPerformanceBoxComponent {
  @Input() net: number;
  @Input() type: 'first' | 'last' | 'one-item' | '';
  @Input() gross: number;
  @Input() note: string;
  @Input() language: string;
  @Input() benchmarkDate: string;
  @Input() info: string;
  showInfo: boolean;

  constructor(private translateService: TranslateService) {}

  openExtraInfo(): void {
    this.showInfo = !this.showInfo;
  }
}
