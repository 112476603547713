// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-bottom: 30px;
  --padding-start: 15px;
  --padding-end: 15px;
}
ion-content p {
  font-size: 14px;
}
ion-content p.category-title, ion-content p.sub-title {
  font-size: 16px;
}

ion-toolbar {
  --background: var(--ion-color-light);
}

ion-item {
  --background-focused: transparent;
  --background: transparent;
  --padding-start: 0;
  margin-top: 30px;
}

ion-title {
  margin-bottom: 30px;
}

ion-skeleton-text {
  width: 80%;
  border-radius: 8px;
}

.ion-no-margin {
  margin-bottom: 15px;
  font-weight: 700;
}

h1 {
  color: var(--ion-color-primary);
  font-family: var(--font-secondary);
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  text-align: center;
  min-height: 36px;
}
h1 ion-skeleton-text {
  margin: 0 auto;
}

.sub-title {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 24px;
  margin-bottom: 24px;
}

.category-title {
  font-weight: 700;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 4px;
}

.company-topics {
  display: flex;
}
.company-topics img {
  height: 48px;
  width: 48px;
  margin-right: 5px;
}

.skeleton-title {
  align-items: center;
  display: flex;
  height: 26px;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 25px;
}

.skeleton-image {
  background: var(--ion-color-secondary);
}
.skeleton-image.logo {
  width: 60px;
  height: 25px;
  margin: 9px auto;
}
.skeleton-image.illustrative {
  margin-bottom: 15px;
  width: 100%;
  height: 120px;
}

.company__conflicts {
  border: 2px solid var(--ion-color-yellow-tint);
  border-radius: 4px;
  margin-top: 12px;
}

.company__conflicts-top {
  background-color: var(--ion-color-yellow-tint);
  display: flex;
  align-items: center;
  padding: 8px;
}
.company__conflicts-top ion-icon {
  font-size: 24px;
  color: var(--ion-color-yellow);
  margin-right: 8px;
  min-width: 24px;
}
.company__conflicts-top span {
  font-weight: 500;
  font-size: 14px;
}

.company__conflicts-down {
  padding: 0px 8px;
  display: flex;
}

.company-image {
  align-items: center;
  background-position: center;
  background-size: cover;
  display: flex;
  height: 120px;
  justify-content: center;
  position: relative;
  margin-bottom: 24px;
}
.company-image::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--ion-color-secondary);
  opacity: 0.85;
  z-index: 0;
}
.company-image img {
  height: 80px;
  width: auto;
  z-index: 1;
}

.company__extra-desc {
  margin-top: 10px;
}

.company__divider {
  width: 100%;
  height: 1px;
  background-color: var(--ion-color-secondary);
  margin: 15px 0;
}

.company__infos {
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
  align-items: flex-start;
}

.company__info {
  display: flex;
  align-items: center;
  margin-right: 16px;
  padding: 4px 0;
}
.company__info ion-icon {
  font-size: 16px;
  margin-right: 4px;
  color: var(--ion-color-tertiary);
}
.company__info span, .company__info a {
  line-height: 16px;
  font-size: 12px;
  font-weight: 12px;
  letter-spacing: 0.5%;
  color: var(--ion-color-tertiary);
}
.company__info a {
  border-bottom: none;
  text-decoration: underline;
}
.company__info a:hover {
  border-bottom: none;
  color: var(--black);
}

.company__list {
  margin-top: 32px;
}

.company__list-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.company__list-item ion-icon {
  font-size: 16px;
  min-width: 16px;
  margin-right: 8px;
  color: var(--ion-color-success);
}
.company__list-item span {
  font-size: 14px;
}
.company__list-item--warning ion-icon {
  color: var(--ion-color-orange);
}

.company__warning {
  background-color: var(--ion-color-yellow-tint);
  padding: 16px 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 24px 0;
  border-radius: 4px;
}
.company__warning ion-icon {
  font-size: 24px;
  color: var(--ion-color-yellow);
  margin-right: 8px;
  min-width: 24px;
}

.company-image__wished {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 8px;
  top: 8px;
  border-radius: 50%;
  background-color: var(--ion-color-secondary);
  z-index: 1;
}
.company-image__wished ion-icon {
  font-size: 24px;
  color: var(--ion-color-red);
}

.company-image__wished-standalone {
  position: relative;
  right: 0;
  top: 0;
  margin-bottom: 12px;
}

.company__additional-topics {
  margin: 24px 0;
}
.company__additional-topics p {
  margin-bottom: 8px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
