import { Component } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss']
})
export class OfflineComponent {
  constructor(private loadingController: LoadingController) {}

  // Fake loading after customer click on try again (automaticlly disappeard once it's connected to network again)
  async tryAgain() {
    const loading = await this.loadingController.create({});
    await loading.present();

    setTimeout(() => {
      this.loadingController.dismiss();
    }, 1000);
  }
}
