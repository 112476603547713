import { Action, createReducer, on } from '@ngrx/store';
import * as HomeActions from '../home.actions';
import * as AccountActions from '@account/account.actions';

import { EngagementDetail, ImpactMetric, ImpactStoriesItem, ImpactStoryDetail } from '@shared/models/Home';

export const initialState: ImpactState = {
  initialized: false,
  metrics: [],
  isMetricsLoading: true,
  storyDetail: {
    article: {},
    loading: false
  },
  stories: [],
  isStoriesLoading: true,
  storiesFilter: '',
  engagement: {
    liked: null,
    impact_story_id: 0,
    customer_id: 0
  },
  deleteEngagementStatus: false
};

const impactReducer = createReducer(
  initialState,
  on(HomeActions.getImpactStoriesSuccess, (state, action) => ({
    ...state,
    stories: action.data,
    isStoriesLoading: false,
    initialized: true
  })),
  on(HomeActions.getImpactStoriesFail, (state) => ({
    ...state,
    stories: [],
    isStoriesLoading: false,
    initialized: true
  })),
  on(HomeActions.getImpactStoryDetail, (state) => ({
    ...state,
    storyDetail: {
      ...state.storyDetail,
      loading: true
    }
  })),
  on(HomeActions.getImpactStoryDetailSuccess, (state, action) => ({
    ...state,
    storyDetail: {
      article: {
        ...state.storyDetail.article,
        ...action.data
      },
      loading: false
    }
  })),
  on(HomeActions.getImpactMetricsSuccess, (state, action) => ({
    ...state,
    metrics: action.data,
    isMetricsLoading: false
  })),
  on(HomeActions.getImpactMetricsFail, (state) => ({
    ...state,
    metrics: [],
    isMetricsLoading: false
  })),
  on(HomeActions.changeImpactStoriesFilter, (state, action) => ({
    ...state,
    storiesFilter: action.value
  })),
  on(HomeActions.sendEngagementSuccess, (state, action) => ({
    ...state,
    engagement: action.data,
    deleteEngagementStatus: false
  })),
  on(HomeActions.deleteEngagementStatus, (state, action) => ({
    ...state,
    deleteEngagementStatus: action.success
  })),
  // NgRx store clean up after logout
  on(AccountActions.customerLogoutAndUnpairDevice, () => ({ ...initialState }))
);

export function reducerImpact(state: ImpactState | undefined, action: Action) {
  return impactReducer(state, action);
}

export interface ImpactState {
  initialized: boolean;
  metrics: ImpactMetric[];
  isMetricsLoading: boolean;
  stories: ImpactStoriesItem[];
  isStoriesLoading: boolean;
  storiesFilter: null | string;
  storyDetail: {
    article: Partial<ImpactStoryDetail>;
    loading: boolean;
  };
  engagement: Partial<EngagementDetail>;
  deleteEngagementStatus: boolean;
}
