import { Component, Input } from '@angular/core';

type LegendColors = 'info' | 'success' | 'warning' | 'critical' | 'custom';

@Component({
  selector: 'app-ds-legend',
  styleUrls: ['./ds-legend.component.scss'],
  templateUrl: './ds-legend.component.html'
})
export class DSLegendComponent {
  @Input() type: 'fill' | 'outline';
  @Input() color: LegendColors;
  @Input() customColor: string;
}
