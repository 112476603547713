// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds-rounded-icon {
  display: inline-flex;
  align-items: center;
  background-color: var(--custom-color);
  --color: var(--color-core-neutral-10);
  --custom-color: var(--color-core-neutral-5);
  border-radius: 100%;
}
.ds-rounded-icon.info {
  background: var(--color-system-info-1);
}
.ds-rounded-icon.warning {
  background: var(--color-system-warning-1);
}
.ds-rounded-icon.success {
  background: var(--color-system-success-1);
}
.ds-rounded-icon.critical {
  background: var(--color-system-critical-1);
}
.ds-rounded-icon.custom {
  background: var(--custom-color);
}
.ds-rounded-icon.small {
  padding: var(--spacing-1);
}
.ds-rounded-icon.medium {
  padding: var(--spacing-2);
}
.ds-rounded-icon.large {
  padding: var(--spacing-3);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
