import { createAction, props } from '@ngrx/store';

import {
  Company,
  CriteriumData,
  InvestmentData,
  PortfolioStocks,
  RiskSurveyQuestion,
  StockAllocationAPI,
  StocksAllocation,
  PortfolioAllocation,
  TopicData
} from '@shared/models/Strategy';

export const getInvestmentData = createAction('[Strategy] Get investment data');

export const setInvestmentData = createAction('[Strategy] Set investment data', props<InvestmentData>());

export const getCriteriaData = createAction('[Strategy] Get criteria data');

export const setCriteriaData = createAction('[Strategy] Set criteria data action', props<{ data: CriteriumData[] }>());

export const getTopicsData = createAction('[Strategy] Get topics data');

export const setTopicsData = createAction('[Strategy] Set topics data', props<{ data: TopicData[] }>());

export const getCustomerStocks = createAction('[Strategy] Get customer stocks');

export const setCustomerStocks = createAction('[Strategy] Set customer stocks', props<{ data: PortfolioStocks }>());

export const getCompanyDetail = createAction('[Strategy] Get company detail', props<{ companyId: number }>());

export const setCompanyDetail = createAction('[Strategy] Set company detail', props<Company>());

export const getRiskQuestions = createAction('[Strategy] Get account risk questions');

export const setRiskQuestions = createAction('[Strategy] Set account risk questions', props<{ riskQuestions: RiskSurveyQuestion[] }>());

export const getStrategyEditModeStatus = createAction('[Strategy] Get strategy edit mode status');

export const setStrategyEditModeStatus = createAction('[Strategy] Set strategy edit mode status', props<{ editModeStatus: boolean }>());

export const getStocksAllocation = createAction('[Strategy] Get stocks allocation');

export const getStocksAllocationSuccess = createAction('[Strategy] Get stocks allocation success', props<{ data: StockAllocationAPI }>());

export const updateStocksAllocation = createAction(
  '[Strategy] Update stocks allocation',
  props<{ currentTab: StocksAllocation['currentTab']; selectedIndex: number }>()
);

export const getPortfolioAllocation = createAction('[Strategy] Get portfolio allocation');

export const getPortfolioAllocationSuccess = createAction(
  '[Strategy] Get portfolio allocation success',
  props<{ data: PortfolioAllocation }>()
);

export const getPortfolioAllocationFail = createAction('[Strategy] Get portfolio allocation fail');
