// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assets-deposit > p {
  text-align: center;
}
.assets-deposit-placeholder {
  border-bottom: 1px solid #6f6b63;
  cursor: text;
  text-align: center;
  margin: 0 5px;
}
.assets-deposit-placeholder > span {
  display: inline-block;
  vertical-align: middle;
}
.assets-deposit-placeholder > span:first-child {
  font-size: 16px;
  line-height: 20px;
  margin-top: 3px;
  margin-right: 8px;
}
.assets-deposit-placeholder > span:last-child {
  font-family: "Canela";
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
.assets-deposit-placeholder.error span:last-child {
  color: var(--ion-color-danger);
}
.assets-deposit-amount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.assets-deposit-input {
  font-family: "Canela";
  border-top: medium none;
  border-left: medium none;
  border-right: medium none;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  outline: none;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  background-color: transparent;
}
.assets-deposit-input > input[type=number]::-webkit-outer-spin-button,
.assets-deposit-input > input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.assets-deposit-input > input[type=number] {
  -moz-appearance: textfield;
}
.assets-deposit-label {
  font-size: 16px;
  transform: none;
  text-align: center;
}
.assets-deposit-error {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 8px;
  padding: 12px;
  border-radius: 4px;
  background: var(--ion-color-error);
}
.assets-deposit-error p {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
}
.assets-deposit-error ion-icon {
  font-size: 24px;
  color: var(--ion-color-danger);
}
.assets-deposit-progress {
  width: 100%;
  padding: 0 5px;
}
.assets-deposit-progress .bar {
  width: 100%;
  height: 4px;
  overflow: hidden;
  background: #eaeae9;
  transition: all 1s linear;
}
.assets-deposit-progress .bar .progress {
  height: 4px;
  background: var(--ion-color-primary);
}
.assets-deposit-progress .values {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
}
.assets-deposit form > button {
  border-radius: 4px;
  background: var(--ion-color-primary);
  color: var(--ion-color-white);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin: 16px 2px 0;
  padding: 10px 16px;
  width: 100%;
}

ion-button.outline {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  border-radius: 4px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
