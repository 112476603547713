export enum LoginType {
  EMAIL = 'email',
  GOOGLE = 'google'
}

export enum DayTime {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening'
}
