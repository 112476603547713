import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ds-chip',
  templateUrl: './ds-chip.component.html',
  styleUrls: ['./ds-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DsChipComponent {
  @Input() active: null | boolean;
  @Input() value: number;
  @Output() check: EventEmitter<number> = new EventEmitter<number>();

  onCheck(value: number) {
    if (this.active) {
      return;
    }
    this.check.emit(value);
  }
}
