// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.track-deposit-widget {
  display: flex;
  flex-direction: column;
  background: var(--color-core-neutral-0);
  box-shadow: var(--shadow-elavated);
  border-radius: var(--radius-2);
  padding: var(--spacing-4);
}
.track-deposit-widget .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.track-deposit-widget .title {
  display: flex;
  align-items: center;
}
.track-deposit-widget ion-icon[name=piggy] {
  font-size: var(--icon-size-2);
  stroke: var(--color-core-neutral-8);
  margin-right: var(--spacing-2);
  margin-top: -3px;
}
.track-deposit-widget .status {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-2);
}
.track-deposit-widget .status-info {
  color: var(--color-core-neutral-7);
}
.track-deposit-widget .status-info p {
  color: inherit;
}
.track-deposit-widget .status-icon {
  flex: 0 0 var(--icon-size-1);
  margin-left: auto;
  max-width: var(--icon-size-1);
}
.track-deposit-widget .status ion-icon[name=info2] {
  display: inline;
  font-size: var(--fs-4);
  color: var(--color-core-neutral-5);
}
.track-deposit-widget .deposit-info {
  margin-top: var(--spacing-3);
  color: var(--color-core-neutral-7);
}
.track-deposit-widget .deposit-info p {
  color: inherit;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
