import { StockAllocationAPIItem, StocksAllocationItem } from '@shared/models/Strategy';

export function transformStocksAllocationData(data: StockAllocationAPIItem[]): StocksAllocationItem[] {
  if (!data || !data.length) return [];

  const finalData: StocksAllocationItem[] = [];
  const colors = [
    '#F9D483',
    '#F8C97A',
    '#F7BE71',
    '#F6B368',
    '#F5A85F',
    '#F49D57',
    '#F3934E',
    '#F28846',
    '#F17E3F',
    '#F17338',
    '#F06831',
    '#EF5B2C',
    '#EE3C15'
  ];

  data.forEach((item, index) => {
    finalData.push({
      id: index,
      key: Object.keys(item)[0],
      value: item[Object.keys(item)[0]],
      color: colors[index]
    });
  });

  return finalData;
}

export function transformStocksAllocationShortlist(data: StocksAllocationItem[]) {
  if (!data || !data.length) return [];

  let totalFirstTree = 0;
  const shortList = data.filter((item, index) => {
    if (index < 3) {
      totalFirstTree += item.value;
    }
    return index < 3;
  });

  shortList.push({
    id: 4,
    color: '#F6B368',
    key: 'other',
    value: 1 - totalFirstTree
  });

  return shortList;
}
