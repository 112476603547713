import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-balance-sheet-row',
  templateUrl: './balance-sheet-row.component.html',
  styleUrls: ['./balance-sheet-row.component.scss']
})
export class BalanceSheetRowComponent {
  @Input() title: string;
  @Input() info: string;
  @Input() amount: number;
  @Input() currency: string;
  showInfo = false;

  clickInfo(): void {
    this.showInfo = !this.showInfo;
  }
}
