import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  HomeEngagementArticleComponent,
  HomeImpactArticleComponent,
  HomeImpactCampaignComponent,
  HomeImpactCampaignUpdateComponent,
  HomePromoCampaignComponent
} from './components';
import { HomePageComponent } from './home.page';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent
  },
  {
    path: 'performance',
    redirectTo: '/tabs/performance'
  },
  {
    path: 'impact',
    redirectTo: '/tabs/impact'
  },
  {
    path: 'impact-news/:id',
    component: HomeImpactArticleComponent
  },
  {
    path: 'engagement/:id',
    component: HomeEngagementArticleComponent
  },
  {
    path: 'campaign/:id',
    component: HomeImpactCampaignComponent
  },
  {
    path: 'campaign-update/:id',
    component: HomeImpactCampaignUpdateComponent
  },
  {
    path: 'promo-campaign/:id',
    component: HomePromoCampaignComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomePageRoutingModule {}
