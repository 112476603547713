import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-general-confirm-modal',
  styleUrls: ['./general-confirm-modal.component.scss'],
  templateUrl: './general-confirm-modal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class GeneralConfirmModalComponent {
  @Input() body: string;
  @Input() body2: string;
  @Input() note: string;
  @Input() cancelButton: string;
  @Input() ctaText: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() info: string;

  constructor(private modalController: ModalController) {}

  continue() {
    void this.modalController.dismiss({
      dismissed: true,
      continue: true
    });
  }

  dismiss() {
    void this.modalController.dismiss({
      dismissed: true,
      continue: false
    });
  }
}
