// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promo-campaign .image-container {
  width: 100%;
  display: block;
  aspect-ratio: 2/1;
  overflow: hidden;
  border-radius: var(--radius-5);
  margin-bottom: var(--spacing-6);
}
.promo-campaign .spacer {
  margin-bottom: var(--spacing-8);
}
.promo-campaign img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.promo-campaign app-remaining-time-label {
  display: flex;
}
.promo-campaign h1 {
  margin: var(--spacing-1) 0 var(--spacing-3) 0;
}
.promo-campaign h2 {
  margin: 0 0 var(--spacing-3) 0;
}
.promo-campaign h3 {
  margin: 0 0 var(--spacing-1) 0;
}
.promo-campaign .step {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-5);
}
.promo-campaign .step h3 {
  margin: 0;
}
.promo-campaign .step app-ds-rounded-icon {
  margin-right: var(--spacing-4);
}
.promo-campaign .tnc {
  text-align: center;
  padding: var(--spacing-2);
}
.promo-campaign .participation {
  text-align: center;
}
.promo-campaign .participation p {
  margin-bottom: var(--spacing-2);
}
.promo-campaign .participation a {
  padding: var(--spacing-2);
}

.promo-banner {
  display: flex;
  align-items: center;
  background: var(--color-core-neutral-0);
  box-shadow: var(--shadow-elavated);
  padding: var(--spacing-4);
  gap: var(--spacing-2);
  margin-bottom: calc(var(--spacing-1) / 2);
}
.promo-banner:first-child {
  border-start-start-radius: var(--radius-2);
  border-start-end-radius: var(--radius-2);
}
.promo-banner:last-child {
  border-end-start-radius: var(--radius-2);
  border-end-end-radius: var(--radius-2);
}
.promo-banner .promo-info {
  flex-grow: 1;
}
.promo-banner ion-icon {
  font-size: var(--icon-size-3);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
