// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.balance-sheet-row {
  display: flex;
  flex-direction: column;
  border-top: var(--border-1) solid var(--color-core-neutral-3);
  padding: var(--spacing-2) 0;
}
.balance-sheet-row-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.balance-sheet-row-content div {
  display: inherit;
  align-items: inherit;
  gap: var(--spacing-2);
}
.balance-sheet-row-content div ion-icon {
  margin-top: -2px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
