import { HomeAccountsItemComponent } from './home-accounts-item/home-accounts-item.component';
import { HomeAreaChartComponent } from './home-area-chart/home-area-chart.component';
import { HomeBalanceComponent } from './home-balance/home-balance.component';
import { HomeCampaignBannerComponent } from './home-campaign-banner/home-campaign-banner.component';
import { HomeEngagementArticleComponent } from './home-engagement-article/home-engagement-article.component';
import { HomeFakeDoorComponent } from './home-fake-door/home-fake-door.component';
import { HomeFakeDoorModalComponent } from './home-fake-door-modal/home-fake-door-modal.component';
import { HomeImpactComponent } from './home-impact/home-impact.component';
import { HomeImpactArticleComponent } from './home-impact-article/impact-article.component';
import { HomeImpactCampaignComponent } from './home-impact-campaign/home-impact-campaign.component';
import { HomeImpactCampaignUpdateComponent } from './home-impact-campaign-update/home-impact-campaign-update.component';
import { HomeImpactMetricsComponent } from './home-impact-metrics/home-impact-metrics.component';
import { HomeImpactStoriesItemComponent } from './home-impact-stories-item/impact-stories-item.component';
import { HomeMiniWidgetComponent } from './home-mini-widget/home-mini-widget.component';
import { HomePerformanceComponent } from './home-performance/home-performance.component';
import { HomePerformanceChartComponent } from './home-performance-chart/home-performance-chart.component';
import { HomePromoCampaignComponent } from './home-promo-campaign/home-promo-campaign.component';
import { HomeStrategyComponent } from './home-strategy/home-strategy.component';
import { HomeTrackDepositComponent } from './home-track-deposit/home-track-deposit.component';
import { MarketPerformanceBoxComponent } from './market-performance-box/market-performance-box.component';
import { RemainingTimeLabelComponent } from './remaining-time-label/remaining-time-label.component';
import { StockPerformanceBoxComponent } from './stock-performance-box/stock-performance-box.component';

export const components = [
  HomeAccountsItemComponent,
  HomeAreaChartComponent,
  HomeEngagementArticleComponent,
  HomeFakeDoorComponent,
  HomeFakeDoorModalComponent,
  HomeImpactArticleComponent,
  HomeImpactCampaignUpdateComponent,
  HomeImpactCampaignComponent,
  HomePromoCampaignComponent,
  HomeImpactMetricsComponent,
  HomeImpactStoriesItemComponent,
  HomeImpactComponent,
  HomePerformanceComponent,
  MarketPerformanceBoxComponent,
  StockPerformanceBoxComponent,
  HomeStrategyComponent,
  HomeTrackDepositComponent,
  HomeCampaignBannerComponent,
  HomePerformanceChartComponent,
  HomeBalanceComponent,
  HomeMiniWidgetComponent,
  RemainingTimeLabelComponent
];

export { HomeAccountsItemComponent } from './home-accounts-item/home-accounts-item.component';
export { HomeAreaChartComponent } from './home-area-chart/home-area-chart.component';
export { HomeBalanceComponent } from './home-balance/home-balance.component';
export { HomeEngagementArticleComponent } from './home-engagement-article/home-engagement-article.component';
export { HomeFakeDoorModalComponent } from './home-fake-door-modal/home-fake-door-modal.component';
export { HomeFakeDoorComponent } from './home-fake-door/home-fake-door.component';
export { HomeImpactArticleComponent } from './home-impact-article/impact-article.component';
export { HomeImpactCampaignUpdateComponent } from './home-impact-campaign-update/home-impact-campaign-update.component';
export { HomeImpactCampaignComponent } from './home-impact-campaign/home-impact-campaign.component';
export { HomePromoCampaignComponent } from './home-promo-campaign/home-promo-campaign.component';
export { HomeImpactMetricsComponent } from './home-impact-metrics/home-impact-metrics.component';
export { HomeImpactStoriesItemComponent } from './home-impact-stories-item/impact-stories-item.component';
export { HomeImpactComponent } from './home-impact/home-impact.component';
export { HomeMiniWidgetComponent } from './home-mini-widget/home-mini-widget.component';
export { HomePerformanceChartComponent } from './home-performance-chart/home-performance-chart.component';
export { HomePerformanceComponent } from './home-performance/home-performance.component';
export { HomeStrategyComponent } from './home-strategy/home-strategy.component';
export { HomeTrackDepositComponent } from './home-track-deposit/home-track-deposit.component';
export { HomeCampaignBannerComponent } from './home-campaign-banner/home-campaign-banner.component';
export { MarketPerformanceBoxComponent } from './market-performance-box/market-performance-box.component';
export { StockPerformanceBoxComponent } from './stock-performance-box/stock-performance-box.component';
export { RemainingTimeLabelComponent } from './remaining-time-label/remaining-time-label.component';
