import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import packageJSON from '../package.json';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Allow sentry only on staging & production
if (environment.name === 'staging' || environment.name === 'production') {
  Sentry.init({
    dsn: 'https://f93f3137735940e19e281327c04b4ff3@o1009232.ingest.sentry.io/6168777',
    environment: environment.name,
    // 'sampleRate: 1' - needs to be lower when more customers will be using the app
    sampleRate: 1,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation
      }),
      new Sentry.Replay()
    ],
    ignoreErrors: [
      '$sce', // IE6
      'Unauthorized',
      'geocoder',
      'NS_ERROR_NOT_INITIALIZED'
    ],
    release: packageJSON.version,
    tracesSampleRate: 1.0
  });
}

// Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD2QAvkqORmqfQzgdYs6OwIqfHtJDvA-Fk',
  authDomain: 'inyova-mobile-remote-config.firebaseapp.com',
  projectId: 'inyova-mobile-remote-config',
  storageBucket: 'inyova-mobile-remote-config.appspot.com',
  messagingSenderId: '500410084401',
  appId: '1:500410084401:web:8582f15be81b74e17d3910',
  measurementId: 'G-HL037CXM5Z'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
