// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.ds-feedback-loop {
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.ds-feedback-loop h3 {
  margin: var(--spacing-0);
}
.ds-feedback-loop > :first-child {
  margin-bottom: var(--spacing-3);
}
.ds-feedback-actions {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: var(--spacing-4);
}
.ds-feedback-button {
  color: var(--color-core-neutral-10);
  background: transparent;
  border-radius: var(--radius-2);
  outline: var(--border-1) solid var(--color-core-neutral-10);
  gap: var(--spacing-1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-2) var(--spacing-4);
  margin: var(--spacing-0);
}
.ds-feedback-button > ion-icon {
  font-size: var(--fs-4);
}
.ds-feedback-icon {
  font-size: var(--fs-7);
}
.ds-feedback-link {
  margin: var(--spacing-8) 0;
}
.ds-feedback-link ::ng-deep a {
  border: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
