// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offline-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  padding-top: var(--ion-safe-area-top);
}

h1 {
  color: var(--ion-color-primary);
  font-family: var(--font-secondary);
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}

p {
  color: var(--ion-color-primary);
  margin-bottom: 10px;
  text-align: start;
}

img {
  margin: 0 auto;
  position: absolute;
  top: calc(var(--ion-safe-area-top) + 30px);
  height: auto;
  width: 142px;
}

ion-button {
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
