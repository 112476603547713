import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-asset-deposit',
  styleUrls: ['./asset-deposit.component.scss'],
  templateUrl: './asset-deposit.component.html'
})
export class AssetDepositComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currency: string;
  @Input() appLocation: string;
  @Input() kind: string;
  @Input() inputError: string;
  @Input() maximumInput: number;
  @Input() minimumInput: number;
  @Input() newSavings: number;
  @Input() selectedChip: number;
  @Output() changeValue: EventEmitter<number | null> = new EventEmitter<number | null>();
  @Output() setDeposit: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('amountInput') amountInput: ElementRef;

  depositForm: FormGroup;
  invested: number;
  limitedValue: number;
  numRegex = /^-?\d*[.,]?\d{0,2}$/;
  progressPercentage: string;
  showPlaceholder = true;
  maxLength = 9;
  year = new Date().getFullYear();
  protected readonly onDestroy$ = new Subject<void>();

  get assetControl(): AbstractControl {
    return this.depositForm?.get('asset');
  }

  get roundedMonthlySavings() {
    return Number(this.newSavings?.toFixed(1));
  }

  get roundedYearlySavings() {
    return Number((this.newSavings * 12)?.toFixed(1));
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.depositForm = this.initializeForm();

    this.assetControl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((value: undefined | null | number) => {
        if (!(typeof value === 'undefined' || value === null)) {
          if (value < 0) {
            this.assetControl.patchValue(null, { onlySelf: true, emitEvent: false });
            this.assetControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
            return;
          }
          if (value.toString().length > this.maxLength) {
            // eslint-disable-next-line radix
            this.assetControl.patchValue(parseInt(value.toString().slice(0, this.maxLength), 10), { onlySelf: true, emitEvent: false });
            this.assetControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
            return;
          }
        }
        this.changeValue.emit(value);
        this.setProgressPercentage();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['newSavings'] && !changes['newSavings'].isFirstChange()) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.updateForm(this.selectedChip === 0 || this.kind !== '3a' ? this.roundedMonthlySavings : this.roundedYearlySavings);
    }

    if (this.kind === '3a') {
      if (changes['selectedChip']) {
        this.updateForm(changes['selectedChip']?.currentValue === 0 ? this.roundedMonthlySavings : this.roundedYearlySavings);
      }

      if (changes['inputError'] || changes['maximumInput'] || changes['minimumInput']) {
        this.limitedValue = this.inputError === 'overMaximum' ? this.maximumInput : this.minimumInput;
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initializeForm(): FormGroup {
    return this.fb.group({
      asset: [this.roundedMonthlySavings, [Validators.required, Validators.pattern(this.numRegex)]]
    });
  }

  updateForm(value: number, emitEvent = false) {
    this.assetControl?.patchValue(value, { onlySelf: true, emitEvent });
    this.assetControl?.updateValueAndValidity({ onlySelf: true, emitEvent });
    this.setProgressPercentage();
  }

  onSelectInput() {
    this.showPlaceholder = false;
    setTimeout(() => {
      this.amountInput.nativeElement.focus();
    });
  }

  onResetDefault() {
    this.updateForm(this.roundedMonthlySavings, true);
    this.showPlaceholder = true;
  }

  // eslint-disable-next-line consistent-return
  onSetDeposit(): void {
    this.setDeposit.emit(this.assetControl?.value || 0);
    setTimeout(() => {
      this.showPlaceholder = true;
    }, 1000);
  }

  setProgressPercentage() {
    const currentSavings = this.depositForm?.controls?.asset?.value || this.roundedMonthlySavings;
    this.progressPercentage = (((currentSavings - this.minimumInput) / (this.maximumInput - this.minimumInput)) * 100).toString();
  }
}
