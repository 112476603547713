import { Component, Input } from '@angular/core';

export type DSCalloutVariants = 'success' | 'info' | 'warning' | 'critical';

@Component({
  selector: 'app-ds-callout',
  templateUrl: './ds-callout.component.html',
  styleUrls: ['./ds-callout.component.scss']
})
export class DsCalloutComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() variant: DSCalloutVariants = 'info';

  iconList = {
    success: 'circle-check-big',
    info: 'info',
    warning: 'triangle-alert',
    critical: 'circle-x'
  };
}
