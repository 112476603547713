import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ds-button',
  templateUrl: './ds-button.component.html',
  styleUrls: ['./ds-button.component.scss']
})
export class DsButtonComponent {
  @Output() clicked: EventEmitter<MouseEvent | TouchEvent> = new EventEmitter();

  @Input() type: 'button' | 'submit' = 'button';
  @Input() size: 'default' | 'small' = 'default';
  @Input() width: 'auto' | 'full' = 'full';
  @Input() routerLink = '';
  @Input() tabIndex = 0;
  @Input() disabled = false;
  @Input() isLoading = false;

  @Input() variant: 'primary' | 'outline' = 'primary';
  @Input() icon = '';

  iconColorSet = {
    disabled: 'var(--color-core-neutral-5)',
    primary: 'var(--color-core-neutral-0)',
    outline: 'var(--color-core-neutral-10)'
  };

  iconSizeSet = {
    default: 'icon-size-2',
    small: 'icon-size-1'
  };
}
