// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

p {
  margin: 12px 0;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.list-item {
  display: flex;
  margin-bottom: 40px;
}
.list-item.big {
  flex-direction: column-reverse;
}
.list-item.big .list-item__logo {
  width: 100%;
  height: 228px;
  margin-bottom: 12px;
}

.list-item__logo {
  align-items: center;
  display: flex;
  height: 72px;
  width: 110px;
  justify-content: center;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--ion-color-light-shade);
  background-size: cover;
  background-position: center;
}
.list-item__logo img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.list-item__logo .campaign-badge {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(-6deg);
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  background: var(--ion-color-success);
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.005em;
  color: var(--ion-color-primary);
  font-family: var(--font-secondary);
}
.list-item__logo .campaign-badge.small {
  font-size: 18px;
}
@media screen and (max-width: 375px) {
  .list-item__logo .campaign-badge.small {
    font-size: 16px;
  }
}
.list-item__logo .campaign-liked {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  background: var(--ion-color-primary);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-item__logo .campaign-liked ion-icon {
  font-size: 18px;
  color: var(--ion-color-light);
}

.list-item__details {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  margin-right: 4px;
}
.list-item__details .title {
  color: var(--ion-color-tertiary);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.list-item__details-date {
  color: var(--ion-color-tertiary);
  font-size: 12px;
  font-weight: 500;
}

.list-item__details-cta {
  color: var(--ion-color-primary);
  font-size: 12px;
  font-weight: 700;
}

.list-item__engagement {
  color: var(--ion-color-success);
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.list-item__engagement span {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0;
}
@media only screen and (min-width: 360px) {
  .list-item__engagement span {
    letter-spacing: 2px;
  }
}
@media only screen and (min-width: 390px) {
  .list-item__engagement span {
    letter-spacing: 3px;
  }
}
.list-item__engagement ion-icon {
  opacity: 1;
  width: 16px;
  height: 16px;
}

.campaign-update-badge {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(-6deg);
}
.campaign-update-badge span,
.campaign-update-badge p {
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  background: var(--ion-color-success);
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.005em;
  color: var(--ion-color-primary);
  font-family: var(--font-secondary);
}
.campaign-update-badge span {
  background-color: var(--ion-color-light);
  position: relative;
  top: -7px;
  font-size: 21px;
}
.campaign-update-badge.small p {
  font-size: 21px;
}
@media screen and (max-width: 375px) {
  .campaign-update-badge.small p {
    font-size: 18px;
  }
}
.campaign-update-badge.small span {
  top: -10px;
  font-size: 18px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
