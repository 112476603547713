import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
  pure: true
})
export class PhonePipe implements PipeTransform {
  transform(value: string, confidential = false): string {
    if (!value) {
      return value;
    }
    if (value.length === 10 && value.startsWith('07')) {
      if (confidential) {
        return `07* *** ** ${value.slice(8, 10)}`;
      }
      return `${value.slice(0, 3)} ${value.slice(3, 6)} ${value.slice(6, 8)} ${value.slice(8, 10)}`;
    } else if (value.length === 12 && value.startsWith('+41')) {
      if (confidential) {
        return `+41 ** *** ** ${value.slice(10, 12)}`;
      }
      return `${value.slice(0, 3)} ${value.slice(3, 5)} ${value.slice(5, 8)} ${value.slice(8, 10)} ${value.slice(10, 12)}`;
    } else if (value.length === 13 && value.startsWith('0041')) {
      if (confidential) {
        return `0041 ** *** ** ${value.slice(11, 13)}`;
      }
      return `${value.slice(0, 4)} ${value.slice(4, 6)} ${value.slice(6, 9)} ${value.slice(9, 11)} ${value.slice(11, 13)}`;
    } else {
      return value;
    }
  }
}
