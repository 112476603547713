import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  pure: true
})
export class TruncatePipe implements PipeTransform {
  transform(text: string, length = 20, suffix = '...', checkWhiteSpace = false): string {
    const includeWhitespace = checkWhiteSpace || text.indexOf(' ') < 0;
    if (text.length > length && includeWhitespace) {
      return text.substring(0, length - suffix.length).trim() + suffix;
    }

    return text;
  }
}
