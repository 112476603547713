import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from '@inyova/models';

import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, share } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

interface AuthData {
  accessToken: string;
  client: string;
  expiry: string;
  tokenType: string;
  uid: string;
}

interface ApiResponse {
  authentication_token?: string;
  data?: Customer;
  phone?: string;
  status?: string;
  success?: string;
}

interface ApiResponse {
  body?: any;
  data?: Customer;
  errors?: any;
  status?: string;
  statusText?: string;
  success?: string;
}

interface ResetPasswordData {
  login: string;
}

@Injectable({ providedIn: 'root' })
export class TokenService {
  public authData: BehaviorSubject<AuthData> = new BehaviorSubject<AuthData>(null);

  constructor(private http: HttpClient) {}

  // SignIn method
  signIn(signInData: { login: string; password: string }): Observable<ApiResponse> {
    const body = {
      email: signInData.login,
      password: signInData.password,
      mobile_app: true
    };

    return this.http.post<ApiResponse>(`${environment.apiUrl}/auth/sign_in`, body).pipe(share());
  }

  // Sign out request and delete storage
  signOut(): Observable<ApiResponse> {
    return (
      this.http
        .delete<ApiResponse>(`${environment.apiUrl}/auth/sign_out`)
        // Only remove the localStorage and clear the data after the call
        .pipe(
          finalize(() => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('client');
            localStorage.removeItem('expiry');
            localStorage.removeItem('tokenType');
            localStorage.removeItem('uid');

            this.authData.next(null);
          })
        )
    );
  }

  // Validate token request
  validateToken(): Observable<ApiResponse> {
    const observ$ = this.http.get<ApiResponse>(`${environment.apiUrl}/auth/validate_token`);
    observ$.subscribe(
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      (error) => {
        if (error.status === 401) {
          this.signOut();
        }
      }
    );

    return observ$;
  }

  // Reset password request
  resetPassword(resetPasswordData: ResetPasswordData): Observable<{ data: Customer; status: boolean }> {
    return this.http.post<{ data: Customer; status: boolean }>(`${environment.apiUrl}/auth/password`, { email: resetPasswordData.login });
  }

  // Parse Auth data from response
  getAuthHeadersFromResponse(data: HttpResponse<any> | HttpErrorResponse): void {
    const { headers } = data;

    const authData: AuthData = {
      accessToken: headers.get('access-token'),
      client: headers.get('client'),
      expiry: headers.get('expiry'),
      tokenType: headers.get('token-type'),
      uid: headers.get('uid')
    };

    this.setAuthData(authData);
  }

  // Try to get auth data from storage.
  getAuthDataFromStorage(): void {
    const authData: AuthData = {
      accessToken: localStorage.getItem('accessToken'),
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      tokenType: localStorage.getItem('tokenType'),
      uid: localStorage.getItem('uid')
    };

    if (this.checkAuthData(authData)) {
      this.authData.next(authData);
    }
  }

  // Write auth data to storage
  private setAuthData(authData: AuthData): void {
    if (!this.checkAuthData(authData)) return;

    this.authData.next(authData);

    localStorage.setItem('accessToken', authData.accessToken);
    localStorage.setItem('client', authData.client);
    localStorage.setItem('expiry', authData.expiry);
    localStorage.setItem('tokenType', authData.tokenType);
    localStorage.setItem('uid', authData.uid);
  }

  // Check if auth data complete and if response token is newer
  private checkAuthData(authData: AuthData): boolean {
    if (
      authData.accessToken != null &&
      authData.client != null &&
      authData.expiry != null &&
      authData.tokenType != null &&
      authData.uid != null
    ) {
      if (this.authData.value != null) {
        return authData.expiry >= this.authData.value.expiry;
      }
      return true;
    }
    return false;
  }
}
