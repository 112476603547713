// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-icon {
  font-size: 16px;
  color: #b4b3b1;
}

ion-content {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-end: 16px;
  --padding-start: 16px;
  --background: var(--ion-color-primary);
  --color: var(--ion-color-white);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
}
ion-content ion-icon[name=close] {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 16px;
  color: var(--ion-color-white);
}

ion-popover::part(arrow)::after {
  background: var(--ion-color-primary);
}

ion-popover {
  --min-width: calc(100% - 12px);
  --max-height: 100px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
