import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

interface SimpleResponse {
  status: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class DeviceResources {
  private readonly URL: string;

  constructor(private http: HttpClient) {
    this.URL = `${environment.apiUrl}`;
  }

  createNewDevice(deviceUuid: string, token?: string): Observable<SimpleResponse> {
    const data: { deviceuuid: string; fcm_token?: string } = { deviceuuid: deviceUuid };
    if (token) {
      data.fcm_token = token;
    }
    return this.http.post<SimpleResponse>(`${this.URL}/mobile/device`, data);
  }

  validateDevice(deviceUuid: string, biometricToken: string): Observable<SimpleResponse> {
    return this.http.get<SimpleResponse>(`${this.URL}/mobile/device/${deviceUuid}/${biometricToken}/validate`);
  }

  removeDevice(deviceUuid: string): Observable<SimpleResponse | unknown> {
    // When there is no device uuid (web application), return empty observable
    if (!deviceUuid) {
      return new Observable((observer) => observer.next({}));
    }
    return this.http.delete<SimpleResponse>(`${this.URL}/mobile/device/${deviceUuid}`);
  }

  removeDeviceBiometric(deviceUuid: string, token: string): Observable<SimpleResponse> {
    return this.http.delete<SimpleResponse>(`${this.URL}/mobile/device/${deviceUuid}/${token}`);
  }

  removeDeviceNotifications(deviceUuid: string): Observable<SimpleResponse> {
    return this.http.delete<SimpleResponse>(`${this.URL}/mobile/device/${deviceUuid}/notifications`);
  }
}
