// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds-header-stepper {
  margin-bottom: var(--spacing-5);
}
.ds-header-stepper .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-4);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
