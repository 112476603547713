export interface AccountDocument {
  id: number;
  type: string;
  attributes: {
    type_id: number;
    document_type_name: string;
    filename: string;
    title: string;
    translated_title: string;
  };
}

export interface GrowDocument {
  title: string;
  filename: string;
  type: string;
  project_id: number;
  project_title: string;
}

export interface GrowProjectDocuments {
  title: string;
  documents: GrowDocument[];
}

export interface StaticDocument {
  attributes: {
    category: string;
    title: string;
    account_type: null | string;
  };
  id: string;
  type: string;
}

export interface AccountLibertyDocument {
  id: string;
  type: string;
  attributes: AccountLibertyDocumentAttributes;
}

export interface AccountLibertyDocumentAttributes {
  document_type: string;
  document_type_code: string;
  document_date: Date;
}

export interface AccountLibertyMeta {
  stats?: { total: { count: number } };
}

export interface Transaction {
  transaction_date: string;
  transaction_type: TransactionType;
  transaction_value: number;
  type_translated: string;
}

export interface TransactionsView {
  appLocation: 'ch' | 'de' | 'at';
  currency: string;
  currentYear: number;
  depositThisYear: number;
  kind: '3b' | '3a' | 'kid' | 'ci' | 'grow';
  loaded: boolean;
  maxContributionLevel: 'large' | 'small' | 'transfer_only';
  ownerName: string;
  transactions: { [key: string]: Transaction[] };
  possibleYearlyDeposit: number;
  inyovaFee: number;
}

export interface Promotions {
  data: {
    id: number;
    type: string;
    relationships: PromotionsTypeRelationships;
    attributes: {
      valid_from: string;
    };
  }[];
  included: PromotionsTypeIncluded[];
  meta: PromotionsRewards;
}

export interface PromotionsRewards {
  active_rewards: {
    money_monthly: number;
    months: number;
    trees_planted: number;
  };
  referral_rewards: {
    months: number;
    money: {
      money_monthly: number;
      months_duration: number;
    };
  };
  pending_rewards: {
    money_overall: number;
    months: number;
  };
}

export interface PromotionsTypeRelationships {
  promotion: {
    data: {
      id: number;
      type: string;
    };
    links: {
      related: string;
    };
  };
}

export interface PromotionsTypeIncluded {
  id: string;
  type: string;
  attributes: PromotionsTypeIncludedAttributes;
}

export interface PromotionsTypeIncludedAttributes {
  type: string;
  months_duration: string;
  value: number;
  code: string;
}

export enum TransactionType {
  DEPOSIT = 'DEPOSIT',
  FEE = 'FEE',
  REIMBURSEMENT = 'REIMBURSEMENT',
  WITHDRAWAL = 'WITHDRAWAL',
  MONEY_PROMOTION = 'MONEY_PROMOTION'
}

export enum DocumentsTabs {
  INVESTMENT_3B = 'investment3b',
  PILLAR_3A = 'pillar3a',
  REPORTS = 'reports',
  CROWD_INVESTING = 'ci',
  GROW = 'grow'
}

export interface DeviceInfo {
  data: {
    biometric_token: string;
  };
  status: 'success' | 'error';
}

export interface Address {
  id?: number;
  city: string;
  country: string;
  number: string;
  street: string;
  zip: string;
}

export interface Notification {
  id: number;
  type: string;
  attributes: NotificationAttributes;
  relationships?: NotificationRelationships;
}

export interface NotificationAttributes {
  notifiable_type?: 'Account' | 'Customer';
  notifiable_id?: number;
  title: string;
  body: string;
  data: NotificationAttributesData;
  seen: boolean;
  updated_at?: Date;
  created_at?: Date;
  image: string;
}

export interface NotificationAttributesData {
  account_id?: string;
  engagement?: boolean;
  page: string;
  subtype?: string;
  type?: string;
  impact_story_id?: number;
}

export interface NotificationRelationships {
  notifiable: {
    links: {
      related: string;
    };
  };
}

export interface NotificationData {
  image?: string;
  notification_id?: string;
  page?: string;
  [other: string]: any;
}

export interface TrackingDetails {
  account_id?: string;
  content: string;
  location: string;
  platform: string;
}

export interface PersonalDetails {
  email: string;
  first_name: string;
  last_name: string;
  language: string;
  street: string;
  number: string;
  zip: string;
  country: string;
  city: string;
  phone: string;
  verified_phone: string;
  phone_verified: boolean;
  unverified_phone: string;
}

export interface Documents3AStore {
  initialized: boolean;
  pagination: {
    total: number;
  };
  documents: AccountLibertyDocument[];
}

export interface PostAPIZipValidation {
  records: [{ datasetid: string; fields: { ortbez18: string }; geometry: { type: string }; record_timestamp: string; recordid: string }];
}

export interface ABTest {
  id: number;
  type: string;
  attributes: {
    customer_id: number;
    experiment_id: string;
    alternative: string;
  };
}

export interface CustomerPersonalDetails {
  email: string;
  first_name: string;
  last_name: string;
  language: string;
  street: string;
  number: string;
  zip: string;
  country: string;
  city: string;
  phone: string;
  verified_phone: string;
  phone_verified: boolean;
  unverified_phone: string;
}

export interface CreatedPromotions {
  data: {
    type: string;
    id: number;
    attributes: {
      code: string;
    };
    relationships: {
      promotions: {
        links: {
          related: string;
        };
        data: {
          type: string;
          id: number;
        };
      };
    };
  };
  included: [];
}

export interface ProfileBannerItem {
  id: string;
  type: 'profile_banner';
  attributes: {
    title: string;
    body: string;
    image: string;
    data: {
      page: string;
      account_id?: string;
    };
  };
}

export interface FinancialSituation {
  id: number;
  financial_assets: number;
  real_estate_assets: number;
  other_assets: number;
  debt_amount: number;
  freely_cash_available: number;
  monthly_income: number;
  freely_monthly_income_available: number;
  customer_iban: number;
  allow_bank_tin_check: boolean;
  allow_bank_church_check: boolean;
  tax_jurisdictions: FinancialSituationTaxJurisdiction[];
  bank_detail: FinancialSituationBankDetail;
}

export interface FinancialSituationBankDetail {
  address: string;
  bank: string;
  bic: string;
  city: string;
  country: string;
  zip: string;
}

export interface FinancialSituationInvestmentProfile {
  id: number;
  initial_investment: number;
  monthly_investment: number;
  changed_during_onboarding: boolean;
}

export interface FinancialSituationTaxJurisdiction {
  id: number;
  country_code: string;
  tin: string;
}

export interface SavingsPlanSocialProof {
  average: number;
  above_average: number;
  below_average: number;
  percentage: number;
}

export interface IBANItem {
  id: string;
  type: string;
  attributes: {
    bank: string;
    iban: string;
  };
}

export interface BankAccountDetail {
  bank: string;
  bic: string;
  address: string;
  city: string;
  country: string;
  zip: string;
  error?: string;
  errors?: string;
}

export interface AccountOptions {
  type: string;
  attributes: {
    account_id: string;
    interest_for_portfolio_review: boolean;
    interest_for_deposit_control: boolean;
  };
}

export interface SavingsPlanSummary {
  amount_funded_this_year: number;
  total_amount_for_current_year: number;
  amount_still_needed: number;
  status: string;
  percentage_funded: number;
}
