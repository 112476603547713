/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Action, createReducer, on } from '@ngrx/store';
import * as AccountActions from '@account/account.actions';
import * as HomeActions from '@home/home.actions';

import { AccountOptions, SavingsPlanSummary } from '@shared/models/Account';
import {
  BalanceSheet,
  CompaniesStockPerformanceAccount,
  CrowdInvestingValues,
  EndValuesAccount,
  PerformanceAccount,
  PerformanceData,
  PerformanceFilterRange,
  PerformanceSummary,
  PerformanceType
} from '@shared/models/Home';

import { addOrUpdateAccount } from '../home.utils';

export interface PerformanceState {
  accountsGraphData: PerformanceAccount[];
  accountsEndValues: EndValuesAccount[];
  accountOptions: AccountOptions;
  companiesStockPerformance: null | CompaniesStockPerformanceAccount[];
  crowdInvestorData: CrowdInvestingValues;
  joinedFakeDoor: boolean;
  savingsPlanSummary: SavingsPlanSummary | null;
  performanceData: PerformanceData;
  performanceSummary: PerformanceSummary | null;
  balanceSheet: BalanceSheet | null;
}

export const initialState: PerformanceState = {
  accountsGraphData: [],
  accountsEndValues: [],
  companiesStockPerformance: [],
  crowdInvestorData: {
    number_of_shares: 0,
    total_stock_value: 0
  },
  accountOptions: {
    type: 'account_options',
    attributes: {
      account_id: '',
      interest_for_portfolio_review: false,
      interest_for_deposit_control: false
    }
  },
  joinedFakeDoor: false,
  savingsPlanSummary: null,
  performanceData: {
    graphData: [],
    loading: false,
    filter: {
      range: PerformanceFilterRange.ALL,
      chartType: PerformanceType.BALANCE
    }
  },
  performanceSummary: null,
  balanceSheet: null
};

const performanceReducer = createReducer(
  initialState,
  on(HomeActions.getEndValuesSuccess, (state, action) => ({
    ...state,
    endValues: action.data
  })),
  on(HomeActions.getCompaniesStockPerformanceSuccess, (state, action) => ({
    ...state,
    companiesStockPerformance: addOrUpdateAccount(
      { account: action.account, initialised: true, companies: action.data, hasError: false },
      state.companiesStockPerformance
    ) as CompaniesStockPerformanceAccount[]
  })),
  on(HomeActions.getCompaniesStockPerformanceFail, (state, action) => ({
    ...state,
    companiesStockPerformance: addOrUpdateAccount(
      { account: action.account, initialised: true, companies: [], hasError: true },
      state.companiesStockPerformance
    ) as CompaniesStockPerformanceAccount[]
  })),
  on(HomeActions.getGraphData, (state, action) => ({
    ...state,
    accountsGraphData: addOrUpdateAccount(
      {
        account: action.account,
        initialised: false,
        graphData: [],
        hasError: false
      },
      state.accountsGraphData
    ) as PerformanceAccount[]
  })),
  on(HomeActions.getGraphDataSuccess, (state, action) => ({
    ...state,
    accountsGraphData: addOrUpdateAccount(
      {
        account: action.account,
        initialised: true,
        graphData: action.graphData,
        hasError: false
      },
      state.accountsGraphData
    ) as PerformanceAccount[]
  })),
  on(HomeActions.getPerformanceGraph, (state, { range }) => ({
    ...state,
    performanceData: {
      ...state.performanceData,
      loading: range !== PerformanceFilterRange.ALL,
      ...(range === PerformanceFilterRange.ALL && { filter: { ...state.performanceData.filter, range: PerformanceFilterRange.ALL } })
    }
  })),
  on(HomeActions.getPerformanceGraphSuccess, (state, action) => ({
    ...state,
    performanceData: {
      graphData: [...action.graphData],
      loading: false,
      filter: {
        ...state.performanceData.filter,
        range: action.range
      }
    }
  })),
  on(HomeActions.getPerformanceGraphFailed, (state) => ({
    ...state,
    performanceData: {
      ...state.performanceData,
      loading: false
    }
  })),
  on(HomeActions.togglePerformanceGraphType, (state, { chartType }) => ({
    ...state,
    performanceData: {
      ...state.performanceData,
      filter: {
        ...state.performanceData.filter,
        chartType
      }
    }
  })),
  on(HomeActions.getEndValues, (state, action) => ({
    ...state,
    accountsEndValues: addOrUpdateAccount(
      {
        account: action.account,
        initialised: false,
        endValues: null,
        hasError: false
      },
      state.accountsEndValues
    ) as EndValuesAccount[]
  })),
  on(HomeActions.getEndValuesSuccess, (state, action) => ({
    ...state,
    accountsEndValues: addOrUpdateAccount(
      {
        account: action.account,
        initialised: true,
        endValues: action.data,
        hasError: false
      },
      state.accountsEndValues
    ) as EndValuesAccount[]
  })),
  on(HomeActions.getGraphDataFail, (state, action) => ({
    ...state,
    error: action.error,
    accountsGraphData: addOrUpdateAccount(
      { account: action.account, initialised: true, graphData: null, hasError: true },
      state.accountsGraphData
    ) as PerformanceAccount[]
  })),
  on(HomeActions.getCrowdInvestingDataSuccess, (state, action) => ({
    ...state,
    crowdInvestorData: action.data
  })),
  on(HomeActions.getAccountOptionsSuccess, (state, action) => ({
    ...state,
    accountOptions: action.data,
    joinedFakeDoor: action.data.attributes.interest_for_deposit_control || action.data.attributes.interest_for_portfolio_review
  })),
  on(HomeActions.getAccountOptionsFail, (state) => ({
    ...state,
    joinedFakeDoor: false
  })),
  on(HomeActions.setAccountOptionsSuccess, (state, action) => ({
    ...state,
    joinedFakeDoor: action.data.attributes.interest_for_deposit_control || action.data.attributes.interest_for_portfolio_review
  })),
  on(HomeActions.getSavingsPlanSummarySuccess, (state, action) => ({
    ...state,
    savingsPlanSummary: action.data
  })),
  on(HomeActions.getSavingsPlanSummaryFail, (state, action) => ({
    ...state,
    savingsPlanSummary: action.data
  })),
  on(HomeActions.getPerformanceSummarySuccess, (state, action) => ({
    ...state,
    performanceSummary: action.data
  })),
  on(HomeActions.getPerformanceSummaryFail, (state) => ({
    ...state,
    performanceSummary: null
  })),
  on(HomeActions.getBalanceSheetSuccess, (state, action) => ({
    ...state,
    balanceSheet: action.data
  })),
  on(HomeActions.getBalanceSheetFail, (state) => ({
    ...state,
    balanceSheet: null
  })),
  on(AccountActions.getCurrentAccount, (state) => ({
    ...state,
    performanceData: {
      graphData: [],
      loading: false,
      filter: {
        range: PerformanceFilterRange.ALL,
        chartType: PerformanceType.BALANCE
      }
    }
  })),
  // NgRx store clean up after logout
  on(AccountActions.customerLogoutAndUnpairDevice, () => ({ ...initialState }))
);

export function reducerPerformance(state: PerformanceState | undefined, action: Action) {
  return performanceReducer(state, action);
}

export const getPerformanceData = (state: PerformanceState) => state.performanceData;
export const getPerformanceDataFilter = (performanceData: PerformanceData) => performanceData.filter;
export const getPerformanceGraphData = (performanceData: PerformanceData) => performanceData.graphData;
export const getPerformanceDataLoading = (performanceData: PerformanceData) => performanceData.loading;
