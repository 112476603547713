// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  padding-top: var(--ion-safe-area-top);
}

ion-content {
  --padding-start: 15px;
  --padding-end: 15px;
  --padding-bottom: 0;
  color: var(--ion-color-primary);
}
ion-content.ion-content--thank-you {
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
}

ion-footer {
  padding: 0 15px 15px 15px;
}

ion-title {
  font-size: 14px;
  color: var(--ion-color-primary);
  font-weight: 500;
  line-height: 20px;
}

ion-label {
  display: block;
  font-size: 12px;
  color: var(--ion-color-tertiary);
  font-weight: 500;
  margin-bottom: 16px;
}
ion-label.superhuman__question-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--ion-color-primary);
}

p {
  margin-bottom: 1.2em;
}

.ion-content__background {
  width: 100%;
  height: 100%;
  background-image: url('superhuman.3b4b041996ce0cdf.png');
  background-position: bottom right;
  background-size: auto auto;
  background-repeat: no-repeat;
  position: relative;
}
.ion-content__background .title {
  font-family: var(--font-secondary);
  font-size: 40px;
  margin-bottom: 4px;
  line-height: 48px;
  font-weight: 500;
  padding-top: 250px;
}
.ion-content__background p {
  padding: 0 15px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.ion-content__background ion-button {
  margin: 0 15px 30px 15px;
  position: absolute;
  bottom: 52px;
  left: 0;
  width: calc(100% - 30px);
}

.superhuman__stepper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
  margin-top: 20px;
}

.superhuman__stepper-item {
  flex: 1;
  background-color: var(--ion-color-secondary);
  height: 6px;
  margin: 0 2px;
}
.superhuman__stepper-item--active {
  background-color: var(--ion-color-success);
}

.superhuman__answers-options {
  padding: 16px;
  border: 1px solid var(--ion-color-primary);
  border-radius: 30px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 8px;
}
.superhuman__answers-options--active {
  border-color: var(--ion-color-success);
  background-color: var(--ion-color-success);
  color: var(--ion-color-white);
  font-weight: 700;
}

.superhuman__rating-options {
  display: flex;
  align-items: center;
}

.superhuman__rating-item {
  flex: 1;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--ion-color-primary);
  border-left: none;
}
.superhuman__rating-item--active {
  background-color: var(--ion-color-success);
  color: var(--ion-color-white);
}
.superhuman__rating-item:first-of-type {
  border-left: 1px solid var(--ion-color-primary);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.superhuman__rating-item:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

ion-footer ion-button {
  margin-top: 12px;
}
ion-footer ion-button:last-child {
  margin-bottom: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
