// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mini-widget {
  display: block;
  margin-bottom: var(--spacing-8);
  margin-inline: var(--spacing-4);
  flex-direction: column;
  background: var(--color-core-neutral-0);
  box-shadow: var(--shadow-elavated);
  border-radius: var(--radius-2);
  padding: var(--spacing-4);
}
.mini-widget ion-icon {
  color: var(--color-core-neutral-7);
}
.mini-widget .status,
.mini-widget .title {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
}
.mini-widget .status-icon {
  display: inline-flex;
  margin-left: auto;
}
.mini-widget .mini-info {
  margin-top: var(--spacing-3);
  font-size: var(--fs-2);
  line-height: var(--flh-3);
  font-weight: var(--fw-500);
  letter-spacing: var(--fls-5);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
