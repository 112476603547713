// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds-allocation-bar {
  display: flex;
  overflow: hidden;
  margin-bottom: var(--spacing-3);
  height: var(--spacing-6);
  border-radius: var(--radius-2);
}
.ds-allocation-bar .bar {
  height: 100%;
  --custom-color: var(--color-core-neutral-3);
  background-color: var(--custom-color);
}
.ds-allocation-bar .bar + .bar {
  border-left: var(--border-1) solid var(--color-core-brand-3-1);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
