import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private toastController: ToastController) {}
  async errorWithClose(message: string, duration = 0) {
    const toast = await this.toastController.create({
      message,
      mode: 'md',
      position: 'top',
      color: 'danger',
      buttons: [
        {
          side: 'end',
          icon: 'close',
          role: 'cancel'
        }
      ]
    });
    void toast.present();

    void toast.onDidDismiss().then((event) => {
      if (event.role !== 'cancel') {
        void this.error(message, duration);
      }
    });
  }

  async error(message: string, duration = 4000) {
    const toast = await this.toastController.create({
      duration,
      message,
      mode: 'md',
      position: 'top',
      color: 'danger'
    });
    void toast.present();
  }

  async show(message: string, duration = 3800) {
    const toast = await this.toastController.create({
      duration,
      message,
      mode: 'md',
      position: 'top',
      color: 'success'
    });
    void toast.present();
  }

  async success(message: string, duration = 3800) {
    const toast = await this.toastController.create({
      duration,
      message,
      mode: 'md',
      position: 'top',
      color: 'success'
    });
    void toast.present();
  }
}
