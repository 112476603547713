import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PromoCampaignItem } from '@app/shared/models/Home';
import { TrackingService } from '@app/shared/services/tracking.service';

@Component({
  selector: 'app-home-campaign-banner',
  styleUrls: ['./home-campaign-banner.component.scss'],
  templateUrl: './home-campaign-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeCampaignBannerComponent implements OnInit {
  @Input() currency!: string;
  @Input() campaign: PromoCampaignItem;

  highestAmount: number;

  constructor(
    private router: Router,
    private trackingService: TrackingService
  ) {}

  ngOnInit(): void {
    this.highestAmount = Math.max(...this.campaign.promotions.map((o) => o.attributes.value));
  }

  goToPromo() {
    this.trackingService.trackActivity(`[Home] Home promo campaign widget Clicked: ${this.campaign.attributes.name}`);
    void this.router.navigate([`/tabs/home/promo-campaign/${this.campaign.id}`]);
  }
}
