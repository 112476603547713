import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

enum FeedbackSteps {
  INIT = 'init',
  FINISH = 'finish'
}
@Component({
  selector: 'app-ds-feedback-loop',
  templateUrl: './ds-feedback-loop.component.html',
  styleUrls: ['./ds-feedback-loop.component.scss']
})
export class DsFeedbackLoopComponent implements OnInit {
  // TODO: Remove translation, translate pipe dependencies
  // TODO: Removed hard coded positive, negative feedback buttons
  @Input() feedbackSource: string;
  @Input() showLink = true;
  @Output() trackFeedback = new EventEmitter<string>();

  activeStep;
  feedbackSteps = FeedbackSteps;

  ngOnInit(): void {
    this.activeStep = FeedbackSteps.INIT;
  }

  onFeedback(isPositive: boolean) {
    this.trackFeedback.emit(`[Button] Feedback loop: ${this.feedbackSource} - ${isPositive ? 'Yes' : 'No'}`);
    this.activeStep = FeedbackSteps.FINISH;
  }
}
