import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-tooltip',
  styleUrls: ['./tooltip.component.scss'],
  templateUrl: './tooltip.component.html'
})
export class TooltipComponent {
  @Input() trigger: string;

  constructor(private popoverCtrl: PopoverController) {}

  dismiss() {
    void this.popoverCtrl.dismiss();
  }
}
