// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-input:focus-visible:not(:disabled) + .type-box {
  box-shadow: var(--shadow-input-border-selected);
  outline-offset: var(--border-1);
  outline: var(--border-3) solid var(--color-core-brand-2-4);
}
.checkbox-input:focus-visible:not(:disabled) + .type-box.has-error:not(.checked) {
  box-shadow: var(--shadow-input-border-error);
}
.checkbox-input:focus-visible:not(:disabled) + .ds-checkbox:not(.type-box) .checkbox-border {
  box-shadow: var(--shadow-input-border-selected);
  outline-offset: var(--border-1);
  outline: var(--border-3) solid var(--color-core-brand-2-4);
}
.checkbox-input:focus-visible:not(:disabled) + .ds-checkbox:not(.type-box).has-error:not(.checked) .checkbox-border {
  box-shadow: var(--shadow-input-border-error);
}

.ds-checkbox-holder {
  cursor: pointer;
}

.ds-checkbox {
  display: flex;
  padding: var(--spacing-2) 0;
  gap: var(--spacing-2);
  align-items: flex-start;
}
.ds-checkbox.type-box {
  padding: var(--spacing-4);
  border-radius: var(--radius-2);
  outline: var(--border-1) solid var(--color-core-neutral-4);
}
.ds-checkbox.disabled {
  color: var(--color-core-neutral-5);
}
.ds-checkbox.align-center {
  align-items: center;
}
.ds-checkbox .checkbox-border {
  display: flex;
  width: var(--icon-size-2);
  height: var(--icon-size-2);
  min-width: var(--icon-size-2);
  min-height: var(--icon-size-2);
  justify-content: center;
  outline: var(--border-1) solid var(--color-core-neutral-4);
  border-radius: var(--radius-1);
  background-color: var(--color-core-neutral-0);
  align-items: center;
}
.ds-checkbox.has-error .checkbox-border {
  outline-color: var(--color-system-critical-3);
}
.ds-checkbox.has-error.type-box {
  outline-color: var(--color-system-critical-3);
}
.ds-checkbox.has-error.type-box .checkbox-border {
  outline: var(--border-1) solid var(--color-core-neutral-4);
}
.ds-checkbox.checked .checkbox-border {
  background: var(--color-core-neutral-10);
  outline: none;
}
.ds-checkbox.checked.type-box {
  outline: var(--border-1) solid var(--color-core-neutral-4);
}
.ds-checkbox.disabled.type-box {
  outline-color: var(--color-core-neutral-2);
}
.ds-checkbox.disabled.type-box .additional-info {
  color: var(--color-core-neutral-5);
}
.ds-checkbox.disabled.type-box .checkbox-border {
  background-color: var(--color-core-neutral-2);
}
.ds-checkbox.disabled .checkbox-border {
  outline-color: var(--color-core-neutral-4);
  background-color: var(--color-core-neutral-2);
}
.ds-checkbox.disabled.checked .checkbox-border {
  background-color: var(--color-core-neutral-5);
}
.ds-checkbox .additional-info {
  color: var(--color-core-neutral-7);
}

.ds-checkbox-error {
  margin-top: var(--spacing-1);
  color: var(--color-system-critical-3);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
