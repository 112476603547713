import { ForceUpdateComponent } from './force-update/force-update.component';
import { LoginComponent } from './login/login.component';
import { Login2faComponent } from './login-2fa/login-2fa.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { OfflineComponent } from './offline/offline.component';
import { OnboadingComponent } from './onboarding/onboarding.component';

export const components = [
  ForceUpdateComponent,
  Login2faComponent,
  LoginComponent,
  MaintenanceComponent,
  OfflineComponent,
  OnboadingComponent
];

export { ForceUpdateComponent } from './force-update/force-update.component';
export { Login2faComponent } from './login-2fa/login-2fa.component';
export { LoginComponent } from './login/login.component';
export { MaintenanceComponent } from './maintenance/maintenance.component';
export { OfflineComponent } from './offline/offline.component';
export { OnboadingComponent } from './onboarding/onboarding.component';
