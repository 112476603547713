// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-start: var(--spacing-6);
  --padding-end: var(--spacing-6);
}

ion-icon {
  font-size: var(--icon-size-2);
}

.banner-header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  margin-bottom: var(--spacing-6);
}
.banner-header .ds-ui-3 {
  margin-bottom: calc(var(--spacing-1) / 2);
}

.balance-box {
  margin-bottom: var(--spacing-6);
}
.balance-box .ds-heading-2 {
  margin-bottom: var(--spacing-3);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
