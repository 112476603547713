// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  padding-top: var(--ion-safe-area-top);
}

.change-language {
  align-items: center;
  animation: 2s ease-out 0s 1 showContent;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 15px;
  top: calc(var(--ion-safe-area-top) + 15px);
  padding: 10px;
}
.change-language span {
  line-height: 1;
  font-weight: 700;
}
.change-language ion-icon {
  font-size: 18px;
  margin-left: 2px;
}

h1 {
  color: var(--ion-color-primary);
  font-family: var(--font-secondary);
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
}
h1:last-of-type {
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 40px;
}

p {
  color: var(--ion-color-primary);
  margin-bottom: 10px;
  text-align: start;
}

img {
  margin: 0 auto;
  position: absolute;
  top: calc(var(--ion-safe-area-top) + 90px);
  height: auto;
  width: 142px;
}

ion-button {
  margin: 0 0 12px 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
