import { Injectable } from '@angular/core';

import { TwoFaResources } from './2fa.resources';
import { AccountResources } from './accounts.resources';
import { DashboardResources } from './dashboard.resources';
import { DeviceResources } from './device.resources';
import { NotificationResources } from './notification.resources';
import { StrategyResources } from './strategy.resources';

@Injectable({
  providedIn: 'root'
})
export class YovaAPI {
  readonly account: AccountResources;
  readonly dashboard: DashboardResources;
  readonly device: DeviceResources;
  readonly notifications: NotificationResources;
  readonly strategy: StrategyResources;
  readonly twoFa: TwoFaResources;

  constructor(
    account: AccountResources,
    dashboard: DashboardResources,
    device: DeviceResources,
    notifications: NotificationResources,
    strategy: StrategyResources,
    twoFa: TwoFaResources
  ) {
    this.account = account;
    this.dashboard = dashboard;
    this.device = device;
    this.notifications = notifications;
    this.strategy = strategy;
    this.twoFa = twoFa;
  }
}
