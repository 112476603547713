// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  margin-bottom: var(--spacing-8);
  padding-inline: var(--spacing-4);
}

svg.performance-chart {
  display: block;
  margin-left: calc(var(--spacing-4) * -1);
  margin-right: calc(var(--spacing-4) * -1);
}

app-ds-tab-nav {
  display: block;
  margin-bottom: var(--spacing-3);
}

.perf-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.perf-chart {
  padding-top: var(--spacing-12);
  padding-bottom: var(--spacing-6);
  position: relative;
  margin-bottom: var(--spacing-3);
}
.perf-tooltip {
  background-color: var(--color-core-brand-3-2);
  border-radius: var(--radius-2);
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: var(--spacing-2) var(--spacing-3);
}
.perf-tooltip.single-row {
  top: var(--spacing-4);
}
.perf-values {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
}
.perf-value {
  display: flex;
  gap: var(--spacing-2);
}
.perf-value app-ds-legend {
  display: inline-flex;
}
.perf-date {
  margin-left: auto;
}
.perf-dot {
  background-color: var(--color-core-neutral-5);
  border-radius: 50%;
  display: inline-block;
  height: var(--spacing-2);
  width: var(--spacing-2);
}
.perf-details {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-3);
}
.perf-type {
  margin-left: auto;
}
.perf-legends {
  display: flex;
  gap: var(--spacing-4);
}
.perf-chart-header {
  margin-bottom: var(--spacing-3);
}
.perf-chart-header > div {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
}
.perf-chart-header ion-icon {
  margin-top: -2px;
}
.perf-point {
  animation: 0.7s ease-in-out 0s 1 perfDotAnim;
}

@keyframes perfDotAnim {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
