// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep ul {
  margin: var(--spacing-0);
  padding-left: var(--spacing-7);
}

.ds-callout {
  display: flex;
  background-color: var(--color-core-neutral-0);
  border: var(--border-1) solid var(--color-core-neutral-3);
  border-radius: var(--radius-4);
  margin-bottom: var(--spacing-8);
  padding: var(--spacing-5);
  gap: var(--spacing-4);
}
.ds-callout p {
  margin: 0;
}
.ds-callout-content {
  flex: 1 1 auto;
}
.ds-callout-content > p:first-child {
  margin-bottom: var(--spacing-1);
}
.ds-callout-links {
  display: flex;
  gap: var(--spacing-4);
  margin-top: var(--spacing-3);
  font-size: var(--fs-3);
  line-height: var(--flh-4);
  letter-spacing: var(--fls-4);
}
.ds-callout-links:empty {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
