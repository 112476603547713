import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Notification } from '@shared/models/Account';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationResources {
  private readonly URL_V3: string;
  private readonly URL: string;

  constructor(private http: HttpClient) {
    this.URL_V3 = `${environment.apiUrlV3}`;
    this.URL = `${environment.apiUrl}`;
  }

  fetchAll(): Observable<{ meta: any; data: Notification[] }> {
    return this.http.get<{ meta: any; data: Notification[] }>(
      `${this.URL_V3}/customer_notifications?filter[for_mobile]=true&filter[since_days_ago]=30&sort=data,-updated_at`
    );
  }

  public markAsSeen(notificationId: number) {
    return this.http.put(`${this.URL}/notifications/${notificationId}`, {});
  }
}
