// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verify-form {
  margin: 40px auto;
  position: relative;
  text-align: center;
  width: 230px;
}

.verify-code-input {
  background: transparent;
  border: 0;
  box-shadow: none;
  caret-color: transparent;
  color: transparent;
  font-size: 30px;
  height: 100%;
  left: 0;
  letter-spacing: 25px;
  outline: 0;
  position: absolute;
  top: 0;
  width: 230px;
}
.verify-code-input:focus {
  caret-color: transparent;
  outline: none;
}

.input-boxes {
  align-items: center;
  display: flex;
  min-height: 30px;
  width: 230px;
}
.input-boxes span {
  border-bottom: 1px solid var(--ion-color-primary);
  font-size: 30px;
  line-height: 1;
  margin: 0 7px;
  min-height: 18px;
  width: 20px;
}
.input-boxes span:nth-child(3) {
  margin-right: 20px;
}
.input-boxes span:nth-child(4) {
  margin-left: 20px;
}
.input-boxes span.next {
  border-bottom: 2px solid var(--ion-color-primary);
}
.input-boxes span.active {
  border: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
