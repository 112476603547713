import { ModalController } from '@ionic/angular';

import { createAction, props } from '@ngrx/store';

import {
  InyovaGrowAccount,
  InyovaGrowFund,
  InyovaGrowProject,
  InyovaGrowPercentages,
  InyovaGrowRisk,
  InyovaGrowSurveyQuestion,
  InyovaGrowSubmit,
  InyovaGrowSubmitResponse,
  InyovaGrowInterestAccountProject,
  GrowPortfolioAllocation
} from '@app/shared/models/Grow';

export const createInyovaGrowAccount = createAction('[Grow]  create inyova grow account');
export const createInyovaGrowAccountSuccess = createAction(
  '[Grow]  create inyova grow account success',
  props<{ data: InyovaGrowAccount }>()
);
export const createInyovaGrowAccountFail = createAction('[Grow]  create inyova grow account fail');

export const getInyovaGrowAccount = createAction('[Grow] Get the grow account details');
export const getInyovaGrowAccountSuccess = createAction(
  '[Grow] Get the grow account details success',
  props<{ data: InyovaGrowAccount }>()
);
export const getInyovaGrowAccountFail = createAction('[Grow] Get the grow account details fail');

export const getInyovaGrowIban = createAction('[Grow] Check Inyova grow IBAN');
export const getInyovaGrowIbanSuccess = createAction('[Grow] Check Inyova grow IBAN success', props<{ iban: string }>());
export const getInyovaGrowIbanFail = createAction('[Grow] Check Inyova grow IBAN fail');

export const getInyovaGrowProjects = createAction('[Grow] Get Inyova Grow projects');
export const getInyovaGrowProjectsSuccess = createAction(
  '[Grow] Get Inyova Grow projects success',
  props<{ fund: InyovaGrowFund; projects: InyovaGrowProject[] }>()
);
export const getInyovaGrowProjectsFail = createAction('[Grow] Get Inyova Grow projects fail');

export const getInyovaGrowPortfolioAllocationSuccess = createAction(
  '[Grow] Get Inyova Grow portfolio allocation success',
  props<{ growPortfolioAllocation: GrowPortfolioAllocation }>()
);
export const getInyovaGrowPortfolioAllocationFail = createAction('[Grow] Get Inyova Grow portfolio allocation fail');

export const selectInyovaGrowProject = createAction('[Grow] Select Inyova Grow project', props<{ projectId: string }>());
export const selectInyovaGrowProjectSuccess = createAction(
  '[Grow] Select Inyova Grow project success',
  props<{ projectId: string; interestAccountProject: InyovaGrowInterestAccountProject }>()
);
export const selectInyovaGrowProjectFail = createAction('[Grow] Select Inyova Grow project fail', props<{ error: any }>());

export const deselectInyovaGrowProject = createAction('[Grow] Deselect Inyova Grow project', props<{ projectId: string }>());
export const deselectInyovaGrowProjectSuccess = createAction('[Grow] Deselect Inyova Grow project success', props<{ projectId: string }>());
export const deselectInyovaGrowProjectFail = createAction('[Grow] Deselect Inyova Grow project fail', props<{ error: any }>());

export const selectAndInvestGrowProject = createAction(
  '[Grow] Select and Invest Inyova Grow project',
  props<{ projectId: string; modalId: string }>()
);

export const getInyovaGrowRisk = createAction('[Grow] Get Inyova Grow risk');
export const getInyovaGrowRiskSuccess = createAction(
  '[Grow] Get Inyova Grow risk success',
  props<{ data: InyovaGrowRisk; percentages: InyovaGrowPercentages }>()
);
export const getInyovaGrowRiskFail = createAction('[Grow] Get Inyova Grow risk fail');

export const checkGrowRiskProfile = createAction(
  '[Grow] Check Inyova grow risk profile',
  props<{ answers: InyovaGrowSurveyQuestion[]; availableAssets: number; initInvest: number }>()
);
export const checkGrowRiskProfileSuccess = createAction(
  '[Grow] Check Inyova grow risk profile success',
  props<{ data: InyovaGrowPercentages }>()
);
export const checkGrowRiskProfileFail = createAction('[Grow] Check Inyova grow risk profile fail');

export const updateInyovaGrowRisk = createAction('[Grow] Update Inyova Grow risk', props<{ data: InyovaGrowSubmit; nextPage: string }>());
export const updateInyovaGrowRiskSuccess = createAction(
  '[Grow] Update Inyova Grow risk success',
  props<{ data: InyovaGrowSubmitResponse; nextPage: string }>()
);
export const updateInyovaGrowRiskFail = createAction('[Grow] Update Inyova Grow risk fail');

export const finishGrowProjectInvestment = createAction('[Grow] Finish Investment', props<{ projectId: string; amount: number }>());
export const finishGrowProjectInvestmentSuccess = createAction('[Grow] Finish Investment Success', props<{ projectId: string }>());
export const finishGrowProjectInvestmentReached = createAction('[Grow] Finish Investment Reached');
export const finishGrowProjectInvestmentFail = createAction('[Grow] Finish Investment Fail');
export const finishGrowProjectInvestmentReset = createAction('[Grow] Finish Investment Reset');
