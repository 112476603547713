import { Component, Input } from '@angular/core';
import { CustomerAccountItem } from '@inyova/models';

@Component({
  selector: 'app-home-accounts-item',
  styleUrls: ['./home-accounts-item.component.scss'],
  templateUrl: './home-accounts-item.component.html'
})
export class HomeAccountsItemComponent {
  @Input() accountHomepage = null;
  @Input() accountKind: CustomerAccountItem['kind'];
  @Input() crowdinvestingTotalValue = 0;
  @Input() currencyInvestment: string;
  @Input() hasTechnicalIssues: string;
  @Input() currencyShares: string;

  getAccountKindName(account: CustomerAccountItem): string {
    if (this.accountKind === 'ci') {
      return 'ACCOUNT.switch.CI.title';
    }
    if (account.kind === 'kid') {
      return account.owner_name;
    }
    return `ACCOUNT.switch.${account.kind}.title`;
  }

  hasError(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return (this.hasTechnicalIssues || this.accountHomepage?.graphData?.hasError) as boolean;
  }
}
