import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { ModalController } from '@ionic/angular';

import { select, Store } from '@ngrx/store';
import { noop, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromAccount from '@account/account.reducers';

import { LocalStorageKeys } from '@app/app.constants';
import { TrackingService } from '@shared/services/tracking.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-pillar3a',
  styleUrls: ['./home-pillar3a.component.scss'],
  templateUrl: './home-pillar3a.component.html'
})
export class HomePillar3aComponent implements OnDestroy {
  protected readonly onDestroy$ = new Subject<void>();
  appLocation: string;

  constructor(
    private modalController: ModalController,
    private router: Router,
    private store: Store,
    private trackingService: TrackingService
  ) {
    this.store.pipe(select(fromAccount.selectCustomer), takeUntil(this.onDestroy$)).subscribe((customer) => {
      this.appLocation = customer.app_location;
    });
  }

  close(): void {
    this.trackingService.trackActivity('[Button]: Open 3a account - closed');
    this.dismiss();
  }

  dismiss(): void {
    void this.modalController.dismiss({
      dismissed: true
    });
  }

  open3aAccount(): Promise<void> {
    this.trackingService.trackActivity('[Button]: Open 3a account - cta');
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const url = `${environment.customersApp[this.appLocation]}/?customer_token=${localStorage.getItem(
      LocalStorageKeys.AUTHENTICATION_TOKEN
    )}&redirect=new-account`;

    const browser = Browser.open({ url });

    void Browser.addListener('browserFinished', () => {
      this.router.navigateByUrl('/public/login').finally(noop);
    });

    this.dismiss();
    return browser;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
