// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding-inline: var(--spacing-4);
}

.home-balance-box {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-3);
}
.home-balance-box .balance-row {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-2);
}
.home-balance-box .balance-row.total-info {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: var(--spacing-3);
}
.home-balance-box .balance-row:last-child {
  margin-bottom: 0;
}
.home-balance-box .balance-row-right {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: var(--spacing-2);
}
.home-balance-box .balance-row-right ion-icon {
  margin-top: -1px;
}
.home-balance-box .updated-info + .ds-ui-3 {
  margin-bottom: var(--spacing-3);
}
.home-balance-box .balance-row + .ds-ui-3 {
  margin-bottom: var(--spacing-2);
}
.home-balance-box .balance-row + .ds-ui-3:last-child {
  margin-bottom: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
