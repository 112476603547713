// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-start: 24px;
  --padding-end: 24px;
  text-align: center;
}
ion-content ion-label {
  display: block;
}
ion-content ion-label.title {
  font-family: var(--font-secondary);
  font-size: 28px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  font-weight: 700;
  letter-spacing: -0.005em;
}
ion-content img {
  margin: 32px 0 16px;
}
ion-content img.main {
  margin: 32px 0;
}
ion-content p {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 16px;
  text-align: left;
  line-height: 24px;
  margin-bottom: 80px;
}
ion-content .button-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 24px;
  background: linear-gradient(180deg, rgba(248, 246, 241, 0) 0%, #f8f6f1 30%);
}
ion-content button {
  width: 100%;
  margin: 0;
  padding: 12px 20px;
  background-color: var(--ion-color-primary);
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--ion-color-white);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
