// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-home-impact-campaign-support ion-content {
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-bottom: 48px;
}
app-home-impact-campaign-support img {
  display: flex;
  margin: 44px auto 22px;
}
app-home-impact-campaign-support .about {
  margin-bottom: 24px;
}
app-home-impact-campaign-support .about h1 {
  font-family: var(--font-secondary);
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  letter-spacing: -0.005em;
  margin: 0 0 20px;
}
app-home-impact-campaign-support .about .content {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
app-home-impact-campaign-support .about .content p {
  font-size: inherit;
  margin-bottom: 12px;
}
app-home-impact-campaign-support .checkbox {
  margin-bottom: 46px;
  display: flex;
  gap: 9px;
}
app-home-impact-campaign-support .checkbox ion-icon {
  min-width: 30px;
  min-height: 30px;
  margin-top: -4px;
  margin-left: -3px;
}
app-home-impact-campaign-support .close-btn {
  position: absolute;
  top: 40px;
  right: 20px;
  z-index: 2;
}
app-home-impact-campaign-support .close-btn ion-icon {
  color: var(--ion-color-primary);
  font-size: 24px;
}
app-home-impact-campaign-support .sticky-button {
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
  left: 0;
  padding: 12px;
  background: var(--ion-color-success);
}
app-home-impact-campaign-support button {
  border-radius: 4px;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-secondary);
  width: 100%;
  padding: 12px;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}
app-home-impact-campaign-support button.loading {
  opacity: 0.8;
  padding-left: 32px;
}
app-home-impact-campaign-support button.loading .loading-icon {
  display: block;
}
app-home-impact-campaign-support button .loading-icon {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  padding: 12px 8px;
  font-size: 20px;
  animation: spin 1200ms infinite linear;
}
app-home-impact-campaign-support button:disabled {
  opacity: 40%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.support-desc.hide-unsupport span {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
