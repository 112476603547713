// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds-progress-bar {
  width: 100%;
  height: 4px;
  background: var(--color-core-neutral-2);
  border-radius: var(--radius-1);
  margin: var(--spacing-1) 0 var(--spacing-2);
}
.ds-progress-bar .line {
  min-width: 16px;
  max-width: 100%;
  border-radius: inherit;
  height: inherit;
  border-radius: inherit;
  transition: 300ms ease-in-out;
}
.ds-progress-bar .line.primary {
  background: var(--color-core-brand-1);
}
.ds-progress-bar .line.secondary {
  background: var(--color-core-brand-2-3);
}
.ds-progress-bar .line.neutral {
  background: var(--color-core-neutral-3);
}
.ds-progress-bar .line.info {
  background: var(--color-system-info-2);
}
.ds-progress-bar .line.warning {
  background: var(--color-system-warning-2);
}
.ds-progress-bar .line.critical {
  background: var(--color-system-critical-2);
}
.ds-progress-bar .line.success {
  background: var(--color-system-success-2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
