// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-top: calc(var(--ion-safe-area-top) + var(--spacing-4));
  --color: var(--ion-color-primary);
  color: var(--ion-color-primary);
}
ion-content h1 {
  margin-bottom: 40px;
}

app-track-deposit,
app-ds-tab-nav,
app-ds-content-switcher,
.content-wrapper {
  display: block;
  margin-bottom: var(--spacing-8);
  padding-inline: var(--spacing-4);
}

ion-icon[name=info-filled] {
  color: var(--ion-color-yellow);
  font-size: 14px;
}

.performance-header__indicators {
  text-align: center;
}

.performance-header__title {
  font-size: 35px;
  font-weight: 700;
  font-family: var(--font-secondary);
}
.performance-header__title span {
  font-size: 25px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.performance-header__title--with-button {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.performance-header__description {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  color: var(--ion-color-tertiary);
}
.performance-header__description--tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
}
.performance-header__description--tooltip ion-icon {
  margin-left: 4px;
}

.performance-header__value {
  font-size: 16px;
  font-weight: 700;
}
.performance-header__value span {
  font-size: 14px;
}

.performance-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  padding-top: 20px;
}
.performance-filter div {
  flex: 1;
  text-align: center;
  border-bottom: 4px solid var(--ion-color-secondary);
  font-size: 12px;
  line-height: 32px;
  transition: border-color 0.3s linear;
  text-transform: uppercase;
}
.performance-filter div.active {
  border-color: var(--ion-color-primary);
  font-weight: 700;
  pointer-events: none;
}

.performance-graph__title-holder {
  padding: 0 15px;
}
.performance-graph__title-holder h3 {
  font-size: 20px;
  line-height: 28px;
  font-family: var(--font-secondary);
  margin: 0;
  font-weight: 700;
}
.performance-graph__title-holder p {
  display: flex;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  font-weight: 500;
}
.performance-graph__title-holder p ion-icon {
  margin-left: 5px;
}

.performance-graph__wrapper {
  position: relative;
  min-height: 310px;
  width: 100%;
}
.performance-graph__wrapper--loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.performance-graph__wrapper .performance-filter__empty {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  color: var(--ion-color-tertiary);
  width: 100%;
  text-align: center;
}

.performance-graph__legend {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 44px;
}

.performance-graph__legend-item {
  position: relative;
  font-size: 12px;
  color: #706b62;
  padding: 16px 20px 16px 34px;
  line-height: 12px;
}
.performance-graph__legend-item::before {
  position: absolute;
  top: 50%;
  left: 15px;
  content: "";
  border-radius: 50%;
  width: 4px;
  height: 4px;
  border: 2px solid #706b62;
  transform: translateY(-50%);
  background-color: #706b62;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.182392);
}
.performance-graph__legend-item--withdrawal::before {
  background-color: transparent;
}
.performance-graph__legend-item--inactive {
  color: #dddddb;
}
.performance-graph__legend-item--inactive::before {
  background-color: #dddddb;
  border-color: #dddddb;
}

.performance-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  margin-top: 20px;
}

.performance-graph__switch {
  margin: 5px 15px 12px 15px;
}

ion-segment {
  --background: var(--ion-color-secondary);
}

ion-segment-button {
  --indicator-color: var(--ion-color-white);
}

.performance-title {
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}

.performance-subtitle {
  font-size: 14px;
  margin-bottom: 20px;
}

.performance-notice {
  padding: 0 16px;
}
.performance-notice > p {
  color: var(--ion-color-new-grey);
  font-size: 10px;
  line-height: 12px;
}

.performance-data {
  margin-bottom: var(--spacing-8);
}
.performance-data .ds-heading-2 {
  margin-bottom: var(--spacing-3);
}

.performance-companies {
  margin-bottom: var(--spacing-8);
}
.performance-companies-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-3);
}
.performance-companies-header ion-icon {
  margin-top: calc(var(--spacing-1) / 2);
  font-size: var(--fs-4);
  min-width: var(--fs-4);
}
.performance-companies .ds-ui-3 {
  margin-bottom: var(--spacing-3);
}

.crowdinvesting-overview {
  margin: 0 16px 40px;
}

.main-title {
  font-family: var(--font-secondary);
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: -0.005em;
  text-align: center;
  margin: 32px;
}

.shares-overview {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 24px 16px;
  margin: 32px 0;
  color: var(--ion-color-primary);
  background: var(--ion-color-white);
  background-image: url("/assets/icon/crowdinvesting-background.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
}
.shares-overview .label {
  color: var(--ion-color-new-grey);
  font-family: var(--font-primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.shares-overview .main-number {
  font-family: var(--font-secondary);
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -0.01em;
  text-align: center;
}
.shares-overview .other-number {
  font-family: var(--font-primary);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.performance-wrapper__description {
  color: var(--ion-color-tertiary);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.performance-wrapper__description p {
  max-width: 85%;
  margin: 0 auto;
}
.performance-wrapper__description::first-letter {
  text-transform: uppercase;
}
.performance-wrapper__description:focus {
  outline: 0;
}

.performance-wrapper__title {
  font-family: var(--font-secondary);
  font-size: 35px;
  font-weight: 700;
  text-align: center;
}
.performance-wrapper__title span {
  font-size: 25px;
}
.performance-wrapper__title.insights-header__title--space {
  margin-bottom: 35px;
}
@media only screen and (max-width: 360px) {
  .performance-wrapper__title.insights-header__title--space {
    margin-bottom: 20px;
  }
}

.performance-wrapper--error {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: calc(100% - 130px);
  background-image: url("/assets/images/leafs.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: auto calc(100% - 172px);
}
.performance-wrapper--error .performance-wrapper__description {
  text-shadow: 1px 1px 7px #f8f6f1;
  color: var(--ion-color-primary);
  max-width: 90%;
  margin: 25px auto;
}

.button-fab {
  margin-left: 5px;
  align-self: center;
}
.button-fab button {
  padding: 0;
  width: 24px;
  height: 24px;
}
.button-fab ion-icon {
  font-size: 24px;
  font-weight: bold;
}

.balance-sheet-button {
  border-radius: var(--radius-2);
  outline: var(--border-1) solid var(--color-core-neutral-2);
  background: var(--color-core-neutral-0);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-8);
  gap: var(--spacing-1);
  padding: var(--spacing-2);
  margin-inline: var(--spacing-4);
}
.balance-sheet-button ion-icon {
  font-size: var(--icon-size-1);
}

.mini-alert {
  margin-inline: var(--spacing-4);
}

.mini-warning {
  padding-top: var(--spacing-8);
  margin-inline: var(--spacing-4);
  text-align: center;
}
.mini-warning > h3 {
  margin-top: var(--spacing-3);
  margin-bottom: var(--spacing-3);
}
.mini-warning > p {
  margin-bottom: var(--spacing-8);
}

.updated-info-holder {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-4);
  padding-inline: var(--spacing-4);
}
.updated-info-holder .updated-info {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: var(--spacing-2);
}
.updated-info-holder .updated-info.active {
  margin-bottom: var(--spacing-2);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
