// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stock-performance-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--shadow-elavated);
  background: var(--color-core-neutral-0);
  margin-bottom: calc(var(--spacing-1) / 2);
  padding: var(--spacing-4);
}
.stock-performance-box.first {
  border-top-left-radius: var(--radius-2);
  border-top-right-radius: var(--radius-2);
}
.stock-performance-box.last {
  border-bottom-left-radius: var(--radius-2);
  border-bottom-right-radius: var(--radius-2);
}
.stock-performance-box-detail {
  display: inherit;
  flex-direction: column;
  margin-right: var(--spacing-1);
}
.stock-performance-box-detail .ds-ui-2 {
  margin-bottom: var(--spacing-1);
}
.stock-performance-box-detail > div {
  display: inherit;
  align-items: center;
  flex-direction: row;
  gap: var(--spacing-2);
}
.stock-performance-box-left {
  display: flex;
  align-items: center;
}
.stock-performance-box-right {
  min-width: 60px;
  text-align: right;
}
.stock-performance-box ion-icon {
  display: flex;
}
.stock-performance-box .logo {
  display: flex;
  margin-right: var(--spacing-4);
  width: 32px;
  max-height: 32px;
  align-items: center;
  justify-content: center;
}
.stock-performance-box .logo img {
  max-height: inherit;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
