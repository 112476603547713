// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-stacked-area-chart {
  display: block;
  position: relative;
}
app-stacked-area-chart svg:not(:root) {
  overflow: visible;
}
app-stacked-area-chart path.stacked-line {
  stroke: var(--ion-color-chart-expected);
}
app-stacked-area-chart path.stacked-line:first-of-type {
  stroke: var(--ion-color-chart-bad);
}
app-stacked-area-chart path.stacked-line:last-of-type {
  stroke: var(--ion-color-chart-good);
}

.stacked-area-chart__legend {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 40px;
  text-align: center;
}

.stacked-area-chart-tooltip {
  width: 144px;
  height: auto;
  background-color: var(--ion-color-white);
  box-shadow: 0px 2px 4px rgba(47, 44, 38, 0.04), 0px 2px 2px rgba(47, 44, 38, 0.08);
  padding: 8px;
  position: absolute;
  opacity: 0;
  top: -90px;
  left: -72px;
}
.stacked-area-chart-tooltip p {
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  margin-bottom: 4px;
}
.stacked-area-chart-tooltip p:last-child {
  margin-bottom: 0;
}

.stacked-area-chart__legend-item {
  font-size: 12px;
  line-height: 1;
  margin: 0 12px;
  padding: 2px 0;
  position: relative;
  cursor: pointer;
  border-bottom: 4px solid;
  border-color: var(--ion-color-chart-bad);
}
.stacked-area-chart__legend-item:first-of-type {
  display: none;
}
.stacked-area-chart__legend-item:nth-child(3) {
  border-color: var(--ion-color-chart-expected);
}
.stacked-area-chart__legend-item:nth-child(4) {
  border-color: var(--ion-color-chart-good);
}
.stacked-area-chart__legend-item.inactive {
  opacity: 0.3;
  border-bottom: 2px solid;
}
@media only screen and (min-width: 480px) {
  .stacked-area-chart__legend-item {
    margin: 0 20px;
  }
}

.grid line {
  stroke: lightgrey;
  stroke-dasharray: 5, 10;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.grid path {
  stroke-width: 0;
}

.xaxis-stacked {
  color: #706B62;
}
.xaxis-stacked .tick line {
  opacity: 0;
}
.xaxis-stacked path {
  opacity: 0;
}

.yaxis-stacked .tick line {
  opacity: 0;
}
.yaxis-stacked .tick:first-of-type {
  opacity: 0;
}

.yaxis-stacked {
  text-anchor: start;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
