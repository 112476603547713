// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-home-strategy .ds-heading-2 {
  margin-bottom: var(--spacing-3);
}
.app-home-strategy .strategy-header {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-3);
}
.app-home-strategy .strategy-header > div {
  display: flex;
  align-items: center;
}
.app-home-strategy .strategy-header > div h2 {
  margin: 0;
}
.app-home-strategy .strategy-header > div ion-icon {
  margin-left: auto;
}
.app-home-strategy .strategy-header .ds-ui-3 {
  margin-top: var(--spacing-2);
}
.app-home-strategy .allocation {
  margin-bottom: var(--spacing-8);
}
.app-home-strategy .allocation .legend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--flh-3);
  margin-bottom: var(--spacing-3);
}
.app-home-strategy .allocation .legend .ds-caption-2 {
  color: var(--color-core-neutral-7);
}
.app-home-strategy .allocation .bar + .bar {
  border-left: var(--border-1) solid var(--color-core-brand-3-1);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
