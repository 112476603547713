/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import * as AccountActions from '@account/account.actions';
import * as PublicActions from '@public/public.actions';

import { LocalStorageKeys } from '@app/app.constants';
import { AuthService } from '@shared/services/auth.service';
import { ToastService } from '@shared/services/toast.service';
import { TokenService } from '@shared/services/token.service';
import { TrackingService } from '@shared/services/tracking.service';
import { YovaAPI } from '@shared/yova-api';

@Injectable()
export class PublicEffects {
  customerSignIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PublicActions.customerSignIn),
      mergeMap((data) =>
        this.tokenService.signIn(data).pipe(
          tap((res) => {
            void this.loadingController.dismiss();
            // Customer with 2FA enabled
            if (res && res.authentication_token) {
              localStorage.setItem(LocalStorageKeys.PHONE_NUMBER, res.phone);
              localStorage.setItem(LocalStorageKeys.AUTHENTICATION_TOKEN, res.authentication_token);
              void this.router.navigate(['/public/login/2fa']);
            } else {
              this.authService.login(res.data);
              this.trackingService.trackActivity('Logged in');
              this.trackingService.trackAppVersion();
              localStorage.setItem(LocalStorageKeys.AUTHENTICATION_TOKEN, res.data.authentication_token);
            }
          }),
          mergeMap((res) => {
            if (res && res.authentication_token) {
              return [];
            }
            return [AccountActions.setCustomer({ data: res.data }), AccountActions.getCustomer({ doLogin: false })];
          }),
          catchError((err) => {
            void this.loadingController.dismiss();
            const errorObject = err?.error || '';
            if ('errors' in errorObject && errorObject.errors[0].includes('locked')) {
              void this.toastService.error(this.translateService.instant('SHARED.errors.lockedAccount') as string, 6000);
            } else if ('code' in errorObject && errorObject.code.includes('vp_account')) {
              void this.toastService.errorWithClose(this.translateService.instant('SHARED.errors.vpAccount') as string, 0);
            } else if ('code' in errorObject && errorObject.code.includes('pro_account')) {
              void this.toastService.errorWithClose(this.translateService.instant('SHARED.errors.inyovaPro') as string, 0);
            } else {
              void this.toastService.error(this.translateService.instant('SHARED.errors.usedEmail') as string);
            }
            return EMPTY;
          })
        )
      )
    )
  );

  resetPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PublicActions.resetPassword),
        mergeMap((data) =>
          this.tokenService.resetPassword(data).pipe(
            tap(() => this.toastService.show(this.translateService.instant('SHARED.success.sentEmail'))),
            // In both cases, success & unsuccess show success message as it is in customers app
            catchError(() => this.toastService.show(this.translateService.instant('SHARED.success.sentEmail')))
          )
        )
      ),
    { dispatch: false }
  );

  simulateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PublicActions.simulateCustomer),
      mergeMap(({ token }) =>
        this.yovaAPI.account.simulateCustomer(token).pipe(
          tap((res) => {
            if (res !== null && res.status === 'success') {
              localStorage.setItem(LocalStorageKeys.AUTHENTICATION_TOKEN, res.data.authentication_token);
              this.authService.login(res.data);
            } else {
              this.toastService.error(this.translateService.instant('SHARED.errors.something'));
            }
          }),
          mergeMap((res) => {
            const actions = [];
            if (res != null && res.status === 'success') {
              actions.push(AccountActions.setCustomer({ data: res.data }));
              actions.push(AccountActions.getCustomer({ doLogin: false }));
            }
            return actions;
          }),
          catchError(() => {
            void this.toastService.error(this.translateService.instant('SHARED.errors.something'));
            return EMPTY;
          })
        )
      )
    )
  );

  verify2faCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PublicActions.verify2faCode),
      mergeMap(({ authToken, code }) =>
        this.yovaAPI.twoFa.verify2faCode(authToken, code).pipe(
          tap((res) => {
            void this.loadingController.dismiss();
            this.trackingService.trackActivity('Logged in (2fa)');
            this.trackingService.trackAppVersion();
            this.authService.login(res.data);
            localStorage.removeItem(LocalStorageKeys.PHONE_NUMBER);
            localStorage.removeItem(LocalStorageKeys.AUTHENTICATION_TOKEN);
          }),
          mergeMap((res) => [AccountActions.setCustomer({ data: res.data }), AccountActions.getCustomer({ doLogin: false })]),
          catchError((err) => {
            void this.loadingController.dismiss();
            void this.toastService.error(this.translateService.instant('ACCOUNT.2fa.code.error'));
            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private yovaAPI: YovaAPI,
    private router: Router,
    private toastService: ToastService,
    private tokenService: TokenService,
    private loadingController: LoadingController,
    private translateService: TranslateService,
    private trackingService: TrackingService
  ) {}
}
