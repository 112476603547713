// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header {
  background-color: var(--ion-color-light);
}

.impact-item__header {
  padding: 0 15px;
  text-align: left;
  min-height: 50px;
  margin-bottom: 15px;
}
.impact-item__header h1 {
  font-family: var(--font-secondary);
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
}
.impact-item__header p {
  color: var(--ion-color-tertiary);
  font-size: 12px;
  font-weight: 500;
}

.impact-item__label {
  color: var(--ion-color-success);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.24em;
  margin: 12px 0;
  text-align: center;
  text-transform: uppercase;
}
.impact-item__label img {
  margin-left: 12px;
  vertical-align: middle;
}

.impact-item__image {
  background-color: var(--ion-color-secondary);
  background-position: center;
  background-size: cover;
  height: 175px;
  position: relative;
  width: 100%;
}

.impact-item__image-logo {
  align-items: center;
  background-color: var(--ion-color-white);
  display: flex;
  height: 65px;
  justify-content: center;
  left: 15px;
  padding: 5px;
  position: absolute;
  top: 15px;
  width: 65px;
}

.impact-item__content {
  padding: 0 15px;
  padding-bottom: 20px;
  margin: 15px 0;
}
.impact-item__content p {
  margin-bottom: 10px;
}

.impact-item__footer {
  background: #fff;
  border-radius: 20px;
  bottom: 20px;
  box-shadow: 0 4px 5px 0 rgba(47, 44, 38, 0.05);
  color: var(--ion-color-primary);
  display: flex;
  left: 50%;
  padding: 10px 20px;
  position: absolute;
  transform: translate(-50%);
}
.impact-item__footer span {
  line-height: 22px;
}
.impact-item__footer ion-icon {
  color: var(--ion-color-primary);
  margin-left: 10px;
  font-size: 18px;
}
.impact-item__footer--cta {
  padding: 0 15px;
  border-radius: 0;
  background: transparent;
  width: 100%;
  box-shadow: none;
}
.impact-item__footer--cta ion-button {
  width: 100%;
}

.extra-margin {
  display: block;
  margin-bottom: var(--spacing-23);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
