// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login__background {
  background-color: var(--ion-color-secondary);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.login__background::after {
  content: "";
  background-color: var(--ion-color-secondary);
  background-image: url('hero-mountains.4adb9463ba4fd161.png');
  background-position: center;
  background-size: cover;
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

ion-content {
  --background: transparent;
  position: relative;
  text-align: center;
}

ion-item ion-icon {
  transform: translate(-2px, 25px);
  font-size: 20px;
}

.change-language {
  align-items: center;
  animation: 2s ease-out 0s 1 showContent;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 15px;
  top: calc(var(--ion-safe-area-top) + 15px);
  padding: 10px;
}
.change-language span {
  line-height: 1;
  font-weight: 700;
}
.change-language ion-icon {
  font-size: 18px;
  margin-left: 2px;
}

.ion-input__errors--forgot-password {
  max-width: 60%;
}

ion-button {
  margin-bottom: 10px;
}

.container--center {
  padding: 0 30px;
  min-height: 330px;
  opacity: 1;
  animation: 2s ease-out 0s 1 showContent;
}

a {
  cursor: pointer;
}

.login__logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-35vh);
  animation: 1s ease-in-out 0s 1 slideUp;
}
.login__logo .app-version {
  display: none;
}
.login__logo.staging .app-version {
  display: block;
  bottom: -17px;
  color: var(--ion-color-primary);
  font-size: 13px;
  font-style: italic;
  left: 0;
  margin-right: 5px;
  position: absolute;
  right: 0;
}
.login__logo img {
  width: 142px;
  height: auto;
  animation: 1s ease-in-out 0s 1 shrinkDown;
}

.login__forgot-password {
  text-align: right;
  margin-bottom: 40px;
  margin-top: -12px;
}
.login__forgot-password a {
  font-size: 13px;
  text-decoration: none;
}

.login__logged-user h1 {
  font-family: var(--font-secondary);
  font-size: 35px;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 0;
  text-align: left;
}
.login__logged-user h1.welcome {
  margin: 0 -8px 8px;
}
@media only screen and (max-width: 340px) {
  .login__logged-user h1 {
    font-size: 28px;
    margin-top: 20px;
  }
}
.login__logged-user .email {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 1;
  text-align: left;
}
.login__logged-user .login__forgot-password {
  margin-bottom: 30px;
}

.login__logged-user-password {
  animation: 0.5s ease-in-out 0s 1 showContent;
}

.login__signup {
  position: absolute;
  bottom: 45px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  padding: 0 30px;
  animation: 2s ease-out 0s 1 showContent;
}
.login__signup p {
  margin: 0;
  font-size: 16px;
  padding: 4px 0;
  animation: 0.5s ease-out 0s 1 showContent;
}
.login__signup .login__signup-actions {
  animation: 0.5s ease-out 0s 1 showContent;
}

.login__google {
  margin-top: 70px;
}

.login__biometric {
  font-size: 16px;
  font-weight: 700;
  padding: 10px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

@media only screen and (max-height: 550px) {
  .login__signup {
    display: none;
  }
  .login__logo {
    opacity: 0;
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(-35vh);
  }
}
@keyframes shrinkDown {
  0% {
    width: 170px;
  }
  100% {
    width: 142px;
  }
}
@keyframes showContent {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blurBackground {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
