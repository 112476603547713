// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-start: 30px;
  --padding-end: 30px;
  --padding-bottom: 60px;
  color: var(--ion-color-primary);
}

ion-label {
  display: block;
}
ion-label.title {
  font-family: var(--font-secondary);
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  text-align: center;
}
ion-label.subtitle {
  font-family: var(--font-secondary);
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 7px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
}

p {
  margin-bottom: 1.2em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
