import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CustomerAccount } from '@inyova/models';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngrx/store';
import * as AccountActions from '@account/account.actions';

import { StatusBarStylingService } from '@app/shared/services/status-bar-styling.service';
import { GeneralConfirmModalComponent } from '@shared/modals';
import { SavingsPlanSocialProof } from '@shared/models/Account';
import { EstimatedValues, StackedAreaChartData, StackedAreaChartDataItem } from '@shared/models/Shared';
import { InvestmentData } from '@shared/models/Strategy';
import { ToastService } from '@shared/services/toast.service';
import { TrackingService } from '@shared/services/tracking.service';

import { getAggregatedValues, getExpectedValues } from './asset-chart.utils';

@Component({
  selector: 'app-asset-chart',
  templateUrl: 'asset-chart.component.html',
  styleUrls: ['asset-chart.component.scss']
})
export class AssetChartComponent implements OnChanges {
  @Input() appLocation: string;
  @Input() customerInvestment: InvestmentData;
  @Input() currentAccount: CustomerAccount;
  @Input() language: string;
  @Input() isStrategy: boolean;
  @Input() riskLevel: string;
  @Input() savingsPlanSocialProof: SavingsPlanSocialProof;
  chartData: StackedAreaChartData;
  estimatedPeriod = 20;
  estimatedValues: EstimatedValues;
  expectedPercentages: { loss: number; expected: number; good: number };

  newMonthlyValue: number;
  years = [1, 5, 10, 15, 20];
  selectedChip = 0;

  constructor(
    private modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    private store: Store,
    private toastService: ToastService,
    private trackingService: TrackingService,
    private translateService: TranslateService,
    private statusBarStyling: StatusBarStylingService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.customerInvestment) {
      this.newMonthlyValue = this.customerInvestment.monthly_investment;
    }

    this.changeEstimate(this.estimatedPeriod, true);
  }

  changeEstimate(estimatedPeriod: number, changes = false) {
    this.estimatedPeriod = estimatedPeriod;
    this.chartData = { columns: [], data: [] }; // clear data

    const expectedValues = getExpectedValues(this.appLocation, this.customerInvestment.stock_split);
    this.expectedPercentages = {
      loss: expectedValues.expectedLossRatio,
      expected: expectedValues.expectedReturnRatio,
      good: expectedValues.expectedPotentialRatio
    };
    const { expectedReturnRatioFinal, expectedSTDDevRatioFinal, expectedMonthlyReturn, confidenceInterval } = expectedValues;

    const currentYear = new Date().getFullYear();
    const total = this.customerInvestment.current_amount;
    const monthly = this.newMonthlyValue;

    let NAV = total;
    let NAVWithout = total;
    const firstYearData = { date: new Date(`01/01/${currentYear.toFixed()}`) } as StackedAreaChartDataItem;
    firstYearData['badWithout'] = total; // We initialize just the lowest line (stacked chart)
    firstYearData['expectedWithout'] = 0;
    firstYearData['goodWithout'] = 0;
    firstYearData['bad'] = 0;
    firstYearData['expected'] = 0;
    firstYearData['good'] = 0;
    this.chartData.data.push(firstYearData);

    for (let i = 1; i <= estimatedPeriod * 12; i++) {
      const aggregatedValues = getAggregatedValues(
        i,
        expectedReturnRatioFinal,
        expectedSTDDevRatioFinal,
        confidenceInterval,
        expectedMonthlyReturn,
        NAV,
        NAVWithout,
        monthly
      );

      const { WCNav, WCNavWithout, BCNav, BCNavWithout } = aggregatedValues;
      NAV = aggregatedValues.NAV;
      NAVWithout = aggregatedValues.NAVWithout;

      if (i % 12 === 0) {
        const newYearData = { date: new Date(`01/01/${(currentYear + i / 12).toFixed()}`) } as StackedAreaChartDataItem;
        // We add just differance between the last and new line (stacked chart)
        newYearData['badWithout'] = parseFloat(WCNavWithout.toFixed(0));
        newYearData['expectedWithout'] = parseFloat(NAVWithout.toFixed(0)) - parseFloat(WCNavWithout.toFixed(0));
        newYearData['goodWithout'] = parseFloat(BCNavWithout.toFixed(0)) - parseFloat(NAVWithout.toFixed(0));
        newYearData['bad'] = parseFloat(WCNav.toFixed(0)) - parseFloat(BCNavWithout.toFixed(0));
        newYearData['expected'] = parseFloat(NAV.toFixed(0)) - parseFloat(WCNav.toFixed(0));
        newYearData['good'] = parseFloat(BCNav.toFixed(0)) - parseFloat(NAV.toFixed(0));
        this.chartData.data.push(newYearData);
      }

      this.estimatedValues = {
        badWithout: WCNavWithout,
        expectedWithout: NAVWithout,
        goodWithout: BCNavWithout,
        bad: WCNav,
        expected: NAV,
        good: BCNav
      };
    }
    this.chartData.columns = ['date', 'badWithout', 'expectedWithout', 'goodWithout', 'bad', 'expected', 'good'];
    if (!changes) {
      this.trackingService.trackActivity(`[Select] Estimated value for ${estimatedPeriod} years`);
    }
  }

  track(event: string) {
    this.trackingService.trackActivity(event);
  }

  onChangeValue(value: number | null) {
    this.newMonthlyValue = value;
    this.trackingService.trackActivity(`[Button] SP - input: ${this.newMonthlyValue}`);
    this.changeEstimate(this.estimatedPeriod, true);
  }

  onSetDeposit(value: number) {
    this.newMonthlyValue = value;
    if (this.isMin()) {
      this.trackingService.trackActivity(`[Button] SP deposit: Update deposit ${this.newMonthlyValue}`);
      this.store.dispatch(AccountActions.updateSp({ data: value, id: this.currentAccount.id, isWisher: this.currentAccount.is_wisher }));
    } else {
      this.trackingService.trackActivity(`[Button] SP deposit: Error low investment: ${this.newMonthlyValue}`);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      void this.toastService.error(this.translateService.instant('SHARED.deposit.lowError'));
    }
  }

  isMin() {
    const minimumInitial = this.appLocation !== 'ch' ? 100 : 2000;
    const minimumMonthly = this.appLocation !== 'ch' ? 100 : 500;

    return this.customerInvestment.current_amount >= minimumInitial || this.newMonthlyValue >= minimumMonthly;
  }

  isGerman() {
    return this.language.includes('de-');
  }

  getTotalDeposit() {
    if (this.selectedChip === 1) {
      return this.customerInvestment.total.toFixed(0);
    }

    const reinvested = this.customerInvestment?.monthly_investment * 12 * this.estimatedPeriod;
    return (this.customerInvestment?.total + reinvested).toFixed(0);
  }

  async openLegalInfo() {
    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: GeneralConfirmModalComponent,
      componentProps: {
        title: this.translateService.instant(this.isStrategy ? 'sp.revamp.assetGraph.strategy.title' : 'sp.revamp.assetGraph.savings'),
        body: this.translateService.instant('sp.revamp.infoTooltip.legal')
      },
      cssClass: 'app-fullscreen',
      presentingElement: this.routerOutlet.nativeEl
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('base');
    });
    return modal.present();
  }
}
