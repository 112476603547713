// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-block;
}
:host.variant-default {
  color: var(--color-core-neutral-10);
}
:host.variant-neutral {
  color: var(--color-core-neutral-7);
}
:host.variant-critical {
  color: var(--color-system-critical-2);
}
:host a {
  color: inherit;
  line-height: 1;
}
:host.with-icon a {
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
  text-decoration: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
