// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.radio-input:focus-visible:not(:disabled) + .type-box {
  box-shadow: var(--shadow-input-border);
  outline-offset: var(--border-1);
  outline: var(--border-3) solid var(--color-core-brand-2-4);
  background: var(--color-core-brand-3-1);
}
.radio-input:focus-visible:not(:disabled) + .type-box.checked {
  box-shadow: var(--shadow-input-border-selected);
}
.radio-input:focus-visible:not(:disabled) + .ds-radio:not(.type-box) .ds-radio-circle {
  outline: var(--border-3) solid var(--color-core-brand-2-4);
}

.ds-radio-holder {
  cursor: pointer;
}

.ds-radio {
  display: flex;
  gap: var(--spacing-2);
  padding: var(--spacing-2) 0;
}
.ds-radio.disabled {
  color: var(--color-core-neutral-5);
}
.ds-radio.type-box {
  padding: var(--spacing-4);
  border-radius: var(--radius-2);
  outline: var(--border-1) solid var(--color-core-neutral-4);
  margin-bottom: var(--spacing-3);
}
.ds-radio.type-box.checked {
  outline: var(--border-1) solid var(--color-core-neutral-10);
}
.ds-radio.has-error:not(.checked) {
  outline-color: var(--color-system-critical-3);
}
.ds-radio.has-error:not(.checked):not(.type-box) .ds-radio-circle {
  border-color: var(--color-system-critical-3);
}
.ds-radio label {
  flex: 0 0 calc(100% - var(--spacing-5));
  max-width: calc(100% - var(--spacing-5));
}

.ds-radio-circle {
  background-color: var(--color-core-neutral-0);
  border-radius: 50%;
  border: var(--border-1) solid var(--color-core-neutral-4);
  flex: 0 0 var(--spacing-5);
  max-width: var(--spacing-5);
  width: 20px;
  height: 20px;
}
.ds-radio-circle.checked {
  position: relative;
  border: var(--border-1) solid var(--color-core-neutral-10);
  background-color: var(--color-core-neutral-10);
}
.ds-radio-circle.checked:after {
  content: "";
  width: 40%;
  height: 40%;
  position: absolute;
  background-color: var(--color-core-neutral-0);
  top: 50%;
  left: 50%;
  border-radius: inherit;
  transform: translate(-50%, -50%);
}
.ds-radio-circle.disabled {
  border-color: var(--color-core-neutral-4);
  background-color: var(--color-core-neutral-2);
}
.ds-radio-circle.disabled.checked {
  background-color: var(--color-core-neutral-5);
}

.ds-radio-error {
  margin-top: var(--spacing-1);
  color: var(--color-system-critical-3);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
