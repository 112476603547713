import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

import { Store } from '@ngrx/store';
import * as PublicActions from '@public/public.actions';

import { IonicStorageKeys } from '@app/app.constants';
import { State } from '@shared/models/State';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;

  constructor(private fb: FormBuilder, private storage: Storage, private modalController: ModalController, private store: Store<State>) {
    this.resetPasswordForm = this.fb.group({
      login: ['', Validators.required]
    });
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  ngOnInit() {
    this.storage.get(IonicStorageKeys.EMAIL).then((value) => {
      if (value && value.length) {
        this.resetPasswordForm.get('login').setValue(value);
      }
    });
  }

  resetPassword() {
    if (this.resetPasswordForm.invalid) {
      return this.resetPasswordForm.markAllAsTouched();
    }
    this.store.dispatch(PublicActions.resetPassword(this.resetPasswordForm.getRawValue()));
    this.dismiss();
  }
}
