/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';

import { StackedAreaChartConfig, StackedAreaChartData, StackedAreaChartDataItem } from '@shared/models/Shared';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-stacked-area-chart',
  styleUrls: ['./stacked-area-chart.component.scss'],
  templateUrl: './stacked-area-chart.component.html'
})
export class StackedAreaChartComponent implements OnChanges {
  @Input() data: StackedAreaChartData;
  @Input() estimatedPeriod: number;
  @Input() investedByYear: number[];
  @Input() config: StackedAreaChartConfig = {
    height: 185,
    width: window.innerWidth - 52,
    margin: {
      top: 20,
      bottom: 20,
      left: 0,
      right: 0
    }
  };

  @Input() translations: {
    badCase: string;
    expectedCase: string;
    goodCase: string;
    year: string;
    investment: string;
    expectedValue: string;
  };

  selectedIndex = null;
  tooltipData = {
    invested: 0,
    value: 0,
    year: 0
  };

  // D3
  private svg: any;
  private area: any;
  private areaSelected: any;

  constructor(
    private container: ElementRef,
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      if (changes.data.firstChange) {
        this.drawChart(changes.data.currentValue as StackedAreaChartData, false);
      } else {
        this.drawChart(changes.data.currentValue as StackedAreaChartData, true);
      }
    }
  }

  drawChart(data: StackedAreaChartData, update: boolean) {
    // Size
    const height = this.config.height - this.config.margin.top - this.config.margin.bottom;
    const { width } = this.config;
    // Format data
    const series = d3.stack().keys(data.columns.slice(1))(data.data as any);
    // Select main SVG
    this.svg = d3.select(this.container.nativeElement).select('svg').attr('width', width).attr('height', height);

    // Clear graph on update
    if (update) {
      this.svg.select('.grid').remove();
      this.svg.select('.stacked-area-paths').remove();
      this.svg.select('.stacked-area-lines').remove();
      this.svg.select('.stacked-area-dot').remove();
      this.svg.select('.xaxis-stacked').remove();
      this.svg.select('.yaxis-stacked').remove();
      this.svg.selectAll('path').remove();
      this.selectedIndex = null;
    }

    // X
    const x = d3
      .scaleUtc()
      .domain(d3.extent(data.data, (d: StackedAreaChartDataItem) => d.date))
      .range([this.config.margin.left, width - this.config.margin.right]);

    // Y
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(series, (d) => d3.max(d, (d) => d[1]))])
      .nice()
      .range([height - this.config.margin.bottom, this.config.margin.top]);

    // Create line
    this.area = d3
      .area()
      .x((d: any) => x(d.data.date))
      .y0((d) => y(d[0]))
      .y1((d) => y(d[1]));

    // Create line selected
    this.areaSelected = d3
      .area()
      .x((d: any) => x(d.data.date))
      .y0(() => y(0))
      .y1((d) => y(d[1]));

    // Create line
    const line = d3
      .line()
      .x((d: any) => x(d.data.date))
      .y((d: any) => y(d[1]));

    // Grid
    const grid = (g) =>
      g.attr('class', 'grid').call(
        d3
          .axisLeft(y)
          .ticks(height / 45)
          .tickSize(-width)
          .tickFormat(() => ' ')
      );

    // Y axis
    const yAxis = (g) =>
      g
        .attr('transform', `translate(15,-8)`)
        .call(
          d3
            .axisLeft(y)
            .ticks(height / 45)
            .tickSizeOuter(0)
        )
        .call((g) => g.select('.domain').remove())
        .call((g) => g.select('.tick:last-of-type text').clone().attr('x', 3).attr('text-anchor', 'start').attr('font-weight', 'bold'));

    // X axis
    const formatDate = (date: Date) => {
      const currentLang = this.translateService.currentLang.slice(0, 2);
      if (this.estimatedPeriod === 1) {
        return new Intl.DateTimeFormat(currentLang, { month: 'long' }).format(date);
      }
      return new Intl.DateTimeFormat(currentLang, { year: 'numeric' }).format(date);
    };

    const xAxis = (g) =>
      g.attr('transform', `translate(${this.estimatedPeriod === 1 ? '15' : '0'},${height - this.config.margin.bottom})`).call(
        d3
          .axisBottom(x)
          .ticks(width / 80)
          .tickSizeOuter(0)
          .tickFormat(formatDate)
      );

    // Create grid
    this.svg.append('g').attr('class', 'grid').call(grid);

    // Tooltip dot
    const dot = this.svg
      .append('g')
      .attr('class', 'stacked-area-dot')
      .append('circle')
      .attr('r', 4)
      .attr('stroke-width', 4)
      .attr('filter', 'drop-shadow(0 0 2px #F8F6F1)')
      .style('opacity', 0);

    // Create lines
    this.svg
      .append('g')
      .attr('class', 'stacked-area-lines')
      .selectAll('path')
      .data(series)
      .join('path')
      .attr('fill', 'none')
      .attr('stroke-width', 3)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('class', 'stacked-line')
      .attr('d', line as any);

    // Create paths
    this.svg
      .append('g')
      .attr('class', 'stacked-area-paths')
      .selectAll('path')
      .data(series)
      .join('path')
      .attr('class', 'stacked')
      .style('fill', (d, i) => {
        return `url(#linearGradient${i as number})`;
      })
      .attr('d', this.area);

    // Tooltip
    const tooltip = d3.select('#stacked-area-chart-tooltip');

    // Color palete
    const colors = ['#F4A22E', '#98B172', '#A3D0DA'];

    // Display tooltip
    const displayTooltip = (ev, e) => {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      const bisectObj = d3.bisector((d: any) => d.data.date).left;
      const expectedEv = window.TouchEvent && ev instanceof TouchEvent ? ev.touches[0] : ev;
      const date = x.invert(d3.pointer(expectedEv, this.svg.node())[0]);
      const index = bisectObj(series[e.index], date, 1);
      const item = series[e.index][index] ? series[e.index][index] : null;
      const yScaleValue: number = y(item ? item[1] : 0);
      let xScaleValue: number = x(item ? item.data.date : 0);

      const SAFE_AREA_VALUE = 65;

      if (xScaleValue > this.config.width - SAFE_AREA_VALUE) {
        xScaleValue = this.config.width - SAFE_AREA_VALUE;
      } else if (xScaleValue < SAFE_AREA_VALUE) {
        xScaleValue = SAFE_AREA_VALUE;
      }

      if (this.selectedIndex === null || this.selectedIndex === e.index) {
        tooltip.style('transform', `translate(${xScaleValue}px,  ${yScaleValue}px)`).style('opacity', `1`);
        dot
          .attr('transform', `translate(${x(item ? item.data.date : 0)},  ${yScaleValue})`)
          .attr('stroke', colors[e.index])
          .attr('fill', colors[e.index])
          .style('opacity', 1);
      } else {
        tooltip.attr('transform', `translate(-100px, -100px)`);
      }

      // eslint-disable-next-line prefer-destructuring
      this.tooltipData.value = item ? item[1] : 0;
      this.tooltipData.invested = this.investedByYear[index];
      this.tooltipData.year = Number(date.getFullYear()) + 1;
    };

    // Listeners
    this.svg.selectAll('.stacked').on('touchmove mousemove', (ev, item) => {
      displayTooltip(ev, item);
    });
    this.svg.selectAll('.stacked-line').on('touchmove mousemove', (ev, item) => {
      displayTooltip(ev, item);
    });
    this.svg.selectAll('.stacked').on('touchend mouseout', () => {
      tooltip.style('opacity', 0).attr('transform', `translate(-100px, -100px)`);
      dot.style('opacity', 0);
    });
    this.svg.selectAll('.stacked-line').on('touchend mouseout', () => {
      tooltip.style('opacity', 0);
      dot.style('opacity', 0);
    });

    // Create x axis
    this.svg.append('g').attr('class', 'xaxis-stacked').call(xAxis);

    // Create y axis
    this.svg.append('g').attr('class', 'yaxis-stacked').call(yAxis);
  }

  handleSelection(index: number | null): void {
    this.svg
      .selectAll('.stacked')
      .style('opacity', '1')
      .filter((item, i) => this.selectedIndex === i)
      .attr('d', this.area)
      .style('fill', `url(#linearGradient${this.selectedIndex as number})`);
    this.svg.selectAll('.stacked-line').transition().duration(100).style('opacity', '1');

    if (this.selectedIndex === index) {
      this.selectedIndex = null;
    } else {
      this.svg
        .selectAll('.stacked-line')
        .style('opacity', '0.2')
        .transition()
        .duration(300)
        .filter((item, i) => index === i)
        .style('opacity', '1')
        .transition()
        .duration(300);
      this.svg
        .selectAll('.stacked')
        .style('opacity', '0.2')
        .transition()
        .duration(200)
        .filter((item, i) => index === i)
        .attr('d', this.areaSelected)
        .style('opacity', '1')
        .style('fill', `url(#linearGradient${index})`)
        .transition()
        .duration(200);
      this.selectedIndex = index;
    }
  }
}
