import { DateTranslatePipe } from './date-translate.pipe';
import { IbanPipe } from './iban.pipe';
import { IsPositivePipe } from './is-positive.pipe';
import { PhonePipe } from './phone.pipe';
import { ThousandSeparatorPipe } from './thousand-separator.pipe';
import { TruncatePipe } from './truncate.pipe';

export const pipes = [IbanPipe, IsPositivePipe, PhonePipe, TruncatePipe, ThousandSeparatorPipe, DateTranslatePipe];

export { IbanPipe } from './iban.pipe';
export { IsPositivePipe } from './is-positive.pipe';
export { PhonePipe } from './phone.pipe';
export { TruncatePipe } from './truncate.pipe';
export { ThousandSeparatorPipe } from './thousand-separator.pipe';
export { DateTranslatePipe } from './date-translate.pipe';
