// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login__background {
  background-color: var(--ion-color-secondary);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.login__background::after {
  content: "";
  background-color: var(--ion-color-secondary);
  background-image: url('hero-mountains.4adb9463ba4fd161.png');
  background-position: center;
  background-size: cover;
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

ion-content {
  --background: transparent;
  position: relative;
  text-align: center;
}

.login__logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-35vh);
}
.login__logo.staging::after {
  bottom: -5px;
  color: var(--ion-color-primary);
  content: "staging";
  font-size: 14px;
  font-style: italic;
  left: 0;
  margin-right: 30px;
  position: absolute;
  right: 0;
}
.login__logo img {
  height: auto;
  width: 142px;
}

.container--center {
  padding: 0 30px;
  min-height: 330px;
  opacity: 1;
  animation: 2s ease-out 0s 1 showContent;
}

.login__logged-user h1 {
  font-family: var(--font-secondary);
  font-size: 35px;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 25px;
  text-align: left;
}
@media only screen and (max-width: 340px) {
  .login__logged-user h1 {
    font-size: 28px;
    margin-top: 20px;
  }
}
.login__logged-user .email {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 30px;
  text-align: left;
}

.login__signup {
  position: absolute;
  bottom: 45px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  padding: 0 30px;
}
.login__signup p {
  margin: 0;
  font-size: 16px;
  padding: 4px 0;
}
.login__signup span {
  margin: 0 10px;
}

@media only screen and (max-height: 550px) {
  .login__signup {
    display: none;
  }
  .login__logo {
    opacity: 0;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
