/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Share } from '@capacitor/share';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as HomeActions from '@home/home.actions';
import * as fromAccount from '@account/account.reducers';
import * as fromHome from '@home/reducers';

import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-home-impact-campaign-support',
  styleUrls: ['./home-impact-campaign-support.component.scss'],
  templateUrl: './home-impact-campaign-support.component.html',
  encapsulation: ViewEncapsulation.None
})
export class HomeImpactCampaignSupportComponent implements OnDestroy {
  protected readonly onDestroy$ = new Subject<void>();
  @Input() campaignID: number;
  @Input() liked: boolean;
  @Input() expired: boolean;
  @Input() shareholder: boolean;
  rebalanceAgreed = false;
  code: string;
  isLoading = false;
  isFirstTime = true;

  constructor(
    private modalController: ModalController,
    private store: Store,
    private trackingService: TrackingService,
    private translateService: TranslateService
  ) {
    this.store.pipe(select(fromAccount.selectCustomer), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.code = value.referral_code;
    });
    this.store.pipe(select(fromHome.selectImpactStoryDetail), takeUntil(this.onDestroy$)).subscribe((story) => {
      if (!story.article?.attributes?.title) {
        return;
      }

      this.liked = story.article.attributes.liked;
      this.isLoading = false;

      if (!this.liked && !this.isFirstTime) {
        void this.modalController.dismiss({
          dismissed: true
        });
      }
    });
  }

  dismiss(): void {
    this.trackingService.trackActivity(`[Button] Campaign Confirmation ${this.liked ? 'final handshake' : 'heart'} modal: Close`);
    void this.modalController.dismiss({
      dismissed: true
    });
  }

  rebalance() {
    this.rebalanceAgreed = !this.rebalanceAgreed;
    this.trackingService.trackActivity(`[Checkbox] Campaign: Rebalance portfolio ${this.rebalanceAgreed ? 'checked' : 'unchecked'}`);
  }

  supportCampaign() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    if (this.shareholder || this.rebalanceAgreed) {
      this.trackingService.trackActivity('[Button] Campaign: CTA support');
      this.store.dispatch(HomeActions.sendEngagement({ action: true, id: this.campaignID, rebalance_portfolio: !this.shareholder }));
    }
  }

  unsupportCampaign() {
    this.isFirstTime = false;
    this.trackingService.trackActivity('[Button] Campaign: CTA unsupport');
    this.store.dispatch(HomeActions.deleteEngagement({ id: this.campaignID }));
  }

  async shareCampaign() {
    this.trackingService.trackActivity('[Button] Campaign: Confirmation modal Share CTA');
    const key = this.expired ? 'IMPACT.publicisCampaign.share.finished' : 'IMPACT.publicisCampaign.share.ongoing';
    await Share.share({
      text: this.translateService.instant(key, { code: this.code })
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
