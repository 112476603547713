import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-home-balance',
  templateUrl: './home-balance.component.html',
  styleUrls: ['./home-balance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeBalanceComponent {
  @Input() totalAmount!: number;
  @Input() netInvestment: number;
  @Input() netReturn: number;
  @Input() currency: string;

  infoList = {
    updateDate: false,
    netInvestment: false,
    totalReturn: false
  };

  showInfo(item: string): void {
    this.infoList[item] = !this.infoList[item];
  }
}
