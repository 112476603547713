import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { IMPACT_CAMPAIGNS_INYOVA_IDS, IMPACT_CAMPAIGNS_UPDATE_INYOVA_IDS } from '@app/app.constants';
import { ImpactStoriesItem } from '@shared/models/Home';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-impact-stories-item',
  styleUrls: ['./impact-stories-item.component.scss'],
  templateUrl: './impact-stories-item.component.html'
})
export class HomeImpactStoriesItemComponent implements OnChanges {
  @Input() language: string;
  @Input() item: ImpactStoriesItem;
  @Output() openDetail = new EventEmitter<boolean>();
  isActive = false;
  isCampaignUpdate = false;

  constructor(private trackingService: TrackingService) {}

  open(): void {
    this.openDetail.emit(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { item } = changes;

    if (item.firstChange) {
      const id = Number((item.currentValue as ImpactStoriesItem).attributes.inyova_id);
      this.isActive = IMPACT_CAMPAIGNS_INYOVA_IDS.includes(id);
      this.isCampaignUpdate = IMPACT_CAMPAIGNS_UPDATE_INYOVA_IDS.includes(id);
    }
  }

  track(event: string): void {
    this.trackingService.trackActivity(event);
  }
}
