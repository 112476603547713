// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box--white {
  background: var(--ion-color-primary-contrast);
  padding: 10px 0;
  margin-bottom: 10px;
}
.box--white h2 {
  font-family: var(--font-secondary);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;
}
.box--white h2 ion-icon {
  font-size: 16px;
  color: #b4b3b1;
}
.box--white .expected-box {
  margin-bottom: 14px;
  padding: 12px;
  background-color: var(--ion-color-light);
  border-radius: 4px;
  text-align: center;
}
.box--white .expected-box p.title {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  font-weight: 700;
  margin-bottom: 4px;
}
.box--white .expected-box p.funds {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.box--white .expected-box p.funds span {
  margin-right: 4px;
  font-size: 12px;
  line-height: 12px;
  color: #6f6b63;
}
.box--white .expected-box.blue {
  background-color: #edf6f8;
}
.box--white .expected-box.orange {
  background-color: #fdecd5;
}
.box--white .expected-box.blue, .box--white .expected-box.orange {
  margin-bottom: 8px;
  padding: 16px;
}
.box--white .expected-box.blue p.title, .box--white .expected-box.orange p.title {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: normal;
}
.box--white .expected-box.blue p.funds, .box--white .expected-box.orange p.funds {
  font-size: 24px;
  line-height: 32px;
}
.box--white .tertiary {
  color: var(--ion-color-tertiary);
}
.box--white p {
  font-size: 14px;
}
.box--white p.inline {
  display: inline-flex;
}
.box--white p a {
  font-size: 16px;
}

.strategy-estimate-intro {
  margin: 12px 0;
}

.strategy-chart {
  min-height: 164px;
  width: 100%;
  margin-top: 14px;
}

.strategy-estimate__details {
  font-size: 14px;
}
.strategy-estimate__details h2:last-of-type {
  margin-top: 28px;
}

ion-select {
  --padding-start: 4px;
  --padding-end: 4px;
  --padding-top: 4px;
  --padding-bottom: 4px;
  border-bottom: 1px solid var(--ion-color-primary);
  display: inline-flex;
  font-size: 14px;
}

span.expected,
span.gain,
span.loss {
  display: inline-block;
  height: 10px;
  margin: 0 8px 0 -5px;
  width: 10px;
  border-radius: 50%;
}

span.expected {
  background-color: var(--ion-color-chart-expected);
}

span.gain {
  background-color: var(--ion-color-chart-good);
}

span.loss {
  background-color: var(--ion-color-chart-bad);
}

.estimate-explanation {
  margin: 10px 0 5px;
}

.disclaimer-box {
  background-color: var(--ion-color-light);
  margin-top: 10px;
  padding: 10px;
}

.overview-box ion-row {
  margin-top: 12px;
}

.estimated-box {
  margin: 16px 0;
}

button.outline {
  margin: 24px 0;
}

.tab-years {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #d5d5d4;
  width: 100%;
}
.tab-years .tab {
  padding: 8px 10px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.005em;
}
.tab-years .tab.active {
  padding: 8px 10px 6px;
  border-bottom: 2px solid var(--ion-color-primary);
}

.lowercase {
  text-transform: lowercase;
}

.info {
  margin: 24px 0;
  background: #DAECF1;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(47, 44, 38, 0.04), 0px 2px 2px rgba(47, 44, 38, 0.08);
  padding: 16px;
  text-align: left;
}
.info p {
  line-height: 20px;
  font-size: 14px;
}
.info p.title {
  margin-bottom: 4px;
  font-weight: 700;
}
.info p.title ion-icon {
  vertical-align: bottom;
  margin-right: 4px;
  font-size: 24px;
  color: #A3D0DB;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
