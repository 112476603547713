import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-home-mini-widget',
  templateUrl: './home-mini-widget.component.html',
  styleUrls: ['./home-mini-widget.component.scss']
})
export class HomeMiniWidgetComponent {
  @Input() minInvestment: number;
  @Input() currentAmount: number;
  @Input() percentage: number;
  @Input() amount: number;
  @Input() currency: string;
  showMiniWidgetInfo = false;
}
