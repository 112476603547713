import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import * as HomeActions from '@home/home.actions';

import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-home-fake-door-modal',
  styleUrls: ['./home-fake-door-modal.component.scss'],
  templateUrl: './home-fake-door-modal.component.html'
})
export class HomeFakeDoorModalComponent {
  @Input() type: 'portfolioReview' | 'depositControl';
  @Input() screen: number;

  constructor(private modalController: ModalController, private trackingService: TrackingService, private store: Store) {}

  continue() {
    this.trackingService.trackActivity(`[Button] Fake door - ${this.type}: CTA screen ${this.screen} clicked`);

    if (this.screen === 3) {
      this.close();
      return;
    }

    if (this.screen === 2) {
      const option = this.type === 'portfolioReview' ? 'portfolio_review' : 'deposit_control';
      this.store.dispatch(HomeActions.setAccountOptions({ option }));
    }

    this.screen += 1;
  }

  close() {
    void this.modalController.dismiss({
      dismissed: true,
      continue: false
    });
  }
}
