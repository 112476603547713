import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isPositive',
  pure: true
})
export class IsPositivePipe implements PipeTransform {
  transform(value: number | string): string {
    if (!value) {
      return `${0}`;
    }
    if (typeof value === 'string') {
      value = Number(value);
    }
    return value && value > 0 ? `+${value}` : `${value}`;
  }
}
