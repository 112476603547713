import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SavingsPlanSocialProof } from '@shared/models/Account';
import { EstimatedValues } from '@shared/models/Shared';

@Component({
  selector: 'app-asset-social-proof',
  templateUrl: './asset-social-proof.component.html',
  styleUrls: ['./asset-social-proof.component.scss']
})
export class AssetSocialProofComponent {
  @Input() kind: string;
  @Input() currency: string;
  @Input() savingsPlanSocialProof: SavingsPlanSocialProof | null;
  @Input() estimatedPeriod: number;
  @Input() estimatedValues: EstimatedValues;
  @Input() expectedPercentages: { loss: number; expected: number; good: number };
  @Output() toggleDetails: EventEmitter<string> = new EventEmitter<string>();
  showDetails = false;

  get difference() {
    return Math.abs(this.estimatedValues.expected - this.estimatedValues.expectedWithout);
  }

  get knobPosition() {
    if (this.savingsPlanSocialProof === null) {
      return 0;
    }
    return `${
      (Math.abs(this.savingsPlanSocialProof.average - this.savingsPlanSocialProof.below_average) * 100) /
      (this.savingsPlanSocialProof.above_average - this.savingsPlanSocialProof.below_average)
    }%`;
  }

  onToggleDetails() {
    this.showDetails = !this.showDetails;
    this.toggleDetails.emit('[Button] SP proof: Toggle details');
  }
}
