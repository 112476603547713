// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds-legend {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  --custom-color: var(--color-core-neutral-5);
}
.ds-legend .dot {
  width: 8px;
  height: 8px;
  border-radius: var(--radius-2);
}
.ds-legend .dot.info {
  background: var(--color-system-info);
  border-color: var(--color-system-info);
}
.ds-legend .dot.warning {
  background: var(--color-system-warning);
  border-color: var(--color-system-warning);
}
.ds-legend .dot.success {
  background: var(--color-system-success);
  border-color: var(--color-system-success);
}
.ds-legend .dot.critical {
  background: var(--color-system-critical);
  border-color: var(--color-system-critical);
}
.ds-legend .dot.custom {
  background: var(--custom-color);
  border-color: var(--custom-color);
}
.ds-legend.outline .dot {
  border-width: var(--border-2);
  border-style: solid;
  background: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
