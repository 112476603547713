// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-start: 24px;
  --padding-end: 24px;
}

h1 {
  font-family: var(--font-secondary);
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 24px;
}

p {
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 24px;
}

.assets-success button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
  padding: 6px 16px;
  border-radius: 4px;
  background: var(--ion-color-primary);
  color: var(--ion-color-white);
  font-weight: 700;
}
.assets-success button.white {
  background: var(--ion-color-white);
  border: 2px solid var(--ion-color-primary);
  color: var(--ion-color-primary);
  padding: 4px 16px;
}
.assets-success button ion-icon {
  font-size: 28px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
