import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { ModalController } from '@ionic/angular';

import { noop } from 'rxjs';

import { LocalStorageKeys } from '@app/app.constants';
import { TrackingService } from '@shared/services/tracking.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-banner-modal',
  templateUrl: './home-banner-modal.component.html',
  styleUrls: ['./home-banner-modal.component.scss']
})
export class HomeBannerModalComponent implements OnInit {
  @Input() appLocation = 'ch';
  @Input() language: string;
  @Input() modalType: string;
  @Input() extarnalPage: string;
  links = {
    en: 'https://inyova.ch/en/expertise/investing-money-for-kids/',
    de: 'https://inyova.ch/wissen/geld-fuer-kinder-anlegen/',
    fr: 'https://inyova.ch/fr/expertise/investir-pour-ses-enfants/'
  };

  articleLink: string;

  constructor(
    private modalController: ModalController,
    private router: Router,
    private trackingService: TrackingService
  ) {}

  ngOnInit() {
    this.articleLink = this.links[this.language.split('-')[0]];
  }

  close(): void {
    this.trackingService.trackActivity(`[Button]: Closed Modal ${this.modalType}`);
    this.dismiss();
  }

  dismiss(): void {
    void this.modalController
      .dismiss({
        dismissed: true
      })
      .finally(() => {
        this.trackingService.trackActivity('[Modal]: Closed Modal');
      });
  }

  openBannerLink() {
    this.trackingService.trackActivity(`[Button]: Open ${this.modalType} account - CTA`);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const url = `${environment.customersApp[this.appLocation]}/?customer_token=${localStorage.getItem(
      LocalStorageKeys.AUTHENTICATION_TOKEN
    )}&redirect=${this.extarnalPage}`;

    const browser = Browser.open({ url });

    void Browser.addListener('browserFinished', () => {
      this.router.navigateByUrl('/public/login').finally(noop);
    });

    this.dismiss();
    return browser;
  }
}
