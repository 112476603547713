/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ds-checkbox',
  templateUrl: './ds-checkbox.component.html',
  styleUrls: ['./ds-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DsCheckboxComponent),
      multi: true
    }
  ]
})
export class DsCheckboxComponent implements ControlValueAccessor {
  @Input() checkboxPosition: 'center' | 'default' = 'default';
  @Input() label: string;
  @Input() hasError = false;
  @Input() disabled = false;
  @Input() errorMessage = '';
  @Input() variant: 'default' | 'box' = 'default';
  @Input() additionalInfo: string;
  @Input() checked = false;

  @Output() clicked = new EventEmitter();

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(checked: boolean) {
    this.checked = checked;
  }

  onModelChange(e: boolean) {
    if (this.disabled) return;
    this.checked = e;
    this.onChange(e);
  }
}
