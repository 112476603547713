import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromHome from '@home/reducers';

import { StatusBarStylingService } from '@app/shared/services/status-bar-styling.service';
import { HomeImpactCampaignSupportComponent } from '@home/modals';

@Component({
  selector: 'app-campaign-video',
  templateUrl: './campaign-video.component.html',
  styleUrls: ['./campaign-video.component.scss']
})
export class CampaignVideoComponent implements OnInit {
  protected readonly onDestroy$ = new Subject<void>();
  @Input() videoURL!: string;
  @Input() id!: number;
  @Input() liked!: boolean;
  safeURL: SafeResourceUrl;

  constructor(
    private modalController: ModalController,
    private _sanitizer: DomSanitizer,
    private store: Store,
    private statusBarStyling: StatusBarStylingService
  ) {}

  ngOnInit(): void {
    // eslint-disable-next-line no-underscore-dangle
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoURL);

    this.store.pipe(select(fromHome.selectImpactStoryDetail), takeUntil(this.onDestroy$)).subscribe((story) => {
      if (!story.article?.attributes?.title) {
        return;
      }

      this.liked = story.article.attributes.liked;
    });
  }

  dismiss() {
    void this.modalController.dismiss({
      dismissed: true,
      continue: false
    });
  }

  async openCampaignSupport() {
    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: HomeImpactCampaignSupportComponent,
      cssClass: 'app-fullscreen green',
      componentProps: {
        campaignID: this.id,
        liked: this.liked
      },
      presentingElement: await this.modalController.getTop()
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('base');
    });
    return modal.present();
  }
}
