import { Component, Input } from '@angular/core';

type ProgressBarTypes = 'primary' | 'secondary' | 'neutral' | 'info' | 'success' | 'warning' | 'critical';

@Component({
  selector: 'app-ds-progress-bar',
  templateUrl: './ds-progress-bar.component.html',
  styleUrls: ['./ds-progress-bar.component.scss']
})
export class DsProgressBarComponent {
  @Input() percentage: number | null | undefined;
  @Input() type: ProgressBarTypes;
}
