// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio-review {
  background: var(--ion-color-secondary);
  border-radius: 8px;
  margin: 0 var(--spacing-4) var(--spacing-8);
  padding: 24px;
}
.portfolio-review div {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}
.portfolio-review div img {
  width: 40px;
  height: 40px;
}
.portfolio-review div .text {
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.portfolio-review button {
  width: 100%;
  height: auto;
  padding: 12px 20px;
  background: var(--ion-color-primary);
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--ion-color-white);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
