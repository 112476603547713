// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  margin-bottom: var(--spacing-8);
}

.ds-content-switcher {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-1);
  border-radius: var(--radius-2);
  background-color: var(--color-core-brand-3-2);
}
.ds-content-switcher .element {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  min-width: var(--spacing-10);
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--radius-0);
  color: var(--color-core-neutral-8);
}
.ds-content-switcher .element.active {
  background-color: var(--color-core-neutral-0);
  border-radius: var(--radius-1);
  box-shadow: var(--shadow-elavated);
  color: var(--color-core-neutral-10);
}
.ds-content-switcher .element span.dot {
  margin-left: calc(var(--spacing-1) / 2);
  width: var(--spacing-2);
  height: var(--spacing-2);
  border-radius: var(--radius-2);
  background: var(--color-core-brand-1-5);
}
.ds-content-switcher .line {
  display: inline-block;
  width: var(--border-1);
  height: var(--flh-3);
  background-color: var(--color-core-neutral-4);
}
.ds-content-switcher .line:first-of-type, .ds-content-switcher .line.hide {
  visibility: hidden;
}
.ds-content-switcher .element.active + .line {
  visibility: hidden;
}
.ds-content-switcher.medium .element {
  padding: var(--spacing-2);
}
.ds-content-switcher.fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
