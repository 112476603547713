import { createSelector } from '@ngrx/store';
import * as fromHome from './home.reducers';
import * as fromImpact from './impact.reducers';
import * as fromPerformance from './performance.reducers';
import * as fromAccount from '@account/account.reducers';

import { IMPACT_CAMPAIGNS_INYOVA_IDS } from '@app/app.constants';
import { Notification } from '@shared/models/Account';
import {
  CompaniesStockPerformanceAccount,
  EndValuesAccount,
  ImpactStoriesItem,
  PerformanceAccount,
  PerformanceFilterRange,
  PerformanceGraphItem,
  PerformanceType
} from '@shared/models/Home';
import { State } from '@shared/models/State';

export { fromHome, fromImpact, fromPerformance };

export const HOME_REDUCER_FEATURE_KEY = 'home';
export const PERFORMANCE_REDUCER_FEATURE_KEY = 'performance';
export const IMPACT_REDUCER_FEATURE_KEY = 'impact';

/*
 * SELECTORS
 * */
export const selectFeatureImpact = (state: State) => state.impact;
export const selectFeatureHome = (state: State) => state.home;
export const selectFeaturePerformance = (state: State) => state.performance;
// Performance selectors
export const selectAccountsGraphData = createSelector(
  selectFeaturePerformance,
  (state: fromPerformance.PerformanceState) => state.accountsGraphData
);
export const selectAccountsCompaniesStockPerformance = createSelector(
  selectFeaturePerformance,
  (state: fromPerformance.PerformanceState) => state.companiesStockPerformance
);
export const selectAccountsEndValues = createSelector(
  selectFeaturePerformance,
  (state: fromPerformance.PerformanceState) => state.accountsEndValues
);
export const selectAccountsHomepage = createSelector(
  selectAccountsEndValues,
  selectAccountsGraphData,
  (endValues: EndValuesAccount[], performance: PerformanceAccount[]) => {
    const accountsMapped = {};
    endValues.forEach((item) => {
      accountsMapped[item.account.id] = {
        ...accountsMapped[item.account.id],
        account: item.account,
        endValues: item
      };
    });
    performance.forEach((item) => {
      accountsMapped[item.account.id] = {
        ...accountsMapped[item.account.id],
        graphData: item
      };
    });
    return accountsMapped;
  }
);
export const selectCrowdInvestorData = createSelector(
  selectFeaturePerformance,
  (state: fromPerformance.PerformanceState) => state.crowdInvestorData
);

export const selectAccountsCompaniesStockPerformanceCurrentAccount = createSelector(
  selectAccountsCompaniesStockPerformance,
  fromAccount.selectCurrentAccountID,
  (state, currentAccountID) => {
    if (!state || !state.length) {
      return {} as CompaniesStockPerformanceAccount;
    }
    return state.find((account) => account.account.id === currentAccountID) || ({} as CompaniesStockPerformanceAccount);
  }
);
export const selectAccountsGraphDataCurrentAccount = createSelector(
  selectAccountsGraphData,
  fromAccount.selectCurrentAccountID,
  (state, currentAccountID) => {
    if (!state || !state.length) {
      return {} as PerformanceAccount;
    }
    return state.find((account) => account.account.id === currentAccountID) || ({} as PerformanceAccount);
  }
);
export const selectAccountsEndValuesCurrentAccount = createSelector(
  selectAccountsEndValues,
  fromAccount.selectCurrentAccountID,
  (state, currentAccountID) => {
    if (!state || !state.length) {
      return {} as EndValuesAccount;
    }
    return state.find((account) => account.account.id === currentAccountID) || ({} as EndValuesAccount);
  }
);
export const selectCompaniesStockPerformance = createSelector(selectAccountsCompaniesStockPerformanceCurrentAccount, (state) => {
  return state.companies?.length ? [...state.companies].sort((a, b) => b.attributes.performance - a.attributes.performance) : null;
});

export const selectFeatureData = createSelector(selectAccountsGraphDataCurrentAccount, (state) => {
  return state?.graphData || [];
});

export const selectEndValuesData = createSelector(selectAccountsEndValuesCurrentAccount, (state) => state.endValues);

// Performance chart data
const selectPerformanceState = createSelector(selectFeaturePerformance, fromPerformance.getPerformanceData);
const selectPerformanceFilters = createSelector(selectPerformanceState, fromPerformance.getPerformanceDataFilter);
const selectPerformanceChartData = createSelector(selectPerformanceState, fromPerformance.getPerformanceGraphData);
const selectPerfDataLoading = createSelector(selectPerformanceState, fromPerformance.getPerformanceDataLoading);

const selectPerformanceFromSource = createSelector(
  selectPerformanceFilters,
  selectPerformanceChartData,
  selectFeatureData,
  ({ range }, filteredData, allData) => {
    return range === PerformanceFilterRange.ALL ? allData : filteredData;
  }
);

const selectFirstEndValue = createSelector(selectPerformanceFromSource, (items) => (items.length > 0 ? items[0].end_value : 0));
export const selectPerformanceAll = createSelector(selectFeatureData, (allData) => {
  return allData.length > 0 ? allData[allData.length - 1].end_value : 0;
});

export const selectPerformanceData = createSelector(selectPerformanceFilters, selectPerformanceFromSource, ({ chartType }, data) => {
  return data.map((item) => {
    return {
      date: new Date(item.date),
      value: chartType === PerformanceType.PERFORMANCE ? item.return : item.end_value,
      transactions: item.transactions,
      ...(chartType === PerformanceType.PERFORMANCE && { endValue: item.end_value }),
      ...(chartType === PerformanceType.PERFORMANCE && { benchmarkValue: item.benchmark_return })
    } as PerformanceGraphItem;
  });
});

export const selectPerformance = createSelector(
  selectFirstEndValue,
  selectPerformanceFilters,
  selectPerfDataLoading,
  (firstEndValue, { range, chartType }, loading) => {
    return { firstEndValue, range, chartType, loading };
  }
);

// Impact selectors
export const selectImpactMetrics = createSelector(selectFeatureImpact, (state: fromImpact.ImpactState) => {
  return state.metrics?.map((item) => item.attributes);
});
export const selectImpactInitialized = createSelector(selectFeatureImpact, (state: fromImpact.ImpactState) => state.initialized);
export const selectMetricsLoading = createSelector(selectFeatureImpact, (state: fromImpact.ImpactState) => state.isMetricsLoading);
export const selectImpactStories = createSelector(selectFeatureImpact, (state: fromImpact.ImpactState) => state.stories);
export const selectImpactStoriesLoading = createSelector(selectFeatureImpact, (state: fromImpact.ImpactState) => state.isStoriesLoading);
export const selectShowImpactStoriesFilter = createSelector(selectFeatureImpact, (state: fromImpact.ImpactState) => {
  return state.stories.some((item) => item && item.attributes.engagement_campaign);
});
export const selectImpactStoryDetail = createSelector(selectFeatureImpact, (state: fromImpact.ImpactState) => state.storyDetail);
export const selectImpactStoryDetailLoading = createSelector(
  selectFeatureImpact,
  (state: fromImpact.ImpactState) => state.storyDetail.loading
);

// Insights selectors
export const selectCustomerNotifications = createSelector(selectFeatureHome, (state: fromHome.HomeState) => state.customerNotifications);
export const selectUnseenNotifications = createSelector(selectCustomerNotifications, (customerNotifications: Notification[]) => {
  if (!customerNotifications || !customerNotifications.length) return [] as Notification[];
  return customerNotifications.filter((item) => !item.attributes.seen);
});

export const selectImpactStoriesFilter = createSelector(selectFeatureImpact, (state: fromImpact.ImpactState) => state.storiesFilter);
export const selectImpactStoriesFiltered = createSelector(
  selectImpactStories,
  selectImpactStoriesFilter,
  (state: ImpactStoriesItem[], filter: null | string) => {
    if (!filter) {
      return state;
    }

    return state.filter((item) =>
      filter === 'shareholderEngagement' ? item.attributes.engagement_campaign : !item.attributes.engagement_campaign
    );
  }
);

export const selectPublicisCampaign = createSelector(selectImpactStories, (stories: ImpactStoriesItem[]) => {
  const campaigns = stories.filter((story) => IMPACT_CAMPAIGNS_INYOVA_IDS.includes(story.attributes.inyova_id));
  return campaigns[0];
});

export const selectEngagementStories = createSelector(selectImpactStories, (stories: ImpactStoriesItem[]) => {
  const campaigns = stories.filter((story) => story.attributes.engagement_campaign);
  return campaigns.filter((story) => story.attributes.liked || new Date(story?.attributes?.expires_at) >= new Date());
});

export const selectEngagementSuccess = createSelector(selectFeatureImpact, (state: fromImpact.ImpactState) => state.engagement);
export const selectDeleteEngagementStatus = createSelector(
  selectFeatureImpact,
  (state: fromImpact.ImpactState) => state.deleteEngagementStatus
);

export const selectSuperhumanSurveyCurrentQuestion = createSelector(selectFeatureHome, (state: fromHome.HomeState) => {
  if (!state.superhumanSurvey || !state.superhumanSurvey.length)
    return { currentIndex: null, nextQuestionID: null, previousQuestionID: null, question: null, surveyLength: null };

  let index = 0;
  const question = state.superhumanSurvey.find((item, i) => {
    if (item.id === state.currentSuperhumanSurveyQuestionID) {
      index = i;
      return true;
    }
    return false;
  });
  const nextQuestion = state.superhumanSurvey[index + 1];
  const previousQuestion = state.superhumanSurvey[index - 1];

  return {
    currentIndex: index,
    nextQuestionID: nextQuestion ? nextQuestion.id : null,
    previousQuestionID: previousQuestion ? previousQuestion.id : null,
    question,
    surveyLength: state.superhumanSurvey.length
  };
});

export const selectTechnicalIssuesReporting = createSelector(selectFeatureHome, (state: fromHome.HomeState) => state.technicalIssues);

export const selectFakeDoorStatus = createSelector(selectFeaturePerformance, (state: fromPerformance.PerformanceState) => {
  return state.joinedFakeDoor;
});

export const selectSavingsPlanSummary = createSelector(selectFeaturePerformance, (state: fromPerformance.PerformanceState) => {
  return state.savingsPlanSummary;
});

export const selectPerformanceSummary = createSelector(
  selectFeaturePerformance,
  (state: fromPerformance.PerformanceState) => state.performanceSummary
);

export const selectBalanceSheet = createSelector(selectFeaturePerformance, (state: fromPerformance.PerformanceState) => state.balanceSheet);

export const selectPromoCampaigns = createSelector(selectFeatureHome, (state: fromHome.HomeState) => state.promoCampaigns);
