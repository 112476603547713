import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerAccount } from '@inyova/models';
import { TranslateService } from '@ngx-translate/core';

import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as HomeActions from '@home/home.actions';
import * as fromAccount from '@account/account.reducers';
import * as fromHome from '@home/reducers';

import { formatDate } from '@app/home/home.utils';
import { PromoCampaignItem } from '@app/shared/models/Home';

@Component({
  selector: 'app-home-promo-campaign',
  templateUrl: './home-promo-campaign.component.html',
  styleUrls: ['./home-promo-campaign.component.scss']
})
export class HomePromoCampaignComponent implements OnInit {
  protected readonly onDestroy$ = new Subject<void>();
  loading: false;
  id: string;
  campaign: PromoCampaignItem;
  currentAccount: CustomerAccount;
  highestAmount: number;
  endDate: string;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      if (params.has('id')) {
        this.id = params.get('id').valueOf();
      }
    });
    this.store.pipe(select(fromHome.selectPromoCampaigns), takeUntil(this.onDestroy$)).subscribe((campaigns) => {
      if (!campaigns || !campaigns.length) {
        return;
      }
      const campaign = campaigns.find((campaign) => campaign.id === this.id);
      if (campaign) {
        this.campaign = campaign;
        this.highestAmount = Math.max(...campaign.promotions.map((o) => o.attributes.value));
        this.endDate = formatDate(campaign.attributes.end_date, '-', this.translateService.currentLang);
      }
    });
    this.store.pipe(select(fromAccount.selectCurrentAccount), takeUntil(this.onDestroy$)).subscribe((currentAccount) => {
      this.currentAccount = currentAccount;
    });
  }

  participate(participation: boolean): void {
    this.store.dispatch(HomeActions.setCampaignParticipation({ participation, id: Number(this.campaign.id) }));
  }
}
