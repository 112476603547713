import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Browser } from '@capacitor/browser';

import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as HomeActions from '@home/home.actions';
import * as fromAccount from '@account/account.reducers';
import * as fromHome from '@home/reducers';

import { ImpactStoryDetail } from '@shared/models/Home';
import { State } from '@shared/models/State';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-impact-article',
  styleUrls: ['./impact-article.component.scss'],
  templateUrl: './impact-article.component.html'
})
export class HomeImpactArticleComponent implements OnInit, OnDestroy {
  backURL: string;
  id: number;
  loading = true;
  story: ImpactStoryDetail['attributes'];
  engagementCampaignActive: boolean;
  language: string;
  protected readonly onDestroy$ = new Subject<void>();

  constructor(
    private trackingService: TrackingService,
    private store: Store<State>,
    private route: ActivatedRoute
  ) {
    this.store.pipe(select(fromAccount.selectCustomer), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.language = value.language;
    });
  }

  ngOnInit() {
    this.store.dispatch(HomeActions.getImpactStoryDetailSuccess({ data: {} as ImpactStoryDetail }));
    this.route.paramMap.pipe(takeUntil(this.onDestroy$)).subscribe((params) => {
      if (params.has('id')) {
        this.id = Number(params.get('id').valueOf());
        this.store.dispatch(HomeActions.getImpactStoryDetail({ id: this.id }));
      }
    });

    this.store.pipe(select(fromHome.selectImpactStoryDetail), takeUntil(this.onDestroy$)).subscribe((story) => {
      if (!story.article?.attributes?.title) {
        return;
      }

      this.story = story.article.attributes;

      this.engagementCampaignActive = new Date() < new Date(this.story.expires_at);
    });

    this.store.pipe(select(fromHome.selectImpactStoryDetailLoading), takeUntil(this.onDestroy$)).subscribe((loading) => {
      this.loading = loading;
    });
  }

  openArticle(url: string, cta: boolean) {
    if (cta) {
      this.track(`[Button] Register (Impact story detail) - ${this.id}`);
    } else {
      this.track(`[Button] Read more (Impact story detail) - ${this.id}`);
    }
    void Browser.open({
      url
    });
  }

  track(event: string) {
    this.trackingService.trackActivity(event);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.store.dispatch(HomeActions.getImpactStoryDetailSuccess({ data: {} as ImpactStoryDetail }));
  }
}
