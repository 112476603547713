// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header {
  background-color: var(--ion-color-light);
}

.impact-item__header {
  padding: 0 15px;
  text-align: left;
  min-height: 50px;
  margin-bottom: 15px;
}
.impact-item__header h1 {
  font-family: var(--font-secondary);
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
  margin-top: 8px;
}
.impact-item__header p {
  color: var(--ion-color-tertiary);
  font-size: 12px;
  font-weight: 500;
}
.impact-item__header div.label {
  color: var(--ion-color-success);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.24em;
  margin: 12px 0;
  text-align: center;
  text-transform: uppercase;
}
.impact-item__header div.label img {
  margin-left: 12px;
  vertical-align: middle;
}

.impact-item__subtitle {
  font-weight: 700;
  padding: 0 15px;
}

.impact-item__image {
  background-color: var(--ion-color-secondary);
  background-position: center;
  background-size: cover;
  height: 175px;
  position: relative;
  width: 100%;
}

.impact-item__cta {
  padding: 0 15px 9px;
}

.impact-item__content {
  padding: 0 15px;
  padding-bottom: 50px;
  margin: 15px 0;
}
.impact-item__content p {
  margin-bottom: 20px;
}
@media only screen and (max-width: 320px) {
  .impact-item__content {
    padding-bottom: 0;
  }
}

.impact-item__footer {
  background: linear-gradient(180deg, #f8f6f1 28.9%, rgba(248, 246, 241, 0.8) 100%);
  padding: 8px 16px 16px;
  width: 100%;
  bottom: 0;
  display: flex;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  transition: all 1s linear;
}

button {
  color: var(--ion-color-primary-contrast);
  background: #57544e;
  border-radius: 4px;
  background: #57544e;
  border: 1px solid #57544e;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background ease 50ms;
}
button:hover, button:focus {
  background: #57544e;
}
button svg {
  min-width: 36px;
  width: 36px;
  height: 36px;
  margin: 4px 8px;
}
button span {
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
}
button span::after {
  content: attr(love);
}
button.loading svg {
  animation: spinLogo 900ms linear infinite forwards;
}
button.animateOn {
  animation: recolorButtonOn 900ms linear 1 forwards;
  animation-delay: 700ms;
  border-radius: 4px;
}
button.animateOn svg {
  animation: spinLogo 900ms linear 1 forwards;
}
button.animateOn svg path {
  animation: recolorLogoOn 900ms linear 1 forwards;
}
button.animateOn span::after {
  animation: labelChangeOn 400ms linear 1 forwards;
  animation-delay: 900ms;
  max-width: 0;
  transition: max-width 1s;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
}
button.animateOff {
  animation: recolorButtonOff 900ms linear 1 forwards;
  border-radius: 4px;
}
button.animateOff svg path {
  animation: recolorLogoOff 900ms linear 1 forwards;
}
button.animateOff span::after {
  animation: labelChangeOff 200ms linear 1 forwards;
  max-width: 0;
  transition: max-width 1s;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  content: attr(engage);
}

@keyframes recolorButtonOn {
  to {
    color: var(--ion-color-primary);
    border: 1px solid var(--ion-color-primary);
    background: var(--ion-color-secondary);
  }
}
@keyframes recolorButtonOff {
  to {
    color: var(--ion-color-primary-contrast);
    border: 1px solid #57544e;
    background: #57544e;
  }
}
@keyframes spinLogo {
  to {
    transform: rotate(360deg);
  }
}
@keyframes recolorLogoOn {
  to {
    fill: var(--ion-color-red);
  }
}
@keyframes recolorLogoOff {
  to {
    fill: var(--ion-color-primary-contrast);
  }
}
@keyframes labelChangeOn {
  to {
    max-width: 20rem;
    content: attr(love);
  }
}
@keyframes labelChangeOff {
  to {
    max-width: 20rem;
    content: attr(engage);
  }
}
.impact-item-notification {
  display: flex;
  align-items: center;
  padding: 16px 12px;
  margin: 0 15px 32px;
  background: var(--ion-color-secondary);
  border-radius: 4px;
}
.impact-item-notification img {
  height: 24px;
  width: 24px;
  margin-right: 12px;
}
.impact-item-notification span.bold {
  display: block;
  font-weight: 700;
}
.impact-item-notification.success {
  background: var(--ion-color-success-background);
}
.impact-item-notification.success img {
  height: 34px;
  width: 34px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
