import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  readonly DATA_URL_PAGES = {
    'invite-friends': '/tabs/account/invite-friends',
    reimbursement: '/tabs/account/transactions',
    'initial-investment': '/tabs/home/performance',
    // deprecated old url, let's keep it until we sure there is no general-deposit
    'general-deposit': '/tabs/account/transactions?push=true',
    transactions: '/tabs/account/transactions?push=true',
    'risk-recheck': '/tabs/account',
    superhuman: '/tabs/home?showSurvey=true',
    'grow/reporting': '/tabs/grow-performance'
  };

  getRedirectPage(path: string) {
    if (!path || !path.length) {
      return '/tabs/home';
    }
    let redirectLink = this.DATA_URL_PAGES[path];
    if (!redirectLink) {
      redirectLink = `/tabs/${path}`;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return redirectLink;
  }
}
