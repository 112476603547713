// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1,
p {
  margin-bottom: var(--spacing-6);
}

h1 {
  margin-top: var(--spacing-4);
}

app-ds-button {
  display: block;
}
app-ds-button:nth-child(1n) {
  margin-bottom: var(--spacing-4);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
