import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromHome from '@home/reducers';

import { formatDate } from '@app/home/home.utils';
import { BalanceSheet } from '@app/shared/models/Home';
import { TrackingService } from '@app/shared/services/tracking.service';

@Component({
  selector: 'app-home-balance-sheet-modal',
  templateUrl: './home-balance-sheet-modal.component.html',
  styleUrls: ['./home-balance-sheet-modal.component.scss']
})
export class HomeBalanceSheetModalComponent {
  @Input() currency!: string;
  @Input() appLocation!: string;
  @Input() language!: string;
  year = new Date().getFullYear();

  showFees = false;

  readonly $balanceSheet: Observable<BalanceSheet | null> = this.store.select(fromHome.selectBalanceSheet);

  constructor(
    private modalController: ModalController,
    private trackingService: TrackingService,
    private store: Store
  ) {}

  formatDate(date: string, seperator: '.' | '-' = '.') {
    return formatDate(date, seperator, this.language);
  }

  close(): void {
    this.onTrackFeedback(`[Button]: Closed Balance Sheet Modal`);
    void this.modalController.dismiss({
      dismissed: true
    });
  }

  onTrackFeedback(message) {
    this.trackingService.trackActivity(message);
  }
}
