import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ds-allocation-bar',
  templateUrl: './ds-allocation-bar.component.html',
  styleUrls: ['./ds-allocation-bar.component.scss']
})
export class DsAllocationBarComponent {
  @Input() allocationItems: { percentage: number; value: number }[];
  @Input() customColors: string[];
}
