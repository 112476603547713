import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { ActionSheetController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as HomeActions from '@home/home.actions';
import * as fromAccount from '@account/account.reducers';
import * as fromHome from '@home/reducers';

import { ImpactStoryDetail } from '@shared/models/Home';
import { State } from '@shared/models/State';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-home-engagement-article',
  styleUrls: ['./home-engagement-article.component.scss'],
  templateUrl: './home-engagement-article.component.html'
})
export class HomeEngagementArticleComponent implements OnInit, OnDestroy {
  id: number;
  loadingStory = true;
  loadingCampaignLike = false;
  story!: ImpactStoryDetail['attributes'];
  buttonLiked: boolean;
  smallScreen: boolean;
  campaignExpiresAt: Date;
  campaignExpired: boolean;
  campaignLiked: boolean;
  campaignEngaged: boolean;
  language: string;
  protected readonly onDestroy$ = new Subject<void>();

  constructor(
    private trackingService: TrackingService,
    private store: Store<State>,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private platform: Platform,
    private actionSheetController: ActionSheetController
  ) {
    this.store.pipe(select(fromAccount.selectCustomer), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.language = value.language;
    });
  }

  ngOnInit(): void {
    void this.platform.ready().then(() => {
      this.smallScreen = this.platform.width() <= 320;
    });

    this.route.paramMap.pipe(takeUntil(this.onDestroy$)).subscribe((params) => {
      if (params.has('id')) {
        this.id = Number(params.get('id').valueOf());
        this.store.dispatch(HomeActions.getImpactStoryDetail({ id: this.id }));
      }
    });

    this.store.pipe(select(fromHome.selectImpactStoryDetail), takeUntil(this.onDestroy$)).subscribe((story) => {
      if (!story.article?.attributes?.title) {
        return;
      }

      this.story = story.article.attributes;
      this.campaignExpiresAt = new Date(this.story.expires_at);
      const today = new Date();
      this.campaignExpired = today > this.campaignExpiresAt;
      this.campaignLiked = this.story.liked;
      if (this.campaignLiked === null || this.campaignLiked === undefined) {
        this.campaignEngaged = false;
      } else {
        this.buttonLiked = this.campaignLiked;
        this.campaignEngaged = true;
      }
    });

    this.store.pipe(select(fromHome.selectImpactStoryDetailLoading), takeUntil(this.onDestroy$)).subscribe((loading) => {
      this.loadingStory = loading;
    });

    this.store.pipe(select(fromHome.selectEngagementSuccess), takeUntil(this.onDestroy$)).subscribe((engagement) => {
      this.loadingCampaignLike = false;
      if (!engagement || !engagement.customer_id) {
        return;
      }

      this.campaignEngaged = true;
      this.campaignLiked = engagement.liked;
      this.buttonLiked = this.campaignLiked;
    });

    this.store.pipe(select(fromHome.selectDeleteEngagementStatus), takeUntil(this.onDestroy$)).subscribe((success) => {
      this.loadingCampaignLike = false;
      if (!success) {
        return;
      }

      this.campaignEngaged = false;
      this.campaignLiked = null;
      this.buttonLiked = false;
    });
  }

  engageCampaign(action: boolean): void {
    if (action) {
      this.loadingCampaignLike = true;

      if (this.campaignLiked) {
        this.track('[Button] Engagement: Unlike campaign');
        this.deleteCampaign();
        return;
      }
      this.track('[Button] Engagement: Like campaign');
      this.store.dispatch(HomeActions.sendEngagement({ action, id: this.id, rebalance_portfolio: false }));
    } else {
      void this.openConfirmModal(action);
    }
  }

  deleteCampaign(id = this.id): void {
    this.store.dispatch(HomeActions.deleteEngagement({ id }));
  }

  track(event: string): void {
    this.trackingService.trackActivity(event);
  }

  openArticle(url: string): void {
    this.track(`[Link] Engagement: Learn more about campaign`);
    void Browser.open({
      url
    });
  }

  get showCampaignActions(): boolean {
    if (this.campaignExpired) {
      return false;
    }

    if (this.campaignEngaged) {
      return !this.campaignLiked;
    }

    return true;
  }

  private async openConfirmModal(action: boolean) {
    const actionSheet = await this.actionSheetController.create({
      header: this.translateService.instant('INSIGHTS.impact.engagement.confirmDislike'),
      cssClass: 'signout-actionsheet',
      buttons: [
        {
          text: this.translateService.instant('INSIGHTS.impact.engagement.cta.dislike'),
          role: 'destructive',
          handler: () => {
            this.trackingService.trackActivity('[Button] Engagement: Dislike campaign - Confirmed');
            this.store.dispatch(HomeActions.sendEngagement({ action, id: this.id, rebalance_portfolio: false }));
          }
        },
        {
          text: this.translateService.instant('SHARED.actions.cancel'),
          role: 'cancel',
          handler: () => {
            this.trackingService.trackActivity('[Button] Engagement: Dislike campaign - Dismissed');
          }
        }
      ]
    });
    await actionSheet.present();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.store.dispatch(HomeActions.getImpactStoryDetailSuccess({ data: {} as ImpactStoryDetail }));
  }
}
