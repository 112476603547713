// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.second-tag {
  margin: 0;
  background: var(--ion-color-light);
  font-size: 24px;
  line-height: 35px;
  top: -5px;
  left: 6px;
}
.second-tag.small {
  font-size: 18px;
}

.hero-holder.campaign-update {
  height: 570px;
}
.hero-holder.campaign-update .hero-bg {
  background-image: url(/assets/images/publicis-campaing-update.png);
  background-size: cover;
}
.hero-holder .campaign-explanation {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.005em;
  padding: 24px;
}
.hero-holder .campaign-explanation ion-icon[name=heart] {
  position: static;
  font-size: 160px;
  height: 60px;
  margin: 0 10px 0 0;
}

.campaign-content {
  margin-top: 44px;
}
.campaign-content > div {
  margin-bottom: 88px;
}
.campaign-content > div > * {
  margin-bottom: 10px;
}

.timeline-holder h2.green {
  margin-bottom: 40px;
}
.timeline-holder ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.timeline-holder ul li {
  position: relative;
  margin: 0;
  padding-bottom: 1em;
  padding-left: 20px;
}
.timeline-holder ul li p {
  color: var(--ion-color-success);
  font-size: 16px;
  line-height: 24px;
}
.timeline-holder ul li p.date-label {
  font-weight: 800;
  color: var(--ion-color-light);
}
.timeline-holder ul li:first-child::before {
  top: 5px;
}
.timeline-holder ul li:last-child::before {
  max-height: 10px;
}
.timeline-holder ul li:before {
  content: "";
  background-color: var(--ion-color-success);
  position: absolute;
  bottom: 0px;
  top: 0;
  left: 5px;
  width: 1px;
}
.timeline-holder ul li:after {
  content: "";
  border-radius: 100%;
  position: absolute;
  background-color: var(--ion-color-light);
  left: 0;
  top: 5px;
  height: 11px;
  width: 11px;
}

.pdf-link {
  color: var(--ion-color-success);
}

.support-button {
  background: var(--ion-color-success);
}

.related-article {
  background: var(--ion-color-light);
  padding-bottom: 40px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
