import { HomeOddModalComponent } from '@home/modals/home-odd-modal/home-odd-modal.component';

import { BalanceSheetRowComponent } from './home-balance-sheet-modal/balance-sheet-row/balance-sheet-row.component';
import { HomeBalanceSheetModalComponent } from './home-balance-sheet-modal/home-balance-sheet-modal.component';
import { HomeBannerModalComponent } from './home-banner-modal/home-banner-modal.component';
import { HomeImpactCampaignSupportComponent } from './home-impact-campaign-support/home-impact-campaign-support.component';
import { HomeImpactMetricsInfoComponent } from './home-impact-metrics-info/home-impact-metrics-info.component';
import { HomePillar3aComponent } from './home-pillar3a/home-pillar3a.component';
import { HomeSuperhumanSurveyComponent } from './home-superhuman-survey/home-superhuman-survey.component';

export const components = [
  HomeBannerModalComponent,
  HomeImpactCampaignSupportComponent,
  HomeImpactMetricsInfoComponent,
  HomePillar3aComponent,
  HomeSuperhumanSurveyComponent,
  HomeBalanceSheetModalComponent,
  BalanceSheetRowComponent,
  HomeOddModalComponent
];

export { BalanceSheetRowComponent } from './home-balance-sheet-modal/balance-sheet-row/balance-sheet-row.component';
export { HomeBalanceSheetModalComponent } from './home-balance-sheet-modal/home-balance-sheet-modal.component';
export { HomeBannerModalComponent } from './home-banner-modal/home-banner-modal.component';
export { HomeImpactCampaignSupportComponent } from './home-impact-campaign-support/home-impact-campaign-support.component';
export { HomeImpactMetricsInfoComponent } from './home-impact-metrics-info/home-impact-metrics-info.component';
export { HomePillar3aComponent } from './home-pillar3a/home-pillar3a.component';
export { HomeSuperhumanSurveyComponent } from './home-superhuman-survey/home-superhuman-survey.component';
export { HomeOddModalComponent } from '@home/modals/home-odd-modal/home-odd-modal.component';
