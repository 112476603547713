import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from '@inyova/models';

import { Observable } from 'rxjs';

import { LocalStorageKeys } from '@app/app.constants';
import { environment } from 'src/environments/environment';

interface Token2FA {
  code: number;
  message: string;
  status: string;
}
interface SMSToken2FA {
  code: number;
  message: string;
  status: string;
  status_2fa: string;
}
@Injectable({
  providedIn: 'root'
})
export class TwoFaResources {
  private readonly URL: string;

  constructor(private http: HttpClient) {
    this.URL = `${environment.apiUrl}`;
  }

  send2faToken(): Observable<Token2FA> {
    return this.http.post<Token2FA>(
      `${this.URL}/multi_factor_auth/send_2fa_token?authentication_token=${localStorage.getItem(LocalStorageKeys.AUTHENTICATION_TOKEN)}`,
      {}
    );
  }

  toggle2faSMSAuthorization(otpCode: string): Observable<SMSToken2FA> {
    return this.http.post<SMSToken2FA>(`${this.URL}/multi_factor_auth/toggle_2fa_token`, {
      otp_code_token: otpCode
    });
  }

  verify2faCode(authToken: string, otpCode: string): Observable<{ data: Customer; status: string }> {
    return this.http.post<{ data: Customer; status: string }>(`${this.URL}/multi_factor_auth/verify_2fa_token`, {
      authentication_token: authToken,
      otp_code_token: otpCode
    });
  }
}
