// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize \`line-height\`. Cannot be changed from \`normal\` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable \`input\` types in iOS */
  -webkit-appearance: none;
  /* Remove excess padding and border in Firefox 4+ */
  display: flex;
  padding: var(--spacing-3) var(--spacing-6);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-2);
  border-radius: var(--radius-2);
  font-size: var(--fs-4);
  font-weight: var(--fw-700);
  line-height: var(--flh-4);
  letter-spacing: var(--fls-3);
}
.ds-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.ds-button.size-small {
  padding: var(--spacing-2) var(--spacing-4);
  gap: var(--spacing-1);
  border-radius: var(--radius-1);
  font-size: var(--fs-2);
  font-weight: var(--fw-700);
  line-height: var(--flh-3);
  letter-spacing: var(--fls-5);
}
.ds-button.type-primary {
  outline: var(--border-1) solid var(--color-core-neutral-10);
  background: var(--color-core-neutral-10);
  color: var(--color-core-neutral-0);
}
.ds-button.type-primary:hover {
  outline: var(--border-1) solid var(--color-core-neutral-9);
  background: var(--color-core-neutral-9);
}
.ds-button.type-primary:disabled, .ds-button.type-primary.disabled {
  outline-color: var(--color-core-neutral-5);
  background: var(--color-core-neutral-5);
}
.ds-button.type-outline {
  outline: var(--border-1) solid var(--color-core-neutral-10);
  background: transparent;
  color: var(--color-core-neutral-10);
}
.ds-button.type-outline:hover {
  outline-color: var(--color-core-neutral-9);
  color: var(--color-core-neutral-9);
}
.ds-button.type-outline:disabled, .ds-button.type-outline.disabled {
  outline-color: var(--color-core-neutral-5);
  color: var(--color-core-neutral-5);
}
.ds-button:focus-visible {
  box-shadow: var(--shadow-input-border-selected);
  outline-offset: var(--border-1);
  outline: var(--border-3) solid var(--color-core-brand-2-4);
}
.ds-button.full-width {
  width: 100%;
}
.ds-button:disabled, .ds-button.is-loading {
  pointer-events: none;
  cursor: initial;
}
.ds-button .loading-icon {
  animation: spin var(--duration-extra-slow) infinite linear;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
