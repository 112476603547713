import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-ds-header-stepper',
  templateUrl: './ds-header-stepper.component.html',
  styleUrls: ['./ds-header-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DSHeaderStepperComponent {
  @Input() title: string;
  @Input() totalSteps = 1;
  @Input() currentStep = 1;
  @Input() type = 'info';
  @Input() finalType = 'success';

  getPercentage() {
    return Math.floor((this.currentStep * 100) / this.totalSteps);
  }
}
