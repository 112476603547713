import { Component, Input } from '@angular/core';

export type DSIconSizes = 'icon-size-0' | 'icon-size-1' | 'icon-size-2' | 'icon-size-3' | 'icon-size-4' | 'icon-size-5';
@Component({
  selector: 'app-ds-icon',
  templateUrl: './ds-icon.component.html',
  styleUrls: ['./ds-icon.component.scss']
})
export class DsIconComponent {
  @Input() name: string;
  @Input() color = 'var(--color-core-neutral-10)';
  @Input() size: DSIconSizes = 'icon-size-1';

  iconSizeList = {
    'icon-size-0': {
      size: 12,
      strokeWidth: 1.25
    },
    'icon-size-1': {
      size: 16,
      strokeWidth: 1.25
    },
    'icon-size-2': {
      size: 20,
      strokeWidth: 1.5
    },
    'icon-size-3': {
      size: 24,
      strokeWidth: 2
    },
    'icon-size-4': {
      size: 32,
      strokeWidth: 2.5
    },
    'icon-size-5': {
      size: 36,
      strokeWidth: 2.75
    }
  };
}
