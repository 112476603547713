// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-proof {
  padding-top: 24px;
  text-align: left;
}
.social-proof p {
  font-size: 14px;
  line-height: 20px;
}
.social-proof-graph {
  background-color: var(--ion-color-light);
  padding: 64px 16px 16px;
}
.social-proof-expand {
  text-align: center;
  padding-top: 16px;
}
.social-proof-expand > a {
  font-size: 14px;
  line-height: 20px;
}
.social-proof-details {
  padding-top: 16px;
}
.social-proof-details > h2 {
  text-align: left;
  margin-bottom: 16px;
}
.social-proof-details > p {
  margin-bottom: 16px;
}
.social-proof-details > p:last-child {
  margin-bottom: 0;
}
.social-proof-calculation {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 8px;
}
.social-proof-calculation > p {
  margin-bottom: 0;
  margin-left: 4px;
}

.social-demo {
  background-color: var(--ion-color-blue);
  height: 16px;
  position: relative;
  margin-bottom: 16px;
  width: 100%;
}
.social-demo-knob {
  background-color: var(--ion-color-primary);
  height: 24px;
  position: absolute;
  top: 50%;
  margin-left: -1px;
  margin-top: -12px;
  width: 2px;
}
.social-demo-tooltip {
  background-color: var(--ion-color-white);
  border: 1px solid rgba(47, 44, 38, 0.2);
  border-radius: 18px;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 8px 12px 6px 12px;
  position: absolute;
  transform: translateX(-50%);
  letter-spacing: 0.005em;
  top: -46px;
  white-space: nowrap;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
