// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds-returns {
  --custom-color: var(--color-core-neutral-6);
  display: flex;
  align-items: center;
  color: var(--custom-color);
}
.ds-returns.small {
  font-size: var(--fs-2);
}
.ds-returns.medium {
  font-size: var(--fs-4);
}
.ds-returns.positive {
  color: var(--color-system-success-3);
}
.ds-returns.negative {
  color: var(--color-system-critical-3);
}
.ds-returns.custom {
  color: var(--custom-color);
}
.ds-returns ion-icon {
  font-size: inherit;
  margin-top: -2px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
