import { Component, Input } from '@angular/core';

export type RoundedIconVariants = 'info' | 'success' | 'warning' | 'critical' | 'custom';

@Component({
  selector: 'app-ds-rounded-icon',
  templateUrl: './ds-rounded-icon.component.html',
  styleUrls: ['./ds-rounded-icon.component.scss']
})
export class DsRoundedIconComponent {
  @Input() icon: string;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() variant: RoundedIconVariants;
  @Input() color: 'var(--color-core-neutral-0)' | 'var(--color-core-neutral-10)' = 'var(--color-core-neutral-10)';
  @Input() customColor = '';

  iconSizeList = {
    small: 'icon-size-2',
    medium: 'icon-size-2',
    large: 'icon-size-5'
  };
}
