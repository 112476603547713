// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-range-chart-3a {
  display: block;
  position: relative;
}
app-range-chart-3a svg:not(:root) {
  overflow: visible;
}
app-range-chart-3a path.stacked-line, app-range-chart-3a path.stacked-line-without {
  stroke: none;
}
app-range-chart-3a path.stacked-line.visible {
  stroke: var(--ion-color-chart-good);
}
app-range-chart-3a path.stacked-line-without.visible {
  stroke: var(--ion-color-chart-bad);
}

.stacked-area-chart {
  position: relative;
}

.timeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.005em;
}
.timeline b, .timeline span {
  display: block;
}
.timeline div {
  flex: 1;
}
.timeline div:first-of-type {
  text-align: left;
}
.timeline div:nth-of-type(2) {
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
  text-align: center;
}
.timeline div:last-of-type {
  text-align: right;
}

h3 {
  margin: 12px 0 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.stacked-area-chart__legend {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 12px;
  margin-bottom: 16px;
  text-align: center;
}

#stacked-area-chart-tooltip {
  position: absolute;
  text-align: left;
  top: 0;
  right: 10px;
  width: auto;
  max-width: 220px;
  height: auto;
  padding: 16px;
  border: 1px solid var(--ion-color-primary);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 4px rgba(47, 44, 38, 0.04), 0px 2px 2px rgba(47, 44, 38, 0.08);
  z-index: 1;
}
#stacked-area-chart-tooltip p {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  text-align: left;
}
#stacked-area-chart-tooltip p:first-of-type {
  font-weight: 700;
}
#stacked-area-chart-tooltip:after {
  content: "";
  position: absolute;
  top: calc(50% - 7px);
  right: -16px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid transparent;
  border-left: 8px solid var(--ion-color-primary);
}

.stacked-area-chart__legend-item {
  width: 100%;
  position: relative;
  padding: 12px;
  font-size: 12px;
  line-height: 16px;
  background: rgba(163, 208, 219, 0.2);
  border: 1px solid var(--ion-color-chart-good);
  border-radius: 34px;
  cursor: pointer;
}
.stacked-area-chart__legend-item::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 4px -2px 0;
  border-radius: 100%;
  background: var(--ion-color-chart-good);
}
.stacked-area-chart__legend-item.inactive {
  background: var(--ion-color-white);
  border-color: #d5d5d4;
}

.grid line {
  stroke: lightgrey;
  stroke-dasharray: 5, 10;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.grid path {
  stroke-width: 0;
}

.yaxis-stacked .tick text {
  font-size: 11px;
  color: #6F6B63;
}

.xaxis-stacked {
  color: #706B62;
}
.xaxis-stacked .tick line {
  opacity: 0;
}
.xaxis-stacked path {
  opacity: 0;
}

.yaxis-stacked {
  text-anchor: start;
  background: var(--ion-color-white);
}
.yaxis-stacked .tick line {
  opacity: 0;
}

.caption {
  display: block;
  margin-top: 10px;
  font-size: 12px;
  font-style: italic;
  color: var(--ion-color-tertiary);
}

.no-deposit {
  display: flex;
  align-items: center;
  gap: 16px;
  background: #fef6ea;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  text-align: left;
}
.no-deposit .rectangle {
  width: 12px;
  height: 8px;
  background: #f4a22e;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
