/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { PercentPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';

import { StatusBarStylingService } from '@app/shared/services/status-bar-styling.service';
import { GeneralConfirmModalComponent } from '@shared/modals';
import { EstimatedValues, StackedAreaChartData, StackedAreaChartDataItem } from '@shared/models/Shared';
import { ThousandSeparatorPipe } from '@shared/pipes';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-range-chart-3a',
  styleUrls: ['./range-chart-3a.component.scss'],
  templateUrl: './range-chart-3a.component.html'
})
export class RangeChart3aComponent implements OnChanges {
  @Input() currency: string;
  @Input() data: StackedAreaChartData;
  @Input() estimatedValues: EstimatedValues;
  @Input() expectedPercentages: { loss: number; expected: number; good: number };
  @Input() language: string;
  @Input() retirementYear: number;
  @Input() selectedChip: number;
  @Input() timeToRetirement: number;
  @Output() setChip = new EventEmitter<number>();

  config = {
    height: 400,
    width: window.innerWidth - 52,
    margin: {
      top: 20,
      bottom: 20,
      left: 30,
      right: 0
    }
  };

  tooltipData = {
    expected: 0,
    potential: 0,
    loss: 0
  };

  currentChip = 0;
  currentYear = new Date().getFullYear();

  // D3
  private svg: any;
  private area: any;
  private lines: any;

  constructor(
    private container: ElementRef,
    private modalController: ModalController,
    private percentPipe: PercentPipe,
    private routerOutlet: IonRouterOutlet,
    private trackingService: TrackingService,
    private translateService: TranslateService,
    private thousandSeparatorPipe: ThousandSeparatorPipe,
    private statusBarStyling: StatusBarStylingService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      if (changes.data.firstChange) {
        this.drawChart(changes.data.currentValue as StackedAreaChartData, false);
      } else {
        this.drawChart(changes.data.currentValue as StackedAreaChartData, true);
      }
    }

    if (changes.selectedChip) {
      this.currentChip = changes.selectedChip.currentValue;
    }
  }

  drawChart(data: StackedAreaChartData, update: boolean) {
    // Size
    const height = this.config.height - this.config.margin.top - this.config.margin.bottom;
    const { width } = this.config;
    // Format data
    const series = d3.stack().keys(data.columns.slice(1))(data.data as any);
    // Select main SVG
    this.svg = d3.select(this.container.nativeElement).select('svg').attr('width', width).attr('height', height);

    // Clear graph on update
    if (update) {
      this.svg.select('.grid').remove();
      this.svg.select('.stacked-area-paths').remove();
      this.svg.select('.stacked-area-lines').remove();
      this.svg.select('.yaxis-stacked').remove();
      this.svg.selectAll('path').remove();
    }

    // X
    const x = d3
      .scaleUtc()
      .domain(d3.extent(data.data, (d: StackedAreaChartDataItem) => d.date))
      .range([this.config.margin.left, width - this.config.margin.right]);

    // Y
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(series, (d) => d3.max(d, (d) => d[1]))])
      .nice()
      .range([height - this.config.margin.bottom, this.config.margin.top]);

    // Create line
    this.area = d3
      .area()
      .x((d: any) => x(d.data.date))
      .y0((d) => y(d[0]))
      .y1((d) => y(d[1]));

    // Create line
    const line = d3
      .line()
      .x((d: any) => x(d.data.date))
      .y((d: any) => y(d[1]));

    // Grid
    const grid = (g) =>
      g
        .attr('transform', `translate(30,0)`)
        .attr('class', 'grid')
        .call(
          d3
            .axisLeft(y)
            .ticks(height / 50)
            .tickSize(30 - width)
            .tickFormat(() => ' ')
        );

    // Y axis
    const yAxis = (g) =>
      g
        .attr('transform', `translate(9,0)`)
        .call(
          d3
            .axisLeft(y)
            .ticks(height / 50)
            .tickSizeOuter(0)
            .tickFormat((d) => {
              const format = d3.format('.2s')(d);
              return format.replace('.0', '');
            })
        )
        .call((g) => g.select('.domain').remove());

    // Create grid
    this.svg.append('g').attr('class', 'grid').call(grid);

    // Create lines
    this.lines = this.svg
      .append('g')
      .attr('class', 'stacked-area-lines')
      .selectAll('path')
      .data(series)
      .join('path')
      .attr('fill', 'none')
      .attr('stroke-width', (d, i) => {
        return i === 1 ? 2 : 3;
      })
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('class', (d, i) => {
        if (d.key.includes('Without')) {
          return `stacked-line-without ${i === 1 ? 'visible' : ''}`;
        }

        return `stacked-line ${i === 4 ? 'visible' : ''}`;
      })
      .attr('d', line as any);

    // Create paths
    this.svg
      .append('g')
      .attr('class', 'stacked-area-paths')
      .selectAll('path')
      .data(series)
      .join('path')
      .attr('class', (d, i) => {
        if (d.key.includes('Without')) {
          return `stacked line${i as number} without`;
        }

        return `stacked line${i as number}`;
      })
      .style('fill', (d, i) => {
        if (i === 3 || i === 0) {
          return `transparent`;
        }

        if (d.key.includes('Without')) {
          return `rgba(244, 162, 46, 0.2)`;
        }

        return `rgba(163, 208, 218, 0.2)`;
      })
      .attr('d', this.area);

    // Create y axis
    this.svg.append('g').attr('class', 'yaxis-stacked').call(yAxis);

    // Tooltip
    this.switchSelection(this.currentChip);
  }

  switchSelection(index: number) {
    this.setChip.emit(index);
    this.currentChip = index;
    this.setTooltipData();
  }

  setTooltipData() {
    const tooltip = d3.select('#stacked-area-chart-tooltip');
    tooltip.style('transform', `translateY(-50%) translateY(${this.getYCoordinates()}px)`);

    this.tooltipData.loss = this.estimatedValues.bad;
    this.tooltipData.expected = this.estimatedValues.expected;
    this.tooltipData.potential = this.estimatedValues.good;
  }

  getYCoordinates(): number {
    const path = this.lines.filter((d, i) => i === 4).node();
    const endCoords = path.getPointAtLength(path.getTotalLength());
    return endCoords.y;
  }

  track(activity: string): void {
    this.trackingService.trackActivity(activity);
  }

  async openExpectedExplanation() {
    const difference = this.thousandSeparatorPipe.transform(this.difference.toFixed(0), this.currency);
    const loss = this.percentPipe.transform(this.expectedPercentages.loss, '1.0-1');
    const expected = this.percentPipe.transform(this.expectedPercentages.expected, '1.0-1');
    const gain = this.percentPipe.transform(this.expectedPercentages.good, '1.0-1');

    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: GeneralConfirmModalComponent,
      componentProps: {
        title: this.translateService.instant('sp.revamp.assetGraph.legend.title'),
        body: this.translateService.instant('sp.revamp.3a.legend.summary', { years: this.timeToRetirement, difference }),
        body2: this.translateService.instant('sp.revamp.assetGraph.legend.probability', { loss, expected, gain }),
        note: this.translateService.instant('sp.revamp.assetGraph.legend.model')
      },
      cssClass: 'app-fullscreen',
      presentingElement: this.routerOutlet.nativeEl
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('base');
    });
    return modal.present();
  }

  get difference() {
    return Math.abs(this.estimatedValues.expected - this.estimatedValues.expectedWithout);
  }
}
