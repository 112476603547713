import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'app-otp-code',
  styleUrls: ['./otp-code.component.scss'],
  templateUrl: './otp-code.component.html'
})
export class OtpCodeComponent implements AfterViewInit, OnChanges {
  codeModel = '';
  @Input() code: string;
  @Output() update = new EventEmitter<string>();
  @ViewChild('verifyCodeInput', { static: false }) inputElement: ElementRef;

  ngAfterViewInit() {
    setTimeout(() => {
      Keyboard.show().finally(() => {
        if (this.inputElement) {
          this.inputElement.nativeElement.focus();
        }
      });
    }, 2000);
  }

  // Reset otp code input
  ngOnChanges(changes: SimpleChanges) {
    if (changes.code && this.inputElement && this.inputElement.nativeElement) {
      this.inputElement.nativeElement.value = '';
      this.codeModel = '';
    }
  }

  inputUpdate(event: string) {
    if (event.length >= 6) {
      this.inputElement.nativeElement.value = event.slice(0, 6);
    }
    this.update.emit(`${this.codeModel}`);
  }
}
