import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LUCIDE_ICONS, LucideAngularModule, LucideIconProvider, icons } from 'lucide-angular';

import * as fromComponents from './components';
import { customIcons } from './custom-icons';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IonicModule, TranslateModule, LucideAngularModule.pick(icons)],
  declarations: [...fromComponents.components],
  exports: [...fromComponents.components],
  providers: [{ provide: LUCIDE_ICONS, multi: true, useValue: new LucideIconProvider(customIcons) }]
})
export class DesignSystemModule {}
