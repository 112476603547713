import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-ds-content-switcher',
  styleUrls: ['./ds-content-switcher.component.scss'],
  templateUrl: './ds-content-switcher.component.html'
})
export class DSContentSwitcherComponent implements OnChanges {
  @Input() activeTab: number | string = 1;
  @Input() highlightTab: number | string;
  @Input() size: 'fit' | 'full';
  // TODO: Remove translation, translate pipe dependencies
  @Input() tabs: { id: number | string; value: string }[];
  @Input() type: 'small' | 'medium';
  @Output() tabSwitched = new EventEmitter<number | string>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.activeTab) {
      this.activeTab = changes.activeTab.currentValue;
    }
  }

  changeTab(tabId: number | string): void {
    this.tabSwitched.emit(tabId);
  }
}
