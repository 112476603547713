import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PublicEffects } from './public.effects';
import * as fromPublic from './public.reducers';

import { SharedModule } from '@shared/shared.module';

import * as fromComponents from './components';
import * as fromModals from './modals';
import { PublicRoutingModule } from './public-routing.module';

@NgModule({
  exports: [fromComponents.OfflineComponent, fromComponents.ForceUpdateComponent],
  declarations: [...fromComponents.components, fromModals.ResetPasswordComponent, fromModals.SignupRedirectComponent],
  imports: [
    CommonModule,
    EffectsModule.forFeature([PublicEffects]),
    IonicModule,
    PublicRoutingModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    StoreModule.forFeature(fromPublic.PUBLIC_REDUCER_FEATURE_KEY, fromPublic.reducer),
    TranslateModule
  ]
})
export class PublicModule {}
