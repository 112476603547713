// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
}

p {
  margin: 12px 0;
  font-size: 14px;
}

.comparison-note {
  padding-right: 25px;
}

.impact-item {
  position: relative;
  margin-bottom: 48px;
}
.impact-item .flex {
  display: flex;
  align-items: center;
}
.impact-item:nth-child(even) .flex {
  flex-direction: row-reverse;
}
.impact-item:nth-child(even) .flex .impact-item__right img {
  right: initial;
  left: -15px;
}
.impact-item ion-icon {
  position: absolute;
  right: 5px;
  bottom: 0px;
  font-size: 20px;
  color: #d6d1c6;
}
.impact-item__left {
  flex: 2;
}
.impact-item__left .title {
  font-family: var(--font-secondary);
  font-size: 42px;
  line-height: 50px;
  font-weight: 300;
}
.impact-item__left .title span {
  font-size: 16px;
  line-height: 24px;
}
.impact-item__left p.note {
  color: var(--ion-color-new-grey);
  font-size: 12px;
  font-weight: 400;
  margin: 15px 0 0 0;
}
.impact-item__right img {
  height: 180px;
  position: relative;
  right: -15px;
  width: 114px;
}
.impact-item__right img.forest_owned {
  right: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
