// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-top: var(--ion-safe-area-top);
}
ion-content.odd-modal {
  --padding-top: calc(var(--ion-safe-area-top) + 12px);
}

app-home-campaign-banner:first-child {
  margin-top: var(--spacing-8);
  display: block;
}

.home-notifications {
  padding: 0;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 22px;
}

.home-accounts {
  margin-top: 25px;
  padding-bottom: 10px;
}

h2.title {
  font-family: var(--font-secondary);
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.impact-stories__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.impact-stories__header h2 {
  margin: 0;
}

.impact-stories__header-filter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.impact-stories__header-filter ion-icon {
  font-size: 18px;
  margin-right: 8px;
}
.impact-stories__header-filter span {
  line-height: 1;
  font-size: 14px;
  font-weight: bold;
  margin-top: 3px;
}
.impact-stories__header-filter--active ion-icon {
  color: var(--ion-color-success);
}
.impact-stories__header-filter--active span {
  color: var(--ion-color-success);
}

.home-impact-wrapper h2 {
  margin-bottom: 40px;
}

.home-impact-items {
  display: flex;
  margin-bottom: 60px;
}

.home-impact-item {
  display: flex;
  position: relative;
  flex: 1;
  align-items: flex-end;
  height: 95px;
}
.home-impact-item.items-3 span.co2_saved {
  display: block;
  width: 70px;
}
.home-impact-item img {
  position: absolute;
  height: inherit;
  width: auto;
}
.home-impact-item img.renewable_energy_produced {
  left: -20px;
  height: 145px;
}
.home-impact-item img.forest_owned {
  left: 10px;
  height: 94px;
}
.home-impact-item img.forest_owned + div {
  margin-left: 50px;
}
.home-impact-item div {
  position: relative;
  margin-left: 30px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--ion-color-new-grey);
  background: var(--background);
  z-index: 2;
}
.home-impact-item div strong {
  white-space: nowrap;
  font-weight: 700;
  font-size: 18px;
  line-height: 29px;
  color: #2f2c26;
}
@media screen and (max-width: 400px) {
  .home-impact-item div strong {
    font-size: 16px;
    line-height: 1.4;
  }
}
@media screen and (max-width: 360px) {
  .home-impact-item div strong {
    font-size: 14px;
  }
}
.home-impact-item div strong span {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

ion-skeleton-text {
  height: 20px;
  margin-bottom: 12px;
}
ion-skeleton-text.image-holder {
  height: 200px;
}

.banner-3a {
  height: 80px;
  display: flex;
  align-items: center;
  background: var(--color-core-brand-2-2);
  border-radius: var(--radius-2);
  padding: var(--spacing-2);
  margin-bottom: var(--spacing-4);
  background-image: url("/assets/images/3a-tree.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
}
.banner-3a h2 {
  margin: 0;
  padding-left: var(--spacing-4);
  max-width: 70%;
}
.banner-3a:first-child {
  margin-top: var(--spacing-8);
}

.grow-banner {
  align-items: center;
  gap: var(--spacing-2);
}
.grow-banner h2 {
  margin: 0 0 var(--spacing-1) 0;
}
.grow-banner .campaign-info {
  flex-grow: 1;
}
.grow-banner ion-icon {
  font-size: var(--icon-size-3);
}

.grow-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 52px;
}
.grow-info-row .info-row-amount {
  font-size: var(--fs-4);
  line-height: var(--flh-5);
  font-weight: var(--fw-800);
}
.grow-info-row .info-row-amount ion-skeleton-text {
  display: block;
  border-radius: 4px;
  height: 16px;
  width: 80px;
}
@media only screen and (min-width: 380px) {
  .grow-info-row .info-row-amount {
    font-size: var(--fs-5);
  }
}
.grow-info-row .info-row-amount span {
  font-size: var(--fs-2);
  font-weight: var(--fw-500);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
