import { Component, HostBinding, Input } from '@angular/core';

export type LinkVariants = 'default' | 'neutral' | 'critical';

@Component({
  selector: 'app-ds-link',
  templateUrl: './ds-link.component.html',
  styleUrls: ['./ds-link.component.scss']
})
export class DsLinkComponent {
  @Input() href = 'javascript:;';
  @Input() target = '_self';
  @Input() icon: string;
  @Input() variant: LinkVariants = 'default';

  @HostBinding('class') get classes(): string {
    return `ds-link variant-${this.variant} ${this.icon ? 'with-icon' : ''}`;
  }

  iconColorList = {
    default: 'var(--color-core-neutral-10)',
    neutral: 'var(--color-core-neutral-7)',
    critical: 'var(--color-system-critical-2)'
  };
}
