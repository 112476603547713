import { CommonModule, PercentPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DesignSystemModule } from '@inyova/design-system';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import * as fromComponents from './components';
import * as fromDirectives from './directives';
import * as fromModals from './modals';
import * as fromPipes from './pipes';

@NgModule({
  imports: [CommonModule, FormsModule, HttpClientModule, IonicModule, ReactiveFormsModule, TranslateModule, DesignSystemModule],
  declarations: [...fromComponents.components, ...fromModals.components, ...fromPipes.pipes, ...fromDirectives.directives],
  providers: [PercentPipe, fromPipes.ThousandSeparatorPipe],
  exports: [...fromComponents.components, ...fromModals.components, ...fromPipes.pipes, ...fromDirectives.directives, DesignSystemModule]
})
export class SharedModule {}
