// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-top: var(--ion-safe-area-top);
  --background: transparent;
  text-align: center;
}

img {
  margin-top: 15px;
  width: 142px;
}

h1 {
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: 30px;
  text-align: left;
  margin: 0;
  margin-bottom: 15px;
}

p {
  text-align: left;
  margin-bottom: 30px;
}

ion-button {
  margin-bottom: 10px;
}

.onboarding--center {
  left: 30px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
