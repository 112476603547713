import { AssetChart3AComponent } from './asset-chart/asset-chart-3a.component';
import { AssetChartComponent } from './asset-chart/asset-chart.component';
import { AssetDepositComponent } from './asset-deposit/asset-deposit.component';
import { AssetSocialProofComponent } from './asset-social-proof/asset-social-proof.component';
import { OtpCodeComponent } from './otp-code/otp-code.component';
import { RangeChartComponent } from './range-chart/range-chart.component';
import { RangeChart3aComponent } from './range-chart-3a/range-chart-3a.component';
import { StackedAreaChartComponent } from './stacked-area-chart/stacked-area-chart.component';
import { SwitchAccountComponent } from './switch-account/switch-account.component';
import { TooltipComponent } from './tooltip/tooltip.component';

export const components = [
  AssetChartComponent,
  AssetChart3AComponent,
  AssetDepositComponent,
  AssetSocialProofComponent,
  OtpCodeComponent,
  RangeChartComponent,
  RangeChart3aComponent,
  StackedAreaChartComponent,
  SwitchAccountComponent,
  TooltipComponent
];

export { AssetChart3AComponent } from './asset-chart/asset-chart-3a.component';
export { AssetChartComponent } from './asset-chart/asset-chart.component';
export { AssetDepositComponent } from './asset-deposit/asset-deposit.component';
export { AssetSocialProofComponent } from './asset-social-proof/asset-social-proof.component';
export { OtpCodeComponent } from './otp-code/otp-code.component';
export { RangeChart3aComponent } from './range-chart-3a/range-chart-3a.component';
export { RangeChartComponent } from './range-chart/range-chart.component';
export { StackedAreaChartComponent } from './stacked-area-chart/stacked-area-chart.component';
export { SwitchAccountComponent } from './switch-account/switch-account.component';
export { TooltipComponent } from './tooltip/tooltip.component';
