import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Subject } from 'rxjs';

import { CompanyDetailDataTopic } from '@shared/models/Strategy';

@Component({
  selector: 'app-company-topic',
  templateUrl: './strategy-company-topic.component.html',
  styleUrls: ['./strategy-company-topic.component.scss']
})
export class CompanyTopicComponent {
  @Input() companyTopic: CompanyDetailDataTopic;
  protected readonly onDestroy$ = new Subject<void>();

  constructor(private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
