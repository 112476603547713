// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds-chip {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: var(--spacing-2) var(--spacing-4);
  border: var(--border-1) solid var(--color-core-neutral-4);
  border-radius: var(--radius-2);
  color: var(--color-core-neutral-6);
  white-space: nowrap;
}
.ds-chip.active {
  background-color: var(--color-core-neutral-0);
  color: var(--color-core-neutral-10);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
