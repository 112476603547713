import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CompaniesStockPerformance } from '@app/shared/models/Home';

@Component({
  selector: 'app-stock-performance-box',
  templateUrl: './stock-performance-box.component.html',
  styleUrls: ['./stock-performance-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StockPerformanceBoxComponent {
  @Input() item!: CompaniesStockPerformance;
  @Input() currency!: string;
  @Input() first: boolean;
  @Input() last: boolean;
  @Output() dispatchCompanyDetail = new EventEmitter<{ companyID: number; title: string }>();

  showCompanyDetail(): void {
    this.dispatchCompanyDetail.emit({ companyID: this.item.attributes.company_id, title: this.item.attributes.title });
  }
}
