import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Share } from '@capacitor/share';
import { TranslateService } from '@ngx-translate/core';

import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as HomeActions from '@home/home.actions';
import * as fromAccount from '@account/account.reducers';
import * as fromHome from '@home/reducers';

import { ImpactStoriesItem, ImpactStoryDetail } from '@shared/models/Home';
import { LanguageService } from '@shared/services/language.service';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-home-impact-campaign-update',
  templateUrl: './home-impact-campaign-update.component.html',
  styleUrls: ['./home-impact-campaign-update.component.scss']
})
export class HomeImpactCampaignUpdateComponent implements OnInit, OnDestroy {
  protected readonly onDestroy$ = new Subject<void>();
  loading = false;
  campaignDetail!: ImpactStoryDetail['attributes'];
  publicisCampaign: ImpactStoriesItem;
  language = 'ch';
  id: number;
  code: string;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    private trackingService: TrackingService,
    private translateService: TranslateService
  ) {
    this.languageService.selected.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.language = value;
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      if (params.has('id')) {
        this.id = Number(params.get('id').valueOf());
        this.store.dispatch(HomeActions.getImpactStoryDetail({ id: this.id }));
      }
    });

    this.store.pipe(select(fromAccount.selectCustomer), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.code = value.referral_code;
    });

    this.store.pipe(select(fromHome.selectImpactStoryDetail), takeUntil(this.onDestroy$)).subscribe((story) => {
      if (!story.article?.attributes?.title) {
        return;
      }

      this.campaignDetail = story.article.attributes;
    });

    const publicisCampaign$ = this.store.select(fromHome.selectPublicisCampaign);
    const detailLoading$ = this.store.select(fromHome.selectImpactStoryDetailLoading);

    combineLatest([publicisCampaign$, detailLoading$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([campaign, loading]) => {
        if (!campaign) {
          // if push notification clicked, trigger load stroies
          this.store.dispatch(HomeActions.getImpactStories());
          return;
        }
        this.publicisCampaign = campaign;
        this.loading = loading;
      });
  }

  async shareCampaign() {
    this.trackingService.trackActivity(`[Button] Campaign Update: Invite friend`);
    await Share.share({
      text: this.translateService.instant('IMPACT.publicisCampaign.share.finished', { code: this.code })
    });
  }

  openDetail(id: number): void {
    this.trackingService.trackActivity(`Campaing Update - Open Publicis campaign`);
    void this.router.navigate([`/tabs/home/campaign/${id}`]);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
