// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.campaign-banner {
  display: flex;
  align-items: center;
  background: var(--color-core-neutral-0);
  box-shadow: var(--shadow-elavated);
  border-radius: var(--radius-2);
  padding: var(--spacing-4);
  margin-bottom: var(--spacing-4);
  gap: var(--spacing-2);
}
.campaign-banner h2 {
  margin: 0 0 var(--spacing-1) 0;
}
.campaign-banner .campaign-info {
  flex-grow: 1;
}
.campaign-banner ion-icon {
  font-size: var(--icon-size-3);
}
.campaign-banner app-remaining-time-label {
  margin-right: var(--spacing-1);
  display: inline-block;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
