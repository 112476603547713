import { Component } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';
import { Storage } from '@ionic/storage-angular';

import { Store } from '@ngrx/store';
import * as AccountActions from '@account/account.actions';

import { IonicStorageKeys, LocalStorageKeys } from '@app/app.constants';
import { LoginType } from '@public/public.constants';
import { State } from '@shared/models/State';
import { TrackingService } from '@shared/services/tracking.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboadingComponent {
  appURL = environment.customersApp.ch;

  constructor(
    private storage: Storage,
    private store: Store<State>,
    private trackingService: TrackingService
  ) {
    void this.storage.get(IonicStorageKeys.LANGUAGE).then((val) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.appURL = val.includes('-DE') ? environment.customersApp.de : environment.customersApp.ch;
    });
  }

  ionViewDidEnter(): void {
    void Keyboard.hide();
  }

  goToStrategy(): void {
    this.trackingService.trackActivity('[Button] Open web-app');
    const url = `${this.appURL}/?customer_token=${localStorage.getItem(LocalStorageKeys.AUTHENTICATION_TOKEN)}&redirect=strategy`;
    window.open(url, '_system');
    void this.storage.get(IonicStorageKeys.LOGIN_TYPE).then((value) => {
      this.store.dispatch(AccountActions.customerLogoutAndUnpairDevice({ isGoogleCustomer: value === LoginType.GOOGLE }));
    });
  }
}
