import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iban',
  pure: true
})
export class IbanPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return `${value}`;
    }
    return value
      .split(' ')
      .join('')
      .replace(/(.{4})/g, '$1 ');
  }
}
