// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-flex;
}

.ds-badge {
  display: flex;
  align-items: center;
  height: 16px;
  padding: 0 var(--spacing-1);
  border-radius: var(--radius-1);
  color: var(--color-core-neutral-10);
  text-transform: uppercase;
  white-space: nowrap;
}
.ds-badge.primary {
  background: var(--color-core-brand-1-5);
  color: var(--color-core-neutral-0);
}
.ds-badge.secondary {
  background: var(--color-core-brand-2-3);
}
.ds-badge.neutral {
  background: var(--color-core-neutral-3);
}
.ds-badge.info {
  background: var(--color-system-info-1);
}
.ds-badge.warning {
  background: var(--color-system-warning-1);
}
.ds-badge.critical {
  background: var(--color-system-critical-1);
}
.ds-badge.success {
  background: var(--color-system-success-1);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
