import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as Sentry from '@sentry/angular-ivy';
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';

import { BehaviorSubject } from 'rxjs';

import { IonicStorageKeys } from '@app/app.constants';

export enum RemoteCongifKeys {
  DEVELOPMENT_LATEST_REQUIRED_APP_VERSION = 'developmentLatestRequiredAppVersion',
  DEVELOPMENT_MAINTENANCE = 'developmentMaintenance',
  MAINTENANCE = 'maintenance',
  PRODUCTION_LATEST_REQUIRED_APP_VERSION = 'productionLatestRequiredAppVersion',
  PRODUCTION_MAINTENANCE = 'productionMaintenance'
}

export interface RemoteConfig {
  developmentLatestRequiredAppVersion: string;
  developmentMaintenance: boolean;
  maintenance: boolean;
  productionLatestRequiredAppVersion: string;
  productionMaintenance: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {
  private readonly TIME_LIMIT_FOR_NEXT_FETCH = 10;
  private readonly defaultConfig = {
    developmentLatestRequiredAppVersion: '6.0.0',
    developmentMaintenance: false,
    maintenance: false,
    productionLatestRequiredAppVersion: '6.0.0',
    productionMaintenance: false
  };

  private retryAttemptsLeft = 2;

  private remoteConfigWorker = getRemoteConfig();
  remoteConfig = new BehaviorSubject<RemoteConfig>(this.defaultConfig);

  constructor(private storage: Storage) {
    this.remoteConfigWorker.defaultConfig = this.defaultConfig;
    this.remoteConfigWorker.settings.minimumFetchIntervalMillis = 550000;
  }

  /**
   * Firebase remote config has quotes which cannot be excedeed,
   * otherwise the customer would recieve incorrect data.
   * That's why we need to cache those values on FE and do the request
   * only with 10 minutes time window (TIME_LIMIT_FOR_NEXT_FETCH).
   */
  fetchData() {
    this.storage
      .get(IonicStorageKeys.REMOTE_CONFIG_NEXT_FETCH)
      .then((value: Date) => {
        // If config is not fetched or 10 minutes interval is over
        if (!value || new Date(value) < new Date()) {
          fetchAndActivate(this.remoteConfigWorker)
            .then(() => {
              this.remoteConfig.next({
                developmentLatestRequiredAppVersion: getValue(
                  this.remoteConfigWorker,
                  RemoteCongifKeys.DEVELOPMENT_LATEST_REQUIRED_APP_VERSION
                ).asString(),
                developmentMaintenance: getValue(this.remoteConfigWorker, RemoteCongifKeys.DEVELOPMENT_MAINTENANCE).asBoolean(),
                maintenance: getValue(this.remoteConfigWorker, RemoteCongifKeys.MAINTENANCE).asBoolean(),
                productionLatestRequiredAppVersion: getValue(
                  this.remoteConfigWorker,
                  RemoteCongifKeys.PRODUCTION_LATEST_REQUIRED_APP_VERSION
                ).asString(),
                productionMaintenance: getValue(this.remoteConfigWorker, RemoteCongifKeys.PRODUCTION_MAINTENANCE).asBoolean()
              });
              const dateFetched = new Date();
              const dateFetchedPlusFetchLimit = dateFetched.setMinutes(dateFetched.getMinutes() + this.TIME_LIMIT_FOR_NEXT_FETCH);
              void this.storage.set(IonicStorageKeys.REMOTE_CONFIG_NEXT_FETCH, dateFetchedPlusFetchLimit);
              void this.storage.set(IonicStorageKeys.REMOTE_CONFIG_LAST_VALUE, this.remoteConfig.getValue());
            })
            .catch((err) => {
              if (this.retryAttemptsLeft > 0) {
                this.retryAttemptsLeft = this.retryAttemptsLeft - 1;
                this.fetchData();
              } else {
                Sentry.captureException(new Error('Remote config couldn`t be loaded.'), { extra: { ...err } });
              }
            });
          // If config was already fetched & still in 10 minutes interval (TIME_LIMIT_FOR_NEXT_FETCH) - return cached data
        } else {
          void this.storage.get(IonicStorageKeys.REMOTE_CONFIG_LAST_VALUE).then((remoteConfigValue: RemoteConfig) => {
            this.remoteConfig.next(remoteConfigValue);
          });
        }
      })
      .catch(() => Sentry.captureMessage('Ionic storage couldn`t be loaded.'));
  }
}
