/* eslint-disable no-param-reassign */
import { ESTIMATE_CONSTANTS_CH, ESTIMATE_CONSTANTS_DE } from '../../../app.constants';

export function getExpectedValues(appLocation: string, stockSplit: number) {
  const estimateConstants = appLocation === 'ch' ? ESTIMATE_CONSTANTS_CH : ESTIMATE_CONSTANTS_DE;
  const { stocksReturn, bondsReturn, confidenceInterval, stocksVol, bondsVol, correlation } = estimateConstants;
  const cashReturn = 0;
  const cashRatio = 0.02;
  const actualStockSplit = stockSplit * (1 - cashRatio);
  const actualBondSplit = 1 - actualStockSplit - cashRatio;

  const expectedReturnRatio = actualStockSplit * stocksReturn + actualBondSplit * bondsReturn + cashRatio * cashReturn;

  const expectedSTDDevRatio = Math.sqrt(
    actualStockSplit ** 2 * stocksVol ** 2 +
      actualBondSplit ** 2 * bondsVol ** 2 +
      cashRatio ** 2 * cashReturn ** 2 +
      2 * actualStockSplit * actualBondSplit * stocksVol * bondsVol * correlation ** 2
  );

  const expectedSTDDevRatioFinal = Math.sqrt(Math.log(1 + expectedSTDDevRatio ** 2 / (1 + expectedReturnRatio) ** 2));
  const expectedReturnRatioFinal = Math.log(1 + expectedReturnRatio) - expectedSTDDevRatioFinal ** 2 / 2;
  const expectedMonthlyReturn = Math.exp(expectedReturnRatioFinal / 12 + (expectedSTDDevRatioFinal / Math.sqrt(12)) ** 2 / 2) - 1;

  const expectedPotentialRatio = Math.exp(expectedReturnRatioFinal + confidenceInterval * expectedSTDDevRatioFinal) - 1;
  const expectedLossRatio = Math.exp(expectedReturnRatioFinal - confidenceInterval * expectedSTDDevRatioFinal) - 1;

  const chartCalculationValues = {
    expectedReturnRatio,
    expectedPotentialRatio,
    expectedLossRatio,
    expectedReturnRatioFinal,
    expectedSTDDevRatioFinal,
    expectedMonthlyReturn,
    confidenceInterval
  };

  return chartCalculationValues;
}

export function getAggregatedValues(
  i: number,
  expectedReturnRatioFinal: number,
  expectedSTDDevRatioFinal: number,
  confidenceInterval: number,
  expectedMonthlyReturn: number,
  NAV: number,
  NAVWithout: number,
  monthly: number
) {
  const fees = 0;
  const aggregatedMonthlyReturn = Math.exp((expectedReturnRatioFinal / 12 + (expectedSTDDevRatioFinal / Math.sqrt(12)) ** 2 / 2) * i) * 1;
  const returnInPeriod = parseFloat((NAV * expectedMonthlyReturn * 1).toFixed(2));
  const returnInPeriodWithout = parseFloat((NAVWithout * expectedMonthlyReturn * 1).toFixed(2));
  const NAVbeforeFees = NAV + parseFloat(returnInPeriod.toFixed(2));
  const NAVbeforeFeesWithout = NAVWithout + parseFloat(returnInPeriodWithout.toFixed(2));
  const NAVafterFees = NAVbeforeFees + fees;
  const NAVafterFeesWithout = NAVbeforeFeesWithout + fees;

  NAV += monthly + returnInPeriod;
  NAVWithout += returnInPeriodWithout;

  const worstCaseReturn = Math.exp(
    i * (expectedReturnRatioFinal / 12 - (confidenceInterval * expectedSTDDevRatioFinal) / Math.sqrt(12 * i))
  );
  const WCNav = (NAVafterFees / aggregatedMonthlyReturn) * worstCaseReturn;
  const WCNavWithout = (NAVafterFeesWithout / aggregatedMonthlyReturn) * worstCaseReturn;

  const bestCaseReturn = Math.exp(expectedReturnRatioFinal / 12 + (confidenceInterval * expectedSTDDevRatioFinal) / Math.sqrt(12 * i)) ** i;

  const BCNav = (NAVafterFees / aggregatedMonthlyReturn) * bestCaseReturn;
  const BCNavWithout = (NAVafterFeesWithout / aggregatedMonthlyReturn) * bestCaseReturn;

  const aggregatedValues = { WCNav, WCNavWithout, NAV, NAVWithout, BCNav, BCNavWithout };
  return aggregatedValues;
}
