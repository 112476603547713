// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-general-confirm-modal ion-content {
  --padding-start: 30px;
  --padding-end: 30px;
  text-align: center;
}
app-general-confirm-modal ion-label {
  display: block;
}
app-general-confirm-modal ion-label.title {
  font-family: var(--font-secondary);
  font-size: 30px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  font-weight: 700;
  margin-bottom: 30px;
}
app-general-confirm-modal p {
  font-size: 14px;
  text-align: left;
  margin-bottom: 24px;
}
app-general-confirm-modal p.warning {
  background-color: var(--ion-color-error);
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 4px;
}
app-general-confirm-modal p p {
  margin-bottom: 24px;
}
app-general-confirm-modal p h2 {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 8px;
}
app-general-confirm-modal p:last-of-type {
  margin-bottom: 50px;
}
app-general-confirm-modal p.note {
  color: var(--ion-color-tertiary);
}
app-general-confirm-modal .button-cancel {
  margin-top: 15px;
}
app-general-confirm-modal h4 {
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  margin-bottom: 8px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
