// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  display: block;
}
ion-label.title {
  font-family: var(--font-secondary);
  font-size: 30px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

ion-content {
  --padding-start: 16px;
  --padding-end: 16px;
}
ion-content ion-button {
  font-weight: 800;
  font-size: 14px;
}

.banner-header {
  display: flex;
  align-items: center;
}
.banner-header__content {
  max-width: 195px;
}
.banner-header__content h2,
.banner-header__content h3 {
  font-family: var(--font-secondary);
  font-size: 28px;
  line-height: 36px;
  margin: 0;
  font-weight: 500;
}
.banner-header__content h3 {
  font-weight: 100;
}
.banner-header__image {
  min-width: 160px;
  max-width: 160px;
}
.banner-header__image img {
  width: 100%;
}

.banner-content ul {
  margin: 40px 0;
  padding: 0;
  list-style: none;
}
.banner-content ul li {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
}
.banner-content ul li:before {
  content: "";
  background-image: url("/assets/icon/check.svg");
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  position: relative;
  top: 3px;
}
.banner-content a {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  display: block;
  margin: 20px 0 0 0;
  color: var(--ion-color-new-grey);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
