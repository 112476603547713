import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-assets-success-modal',
  templateUrl: './assets-success-modal.component.html',
  styleUrls: ['./assets-success-modal.component.scss']
})
export class AssetsSuccessModalComponent {
  @Input() remindAt: undefined | null | string;
  @Input() isWisher: boolean;
  @Input() is3a: boolean;
  constructor(private modalController: ModalController) {}

  onAccountDetails() {
    void this.modalController.dismiss({ next: 'account' });
  }

  onOpenReminder() {
    void this.modalController.dismiss({ next: 'reminder' });
  }

  onDismiss() {
    void this.modalController.dismiss({ next: undefined });
  }
}
