import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTranslate',
  pure: true
})
export class DateTranslatePipe implements PipeTransform {
  transform(value: string | Date, type: string, language: string): string {
    const base: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };
    let options: Intl.DateTimeFormatOptions = base;
    switch (type) {
      case 'longMonth':
        options = { ...base, day: 'numeric' };
        break;

      case 'shortMonth':
        options = { ...base, month: 'short', day: 'numeric' };
        break;
      case 'longDays':
        options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
        break;
      default:
        options = base;
        break;
    }
    return new Date(value).toLocaleDateString(language, options);
  }
}
