import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

import { select, Store } from '@ngrx/store';
import * as fromAccount from '@account/account.reducers';

import { LocalStorageKeys } from '@app/app.constants';
import { environment } from 'src/environments/environment';

import { TrackingDetails } from '../models/Account';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private currentAccountID: string;
  private currentLocation: string;
  private isCIActive: boolean;
  private deviceInfo: AppInfo;

  constructor(
    private http: HttpClient,
    private store: Store
  ) {
    const localStorageValue = localStorage.getItem(LocalStorageKeys.CURRENT_ACCOUNT_ID);
    this.store.pipe(select(fromAccount.selectCurrentAccount)).subscribe((value) => {
      this.currentAccountID = !value.id && localStorageValue ? localStorageValue : value.id;
    });
    this.store.pipe(select(fromAccount.selectCrowdInvestorAccountActive)).subscribe((value) => {
      this.isCIActive = value;
    });
    if (Capacitor.isNativePlatform()) {
      void App.getInfo().then((deviceInfo) => {
        this.deviceInfo = deviceInfo;
      });
    }
  }

  trackActivity(activity: string) {
    if (!environment.production) {
      console.log(`[Lead activity] Activity clicked: ${activity}`);
    }
    this.http.post(`${environment.apiUrl}/journey/action`, this.getTrackingDetails(`Activity clicked: ${activity}`)).subscribe();
  }

  trackView(pageView: string) {
    this.currentLocation = pageView;
    if (!environment.production) {
      console.log(`[Lead activity] Page visited: ${pageView}`);
    }
    this.http.post(`${environment.apiUrl}/journey/action`, this.getTrackingDetails(`Page visited: ${pageView}`)).subscribe();
  }

  trackLaunch(activity: string) {
    if (!environment.production) {
      console.log(`[Lead activity] Activity clicked: ${activity}`);
    }
    this.http.post(`${environment.apiUrl}/journey/action`, this.getTrackingDetails(`Launched: ${activity}`)).subscribe();
  }

  trackAppVersion() {
    const activity = `Version ${this.deviceInfo?.version} - ${this.deviceInfo?.build}`;
    this.http.post(`${environment.apiUrl}/journey/action`, this.getTrackingDetails(activity)).subscribe();
  }

  getTrackingDetails(content: string): TrackingDetails {
    const trackingDetails = {
      location: this.currentLocation,
      platform: 'mobile-app'
    };
    if (this.isCIActive || this.currentAccountID === '') {
      return { content: `CI: ${content}`, ...trackingDetails };
    }
    return { content, ...trackingDetails, account_id: this.currentAccountID };
  }
}
