// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds-tab-nav {
  display: flex;
  gap: var(--spacing-1);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.ds-tab-nav .tab-element {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: var(--spacing-10);
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--radius-1);
  color: var(--color-core-neutral-6);
}
.ds-tab-nav .tab-element.active {
  background-color: var(--color-core-neutral-8);
  color: var(--color-core-neutral-0);
}
.ds-tab-nav.fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
