import { Component } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { ActionSheetController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Languages } from '@app/app.constants';
import { LanguageService } from '@shared/services/language.service';

@Component({
  selector: 'app-force-update',
  templateUrl: './force-update.component.html',
  styleUrls: ['./force-update.component.scss']
})
export class ForceUpdateComponent {
  showCacheNote = false;

  constructor(
    private actionSheetController: ActionSheetController,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private platform: Platform
  ) {
    this.showCacheNote = this.platform.is('android');
  }

  async changeLanguage() {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: 'English',
          handler: () => {
            this.languageService.setLanguage(Languages.EN_CH);
          }
        },
        {
          text: 'Deutsch',
          handler: () => {
            this.languageService.setLanguage(Languages.DE_CH);
          }
        },
        {
          text: 'Français',
          handler: () => {
            this.languageService.setLanguage(Languages.FR_CH);
          }
        },
        {
          text: this.translateService.instant('SHARED.actions.cancel'),
          role: 'cancel'
        }
      ]
    });
    await actionSheet.present();
  }

  getCurrentLang() {
    if (this.translateService && this.translateService.currentLang) {
      return this.translateService.currentLang.slice(0, 2);
    }
    return '';
  }

  async openApp() {
    if (this.platform.is('ios')) {
      Browser.open({ url: 'https://apps.apple.com/ch/app/inyova-impact-investing/id1520194338' });
    } else {
      window.open('https://play.google.com/store/apps/details?id=mobile.app.yova', '_system');
    }
  }
}
