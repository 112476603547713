// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

app-ds-rounded-icon {
  display: inline-flex;
  margin-right: var(--spacing-2);
}

.switch-account {
  padding: 0 var(--spacing-4);
  margin-bottom: var(--spacing-4);
  transition: all var(--duration-extended) var(--ease-1);
}
.switch-account.active {
  margin-bottom: var(--spacing-0);
}
.switch-account__active-item {
  display: flex;
  position: relative;
  background-color: var(--color-core-brand-3-2);
  z-index: 2;
  box-sizing: border-box;
  border-radius: var(--radius-6);
  padding: var(--spacing-2);
  align-items: center;
  max-height: var(--spacing-11);
}
.switch-account__active-item .menu-dropdown {
  margin: var(--spacing-1) var(--spacing-1) var(--spacing-1) auto;
  font-size: var(--icon-size-2);
  color: var(--color-core-neutral-7);
}
.switch-account__list-wrapper {
  position: relative;
  top: calc(-1 * var(--spacing-8));
  z-index: 1;
  margin: 0;
  background-color: var(--color-core-brand-3-2);
  border-radius: var(--radius-6);
  max-height: 0;
  overflow-y: scroll;
  transition: max-height 400ms ease-out;
}
.switch-account__list-wrapper.expand {
  max-height: 75vh;
  transition: max-height 400ms ease-in;
}
.switch-account__list-content {
  border-top: var(--border-1) solid var(--color-core-brand-3-3);
  margin-top: var(--spacing-8);
  padding: var(--spacing-2);
}
.switch-account__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.switch-account__list h4 {
  margin: 0 0 var(--spacing-2) 0;
}
.switch-account__list li {
  display: flex;
  flex-direction: column;
}
.switch-account__list li:not(:last-child) {
  margin-bottom: var(--spacing-6);
}
.switch-account__list li a {
  display: flex;
  flex: 1;
  align-items: center;
}
.switch-account__list li a > div {
  display: flex;
  align-items: center;
}
.switch-account__list li a.kid-account {
  margin: 0 0 var(--spacing-4) 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
