// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.market-performance-box {
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-elavated);
  background: var(--color-core-neutral-0);
  margin-bottom: calc(var(--spacing-1) / 2);
  padding: var(--spacing-4);
}
.market-performance-box.first {
  border-top-left-radius: var(--radius-2);
  border-top-right-radius: var(--radius-2);
}
.market-performance-box.last {
  border-bottom-left-radius: var(--radius-2);
  border-bottom-right-radius: var(--radius-2);
}
.market-performance-box.one-item {
  border-radius: var(--radius-2);
}
.market-performance-box-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-1);
}
.market-performance-box-detail {
  display: inline-flex;
  justify-content: space-between;
}
.market-performance-box-detail > div {
  display: inline-flex;
  align-items: center;
}
.market-performance-box-detail > div .ds-ui-2 {
  display: inline-flex;
}
.market-performance-box-detail > div .ds-ui-2:first-child {
  color: var(--color-core-neutral-7);
  margin-right: var(--spacing-3);
}
.market-performance-box-extra-info {
  color: var(--color-core-neutral-7);
  margin-top: var(--spacing-1);
  margin-bottom: var(--spacing-3);
}
.market-performance-box-extra-info p {
  color: inherit;
}
.market-performance-box .note {
  color: var(--color-core-neutral-5);
}

::ng-deep .ds-ui-3 a {
  color: var(--color-core-neutral-7);
  text-decoration: underline;
  border: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
