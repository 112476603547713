import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ds-returns',
  templateUrl: './ds-returns.component.html',
  styleUrls: ['./ds-returns.component.scss']
})
export class DsReturnsComponent {
  // TODO: Refactor html view and details
  // TODO: Add storybook story later after refactoring
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() type: 'positive' | 'negative' | 'custom' | null = null;
  @Input() customColor = 'var(--color-core-neutral-6)';
  @Input() value: number;
}
