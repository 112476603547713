import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-ds-tab-nav',
  styleUrls: ['./ds-tab-nav.component.scss'],
  templateUrl: './ds-tab-nav.component.html'
})
export class DSTabNavComponent implements OnChanges {
  @Input() activeTab: number | string = 1;
  @Input() size: 'fit' | 'full';
  @Input() tabs: { id: number | string; value: string }[];
  @Output() tabSwitched = new EventEmitter<number>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.activeTab) {
      this.activeTab = changes.activeTab.currentValue;
    }
  }

  changeTab(tabId: number): void {
    this.tabSwitched.emit(tabId);
  }
}
