import { Component } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Languages } from '@app/app.constants';
import { LanguageService } from '@shared/services/language.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {
  constructor(
    private actionSheetController: ActionSheetController,
    private languageService: LanguageService,
    private translateService: TranslateService
  ) {}

  async changeLanguage() {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: 'English',
          handler: () => {
            this.languageService.setLanguage(Languages.EN_CH);
          }
        },
        {
          text: 'Deutsch',
          handler: () => {
            this.languageService.setLanguage(Languages.DE_CH);
          }
        },
        {
          text: 'Français',
          handler: () => {
            this.languageService.setLanguage(Languages.FR_CH);
          }
        },
        {
          text: this.translateService.instant('SHARED.actions.cancel'),
          role: 'cancel'
        }
      ]
    });
    await actionSheet.present();
  }

  getCurrentLang() {
    if (this.translateService && this.translateService.currentLang) {
      return this.translateService.currentLang.slice(0, 2);
    }
    return '';
  }
}
