// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  background-color: #000;
}

ion-button {
  --color: var(--ion-color-light);
}

ion-content {
  --background: #000;
}

.button-holder {
  background: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100vw;
}

.support-button {
  margin: 20px;
  width: -webkit-fill-available;
}

.youtube-container {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  padding-bottom: calc(177.78% - 84px);
}
.youtube-container:not(.supported) {
  padding-bottom: calc(177.78% - 128px);
}
.youtube-container .responsive-video,
.youtube-container embed,
.youtube-container iframe,
.youtube-container object,
.youtube-container video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.close-btn {
  position: absolute;
  top: 40px;
  right: 20px;
  z-index: 2;
}
.close-btn ion-icon {
  color: var(--ion-color-light);
  font-size: 24px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
