import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { TokenService } from './token.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private authService: AuthService;

  constructor(
    private injector: Injector,
    private tokenService: TokenService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get auth data from local storage
    this.tokenService.getAuthDataFromStorage();

    // Add the headers if the request is going to the configured server
    const authData = this.tokenService.authData.value;

    let headers: any = {
      'Content-Type': 'application/json'
    };

    if (authData && request.url.match('inyova')) {
      headers = {
        ...headers,
        'access-token': authData.accessToken,
        client: authData.client,
        expiry: authData.expiry,
        'token-type': authData.tokenType,
        uid: authData.uid
      };
    }

    request = request.clone({
      setHeaders: headers
    });

    return next.handle(request).pipe(
      tap(
        (res) => {
          this.handleResponse(res);
        },
        (error) => {
          if (
            error instanceof HttpErrorResponse &&
            error.status === 401 &&
            !error.url.includes('validate_token') &&
            !error.url.includes('verify_2fa_token') &&
            !error.url.includes('toggle_2fa_token')
          ) {
            this.checkToken();
          }
        }
      )
    );
  }

  // Parse Auth data from response
  private handleResponse(res: HttpResponse<any> | HttpErrorResponse | HttpEvent<any>): void {
    if (res instanceof HttpResponse || res instanceof HttpErrorResponse) {
      if (res.url && res.url.match('inyova')) {
        this.tokenService.getAuthHeadersFromResponse(res);
      }
    }
  }

  private checkToken() {
    this.authService = this.injector.get(AuthService);
    this.authService.checkToken();
  }
}
