import { Component, Input } from '@angular/core';
import { IonRouterOutlet, ModalController } from '@ionic/angular';

import { StatusBarStylingService } from '@app/shared/services/status-bar-styling.service';
import { HomeImpactMetricsInfoComponent } from '@home/modals';
import { ImpactMetric } from '@shared/models/Home';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-home-impact-metrics',
  styleUrls: ['./home-impact-metrics.component.scss'],
  templateUrl: './home-impact-metrics.component.html'
})
export class HomeImpactMetricsComponent {
  @Input() impactMetrics: ImpactMetric[];

  slideOpts = {
    freeMode: false,
    slidesPerView: 'auto',
    roundLengths: true,
    pagination: {}
  };

  constructor(
    private trackingService: TrackingService,
    private modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    private statusBarStyling: StatusBarStylingService
  ) {}

  async openMetricsInfoModal(metricName: string): Promise<void> {
    this.trackingService.trackActivity(`[Card] Metric Info: ${metricName}`);
    this.statusBarStyling.setBackgroundColor('dialog');

    const modal = await this.modalController.create({
      component: HomeImpactMetricsInfoComponent,
      cssClass: 'app-fullscreen',
      componentProps: {
        metricName
      },
      presentingElement: this.routerOutlet.nativeEl
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('base');
    });
    return modal.present();
  }
}
