import { Component } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngrx/store';
import * as AccountActions from '@account/account.actions';
import * as PublicActions from '@public/public.actions';

import { ENABLE_TWO_FA_FAQ_ARTICLE, LocalStorageKeys } from '@app/app.constants';
import { State } from '@app/shared/models/State';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-2fa',
  styleUrls: ['./login-2fa.component.scss'],
  templateUrl: './login-2fa.component.html'
})
export class Login2faComponent {
  authToken: string;
  isStagingEnv = environment && environment.name === 'development';
  phoneNumber: string;
  resetOtp: string;

  constructor(
    private loadingController: LoadingController,
    private translateService: TranslateService,
    private store: Store<State>
  ) {
    this.phoneNumber = localStorage.getItem(LocalStorageKeys.PHONE_NUMBER);
    this.authToken = localStorage.getItem(LocalStorageKeys.AUTHENTICATION_TOKEN);
  }

  needHelp() {
    Browser.open({ url: ENABLE_TWO_FA_FAQ_ARTICLE[this.translateService.currentLang] });
  }

  async otpCodeUpdate(code: string) {
    if (code && code.length === 6) {
      const loading = await this.loadingController.create({});
      await loading.present();
      // Reset opt input after each try
      loading.onDidDismiss().then(() => {
        this.resetOtp = `${Math.random()}`;
      });
      this.store.dispatch(PublicActions.verify2faCode({ authToken: this.authToken, code }));
    }
  }

  async resendCode() {
    const loading = await this.loadingController.create({});
    await loading.present();
    this.store.dispatch(AccountActions.send2faAuthorizationCode());
  }
}
