// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-accounts-item-title {
  flex-basis: 115px;
  word-break: break-word;
}

.home-accounts-item-chart {
  padding: 0px 5px;
  text-align: center;
}
.home-accounts-item-chart p {
  font-size: 12px;
}
.home-accounts-item-chart ion-icon {
  color: var(--ion-color-red);
  width: 28px;
  height: 28px;
}

.home-accounts-item-chart-loading {
  text-align: center;
}
.home-accounts-item-chart-loading ion-spinner {
  height: 24px;
  width: 24px;
}

.home-accounts-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  min-height: 52px;
}

.home-accounts-item-performance {
  text-align: right;
  flex-basis: 130px;
}
.home-accounts-item-performance p:nth-child(1) {
  line-height: 24px;
  font-weight: 800;
  font-size: 16px;
}
.home-accounts-item-performance p:nth-child(1) span {
  font-size: 12px;
  font-weight: 500;
}
.home-accounts-item-performance p:nth-child(2) {
  line-height: 18px;
  font-size: 12px;
}
@media only screen and (min-width: 380px) {
  .home-accounts-item-performance p:nth-child(1) {
    font-size: 20px;
  }
}
.home-accounts-item-performance ion-skeleton-text {
  display: none;
}
.home-accounts-item-performance.loading {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.home-accounts-item-performance.loading p {
  display: none;
}
.home-accounts-item-performance.loading ion-skeleton-text {
  display: block;
  border-radius: 4px;
  height: 16px;
  max-width: 80px;
}
.home-accounts-item-performance.loading ion-skeleton-text:last-of-type {
  height: 10px;
  max-width: 40px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
