import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject } from 'rxjs';

import { IonicStorageKeys, Languages } from '@app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selected = new BehaviorSubject<Languages>(Languages.DE_DE);

  constructor(
    private translateService: TranslateService,
    private storage: Storage
  ) {}

  setInitialAppLanguage() {
    // When language was already selected use this one
    this.storage.get(IonicStorageKeys.LANGUAGE).then((val) => {
      if (val) {
        this.translateService.setDefaultLang(val);
        this.setLanguage(val);
      } else {
        this.translateService.setDefaultLang(Languages.DE_DE);
        this.setBrowserLanguage();
      }
    });
  }

  setLanguage(lang: Languages) {
    this.storage.set(IonicStorageKeys.LANGUAGE, lang);
    this.translateService.use(lang);
    this.selected.next(lang as Languages);
    document.documentElement.lang = lang.substring(0, 2);
  }

  // If there is browser language avaliabile set it as initial one
  private setBrowserLanguage() {
    const browserLang = this.translateService.getBrowserLang();
    if (browserLang) {
      if (browserLang.match(/^en/)) {
        return this.setLanguage(Languages.EN_CH);
      }
      if (browserLang.match(/^de/)) {
        return this.setLanguage(Languages.DE_CH);
      }
      if (browserLang.match(/^fr/)) {
        return this.setLanguage(Languages.FR_CH);
      }
    }
    this.setLanguage(Languages.EN_CH);
  }
}
