import { Action, createReducer } from '@ngrx/store';

import { State } from '@shared/models/State';

export const PUBLIC_REDUCER_FEATURE_KEY = 'public';

export const initialState: PublicState = {
  initialised: null
};

const publicReducer = createReducer(initialState);

export function reducer(state: PublicState | undefined, action: Action) {
  return publicReducer(state, action);
}

export interface PublicState {
  initialised: boolean;
}

/*
 * SELECTORS
 * */
export const selectFeature = (state: State) => state.public;
// Public reducer selectors
